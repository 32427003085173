import { Col, Row, Statistic, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Text } = Typography;

const PurchaseCreditStatus = ({
  totalSubscription,
  total,
  subscribeCredits,
  last7DaysCount,
}) => {
  const user = useSelector((state) => state?.user?.user);

  return (
    <Row>
      <Col span={24} className="bg-white card__shadow rounded-xl p-3">
        <div className="flex justify-between items-center">
          <Text className="md:text-2xl text-xl font-medium text-gray">
            Stats:
          </Text>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-3 mt-5">
          <div className="bg-[#E3F5FF] rounded-lg flex justify-center items-center">
            <Row className="lg:py-10 py-5 px-2">
              <Col>
                <Statistic
                  className="text-sm font-medium text-gray text-center"
                  title="Total Credits Left:"
                  value={user?.balanceCredits || 0}
                />
              </Col>
            </Row>
          </div>
          <div className="bg-[#E5ECF6] rounded-lg flex justify-center items-center">
            <Row className="lg:py-10 py-5 px-2">
              <Col>
                <Statistic
                  className="text-sm font-medium text-gray text-center"
                  title="Subscribed Credits:"
                  value={Number(subscribeCredits || 0).toFixed(0)}
                />
              </Col>
            </Row>
          </div>
          <div className="bg-[#E3F5FF] rounded-lg flex justify-center items-center">
            <Row className="lg:py-10 py-5 px-2">
              <Col>
                <Statistic
                  className="text-sm font-medium text-gray text-center"
                  title="Total Subscriptions:"
                  value={totalSubscription || 0}
                />
              </Col>
            </Row>
          </div>
          <div className="bg-[#FAEFD9] rounded-lg flex justify-center items-center">
            <Row className="lg:py-10 py-5 px-2">
              <Col>
                <Statistic
                  className="text-sm font-medium text-gray text-center"
                  title="Daily Expense:"
                  value={Number(subscribeCredits || 0).toFixed(0)}
                />
              </Col>
            </Row>
          </div>
          <div className="bg-[#F9D9FA] rounded-lg flex justify-center items-center">
            <Row className="lg:py-10 py-5 px-2">
              <Col>
                <Statistic
                  className="text-sm font-medium text-gray text-center"
                  title="New Subscriptions:"
                  value={last7DaysCount}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PurchaseCreditStatus;
