import React, { useState } from "react";
import { Button, Modal, Typography, Layout, Flex, Image, Spin } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { useMutation, useQueryClient } from "react-query";
import { deleteClientRequest } from "../../service/request";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const { Text } = Typography;
const { Content } = Layout;

const DeleteRequest = ({ request, refetch }) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const isVendor = user?.role?.name === "VENDOR";

  const deleteMutation = useMutation(deleteClientRequest);
  const handleDelete = async (id) => {
    try {
      await deleteMutation.mutateAsync(id);
      await queryClient.invalidateQueries("CLIENT_REQUEST_FETCH");
      if (refetch) {
        await refetch();
      }
      toast.success("Request Deleted Successfully");
      if (params.requestId) {
        window.location.pathname = "/request";
      }
    } catch (e) {
      toast.error("Something went wrong.");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isVendor) return null;

  return (
    <Spin spinning={deleteMutation.isLoading}>
      <div onClick={showModal} className="flex gap-2 text-[#D83A52]">
        {Svgs.delete}
        Delete
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Image
            preview={false}
            className="max-w-[140px] mx-auto"
            src="/assets/images/trash.svg"
          />
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
              Are you sure you want to delete request?
            </Text>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Button
              className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
              type=""
              onClick={() => handleDelete(request.id)}
            >
              Delete
            </Button>
            <Button
              className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
              onClick={handleCancel}
              type=""
            >
              Cancel
            </Button>
          </Flex>
        </Content>
      </Modal>
    </Spin>
  );
};

export default DeleteRequest;
