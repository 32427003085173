import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createUser,
  deleteUser,
  fetchUser,
  fetchUsers,
  forgetPassword,
  loginUser,
  resetPassword,
  updateUser,
} from "../../service/userservice";

const initialState = {
  users: [],
  loading:false,
  user: null,
};

export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  const response = await fetchUsers();
  return response.data;
});
export const getUser = createAsyncThunk("users/getUser", async (id) => {
  try {
    const response = await fetchUser(id);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 401 && window.location.pathname !== '/login') {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    throw error;
  }
});

export const login = createAsyncThunk(
  "users/login",
  async ({ username, email, password }, { dispatch }) => {
    try {
      const response = await loginUser({ username, email, password });
      const token = response.data.token;
      dispatch(storeToken(token));
      return response.data;
    } catch (error) {
      console.error("Login failed:", error.message);
      throw error;
    }
  }
);
export const storeToken = createAction("users/login/storeToken");

export const addUser = createAsyncThunk("users/addUser", async (userData) => {
  try {
    const response = await createUser(userData);
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error.message);
    throw error;
  }
});

export const update_User = createAsyncThunk(
  "users/updateUser",
  async ({ id, ...userData }) => {
    const response = await updateUser({id, userData});
    return response.data;
  }
);

export const delete_User = createAsyncThunk(
  "users/deleteUser",
  async (userId) => {
    await deleteUser(userId);
    return userId;
  }
);

export const forgotPassowrd = createAsyncThunk(
  "users/forgot-Password",
  async (email) => {
    try {
      const response = await forgetPassword(email);
      return response.data;
    } catch (error) {
      console.error("Error forget password:", error.message);
      throw error;
    }
  }
);
export const reset_Passowrd = createAsyncThunk(
  "users/resetPassword",
  async (userData) => {
    try {
      const response = await resetPassword(userData);
      return response;
    } catch (error) {
      console.error("Error reset passord : ", error.message);
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    storeToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })

      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(update_User.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        const index = state.users.findIndex(
          (user) => user.id === updatedUser.id
        );
        state.users[index] = updatedUser;
      })
      .addCase(delete_User.fulfilled, (state, action) => {
        const userId = action.payload;
        state.users = state.users.filter((user) => user.id !== userId);
      })
      .addCase(forgotPassowrd.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(reset_Passowrd.fulfilled, (state, action) => {
        state.users.push(action.payload);
      });
  },
});

export default userSlice.reducer;
