import {
  Button,
  Flex,
  Form,
  InputNumber,
  Layout,
  message,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { Field, Formik } from "formik";
import _debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { subscribeService } from "../../service/transaction";
import { getActiveAreas } from "../../store/area/areaSlice";
import { getActiveServices } from "../../store/category/categorySlice";
import { getFormItemValidationProps } from "../../utils/form-util";
import { Svgs } from "../Svgs/svg-icons";

const { Text } = Typography;
const { Content } = Layout;

const ServiceSubscriptionAdd = ({ refetch }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const serviceSubscribe = useMutation(subscribeService);
  const categories = useSelector((state) => state?.category?.activeService);
  const activeAreas = useSelector((state) => state?.area?.activeAreas?.data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchService, setServiceSearch] = useState("");
  const [totalCreditValue, setCreditValue] = useState(0);
  const [searchArea, setAreaSearch] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInputChangeService = _debounce((value = "") => {
    setServiceSearch(value);
  }, 1500);

  const handleInputChangeAreas = _debounce((value = "") => {
    setAreaSearch(value);
  }, 1500);

  useEffect(() => {
    dispatch(getActiveServices({ query: searchService }));
    dispatch(getActiveAreas({ query: searchArea }));
  }, [dispatch, searchService, searchArea]);

  return (
    <div>
      <Button
        onClick={showModal}
        className="bg-primary flex justify-center items-center w-full max-w-[190px] py-5 text-base font-normal text-white rounded-lg"
        type=""
        icon={Svgs.addiconw}
      >
        New Subscription
      </Button>
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center mb-4">
            Add New Subscription
          </Text>
          <Text className="text-[#888888] text-sm font-normal text-center w-full max-w-[320px] mx-auto">
            Fill the information below to add new subscription as per your
            requirement.
          </Text>
          <Formik
            initialValues={{
              serviceId: null,
              areaIds: [],
            }}
            validate={(values) => {
              const errors = {};
              if (!values.serviceId) {
                errors.serviceId = '"Service Category" Should not be empty';
              }
              if (!values?.areaIds?.length) {
                errors.areaIds = '"Area" Should not be empty';
              }
              return errors;
            }}
            onSubmit={async (values) => {
              try {
                message.loading({ content: "Processing..." });
                const variables = {
                  serviceId: values?.serviceId,
                  areas: values?.areaIds,
                  isActive: true,
                };
                await serviceSubscribe.mutateAsync(variables);
                setIsModalOpen(false);
                message.destroy();
                if (refetch) await refetch();
                queryClient.invalidateQueries("GET_ALL_VENDOR_PURCHASES");
                toast.success("Subscribed Successfully!");
              } catch (error) {
                message.destroy();
                const errorMessage = error?.response?.data?.message?.message;
                toast.error(errorMessage || "Something went wrong!");
              }
            }}
          >
            {(props) => {
              return (
                <Form
                  name="login"
                  size="large"
                  layout="vertical"
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    margin: "30px auto 0 auto",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="flex flex-col gap-3"
                >
                  <Field name="serviceId">
                    {({ field, form }) => (
                      <Form.Item
                        label="Service Category:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <Select
                          showSearch
                          placeholder="Find service category "
                          optionFilterProp="children"
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          onSearch={handleInputChangeService}
                          options={categories?.map((s) => ({
                            label: s.name,
                            value: s.id,
                          }))}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  {categories?.find(
                    (cat) => cat.id === props?.values?.serviceId
                  ) && (
                    <Typography.Paragraph>
                      {
                        categories?.find(
                          (cat) => cat.id === props?.values?.serviceId
                        )?.description
                      }
                    </Typography.Paragraph>
                  )}
                  <Field name="areaIds">
                    {({ field, form }) => (
                      <Form.Item
                        label="Select Area:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder="Find service category "
                          className="area-select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.searchLabel ?? "").includes(input)
                          }
                          onChange={(value, ref) => {
                            const credit = ref?.reduce((acc, crr) => {
                              return (acc += crr?.area?.creditValue || 0);
                            }, 0);
                            setCreditValue(credit);
                            form.setFieldValue(field.name, value);
                          }}
                          onSearch={handleInputChangeAreas}
                          filterSort={(optionA, optionB) =>
                            (optionA?.searchLabel ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.searchLabel ?? "").toLowerCase()
                              )
                          }
                          options={activeAreas?.map((area) => ({
                            label: (
                              <Tooltip title={`${area.description}`}>
                                {`${area.name} (${area.population} ${
                                  area.population >= 1
                                    ? "Residents"
                                    : "Resident"
                                })`}
                              </Tooltip>
                            ),
                            value: area.id,
                            area: area,
                            searchLabel: area.name,
                          }))}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Form.Item label="Daily Credit:" required>
                    <InputNumber
                      size="large"
                      placeholder="Enter Credit"
                      min={0}
                      value={Math.round(
                        totalCreditValue *
                          categories?.find(
                            (cat) => cat.id === props?.values?.serviceId
                          )?.serviceValue
                      )}
                      disabled
                      className="super__select text-sm font-normal text-dark w-full"
                      suffix={
                        <>
                          {(totalCreditValue *
                            categories?.find(
                              (cat) => cat.id === props?.values?.serviceId
                            )?.serviceValue) /
                            100}{" "}
                          USD
                        </>
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Flex vertical style={{ marginTop: 20 }}>
                      <Button
                        className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[240px] mx-auto"
                        type="primary"
                        block
                        disabled={props.isSubmitting}
                        onClick={props.handleSubmit}
                      >
                        Subscribe
                      </Button>
                    </Flex>
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </Content>
      </Modal>
    </div>
  );
};

export default ServiceSubscriptionAdd;
