import axios from "../config/Axios";

export const fetchArea = (pagination) => {
  return axios.get("/areas", { params: pagination });
};

export const fetchAreaById = (id) => {
  return axios.get(`/areas/${id}`);
};

export const searchArea = (pagination) => {
  return axios.get("/areas/search", { params: pagination });
};

export const filterArea = (params) => {
  return axios.get("/areas/filters", { params: params });
};

export const fetchAreaZipCodes = (pagination) => {
  return axios.get("/areas/zipcodes", { params: pagination });
};

export const createArea = (areaData) => {
  return axios.post("/areas", areaData);
};

export const updateArea = (id, areaData) => {
  return axios.put(`/areas/${id}`, areaData);
};

export const deleteArea = (id) => {
  return axios.delete(`/areas/${id}`);
};

export const fetchActiveArea = (pagination) => {
  return axios.get("/areas/active-area", { params: pagination });
};

export const calculateAreaValue = async (areaData) => {
  return await axios.post(`/areas/calculate-population`, areaData);
};