import React from "react";
import {
  Layout,
  Space,
  Table,
  Menu,
  Dropdown,
  Input,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Empty,
  Tooltip,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import DeleteCategory from "./delete-category";
import EditCategory from "./edit-category";
import AddService from "./add-service";
import { useDispatch } from "react-redux";
import {
  getAllCategory,
  search_Categories,
  update_Category,
} from "../../store/category/categorySlice";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { constructQueryString } from "../../utils/common-util";

const { Search } = Input;
const { Text } = Typography;

const userDropdown = (rowData, rowIndex, pagination) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item key="1">
      <EditCategory
        rowIndex={rowIndex}
        rowData={rowData}
        pagination={pagination}
      />
    </Menu.Item>
    <Menu.Item key="2">
      <DeleteCategory
        rowIndex={rowIndex}
        rowData={rowData}
        pagination={pagination}
      />
    </Menu.Item>
  </Menu>
);

function CategoryList(props) {
  const { categories, total, pagination, handleTableChange, setPagination } =
    props;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleInputChange = debounce((value = "") => {
    dispatch(search_Categories({ query: value.trim(), page: 1, pageSize: 10 }));
  }, 500);

  const dataSource = categories.map((item) => {
    return {
      ...item,
      name: item.name,
      isPaused: item.isPaused,
      description: item.description,
      iconURL: item.iconURL,
      data: item,
    };
  });
  const handleStatusToggle = async (record) => {
    let id = record?.id;
    const newStatus = record.isPaused === true ? "inactive" : "active";
    const updatedFormValues = {
      name: String(record?.name),
      description: String(record?.description),
      iconURL: String(record?.iconURL),
      createdBy: Number(record?.createdBy),
      updatedBy: Number(record?.updatedBy),
      serviceValue: Number(record?.serviceValue),
      isPaused: newStatus === "active" ? true : false,
    };

    const resultAction = await dispatch(
      update_Category({ id, ...updatedFormValues })
    );
    if (resultAction.payload) {
      dispatch(getAllCategory(pagination));
      toast.success("Category Status Updated Successfully");
    } else if (resultAction.error) {
      toast.error(resultAction.error.message);
    }
  };

  const getColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "0",
        sorter: (a, b) => a.name < b.name,
        render: (text, record) =>
          record?.data?.iconURL ? (
            <Space className="flex gap-1 items-center">
              <span>
                <img
                  src={record?.data?.iconURL}
                  width="36px"
                  height="36px"
                  alt="Service Icon"
                />
              </span>
              <span>{text}</span>{" "}
            </Space>
          ) : (
            <Space className="flex gap-1 items-center">
              <span className="flex justify-center items-center rounded-full w-9 h-9 bg-[#EBEBEB]">
                {Svgs.plumbericon}
              </span>{" "}
              <span>{text}</span>{" "}
            </Space>
          ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "0",
        sorter: (a, b) => a.description < b.description,
        render(description) {
          return (
            <span className="cursor-pointer" title={description}>
              {(description || "").slice(0, 40)?.trim()}
              {description?.length > 40 ? "..." : ""}
            </span>
          );
        },
      },
      {
        title: "Service Value",
        dataIndex: "serviceValue",
        key: "0",
        sorter: (a, b) => a.serviceValue < b.serviceValue,
        render(serviceValue) {
          return (
              <span className="cursor-pointer" title={serviceValue}>
              {serviceValue}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "isPaused",
        key: "status",
        render: (_, record) => (
          <Space
            className="cursor-pointer"
            onClick={() => handleStatusToggle(record)}
          >
            {!record?.data?.isPaused ? (
              <Tooltip title="Pause Service">
                <Space>{Svgs.pause}</Space>
              </Tooltip>
            ) : (
              <Tooltip title="Resume Service">
                <Space>{Svgs.resume}</Space>
              </Tooltip>
            )}
          </Space>
        ),
      },
      {
        title: "",
        dataIndex: "data",
        key: "data",
        align: "right",
        render: (text, rowData, rowIndex) => (
          <Space size="middle">
            <Dropdown
              overlay={() => userDropdown(rowData, rowIndex, pagination)}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/service">Service Category</Link>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-8">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Service Category
          </Text>
        </Col>
        <Col>
          <AddService pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          defaultValue={searchParams.get("search")}
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search Service"
          onChange={(e) => {
            if (!e.target.value) {
              handleInputChange(e.target.value);
            }
          }}
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                search: value,
              },
              "search"
            );
            navigate(`/service${queryString}`);
            handleInputChange(value);
          }}
        />
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default CategoryList;
