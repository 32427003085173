import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { Field } from "formik";
import _uniqBy from "lodash.uniqby";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { calculate_Area_Value } from "../../store/area/areaSlice";
import { getAllAreaCategory } from "../../store/areaCategory/areaCategorySlice";
import { delay } from "../../utils/common-util";
import { getFormItemValidationProps } from "../../utils/form-util";
import { Svgs } from "../Svgs/svg-icons";
import AddArea from "./add-area";
import ManualCredit from "./manual-credit";

const breadCrumb = (isEditMode) => [
  {
    title: <Link to="/area">Area Management</Link>,
  },
  {
    title: <Link to="/area/create">{isEditMode ? "Area" : "Add Area"}</Link>,
  },
];

const { Text } = Typography;
const { TextArea } = Input;

const AreaForm = (props) => {
  const {
    values: { zipCodesEntries, FORM_MODE, manualCredit },
    handleSubmit,
    setFieldValue,
    isSubmitting,
    dirty,
  } = props;

  const dispatch = useDispatch();
  const areaCatgories = useSelector((state) => state.areaCategory.areaCategory);

  useEffect(() => {
    dispatch(getAllAreaCategory());
  }, [dispatch]);

  const handleCalculateClick = async notify => {
    try {
      if (!zipCodesEntries.length) {
        setFieldValue('isManualCredit', false);
        setFieldValue('manualCredit', null);
        setFieldValue('creditValue', 0);
        return
      }
      const result = await dispatch(calculate_Area_Value({
        zipCodes: zipCodesEntries
      })).unwrap();

      setFieldValue('isManualCredit', false);
      setFieldValue('manualCredit', null);
      setFieldValue('creditValue', result.value || 0);
      if (notify) {
        notification.success({
          message: 'Calculation Successful',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Calculation Failed',
        description: 'There was an error calculating the area value. Please try again.',
      });
    }
  };

  useEffect(() => {
    handleCalculateClick(false);
    // eslint-disable-next-line 
  }, [zipCodesEntries])

  const isEditMode = FORM_MODE === "UPDATE";

  return (
    <React.Fragment>
      <Breadcrumb className="mb-4" items={breadCrumb(isEditMode)} />
      <Row className="flex flex-col lg:flex-row justify-between lg:items-center items-start mb-1">
        <Col className="gap-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center justify-start gap-4 mb-3">
            <Link to="/area">{Svgs.arrowback}</Link>
            {isEditMode ? "Area Details" : "Add new Area"}
          </Text>
          <Text className="text-[#868687] text-sm font-normal mb-4 block">
            Please set your area and manage credits here.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text className="md:text-lg text-base font-medium text-[#303030] capitalize mb-3 block">
            Area Management:
          </Text>

          <Form
            size="large"
            layout="vertical"
            className="grid lg:grid-cols-2 gap-3 lg:p-6 p-5 rounded-lg bg-[#F5F5F5]"
          >
            <Field name="name">
              {({ field, form }) => {
                return (
                  <Form.Item
                    label="Name:"
                    hasFeedback
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      size="large"
                      placeholder="Enter new Area name"
                      className="super__select text-sm font-normal text-dark flex-1 "
                    />
                  </Form.Item>
                );
              }}
            </Field>
            <Field name="description">
              {({ field, form }) => {
                return (
                  <Form.Item
                    label="Description:"
                    className="custom-textarea-feild"
                    hasFeedback
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <TextArea
                      {...field}
                      size="large"
                      placeholder="Enter about area"
                      className="super__select text-sm font-normal text-dark flex-1"
                      autoSize={{ minRows: 1, maxRows: 5 }}
                    />
                  </Form.Item>
                );
              }}
            </Field>
            <div className="flex flex-col gap-4">
              <Field name="zipCode">
                {({ field, form }) => {
                  return (
                    <Form.Item
                      label="Zip Code:"
                      hasFeedback
                      required
                      {...getFormItemValidationProps(field, form)}
                    >
                      <div>
                        <Input
                          {...field}
                          size="large"
                          placeholder="Enter Zip Code"
                          className="super__select text-sm font-normal text-dark flex-1 relative"
                          onPressEnter={(e) => {
                            const { value } = e.target;
                            const { zipCodesEntries } = form.values;
                            if (form.errors.zipCode) return;
                            setFieldValue(
                              "zipCodesEntries",
                              _uniqBy([...zipCodesEntries, value])
                            );
                            setFieldValue(field.name, "");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Tab" || e.key === "Enter") {
                              e.preventDefault();
                              const { value } = e.target;
                              const { zipCodesEntries } = form.values;

                              const zipCodes = value
                                  .split(",")
                                  .map((zip) => zip.trim())
                                  .filter((zip) => /^\d{5}$/.test(zip));

                              if (zipCodes.length > 0) {
                                setFieldValue("zipCodesEntries", _uniqBy([...zipCodesEntries, ...zipCodes]));
                              }
                              setFieldValue(field.name, "");
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            const paste = (e.clipboardData || window.clipboardData).getData("text");
                            const { zipCodesEntries } = form.values;
                            const zipCodes = paste
                                .split(",")
                                .map((zip) => zip.trim())
                                .filter((zip) => /^\d{5}$/.test(zip));
                            if (zipCodes.length > 0) {
                              setFieldValue("zipCodesEntries", _uniqBy([...zipCodesEntries, ...zipCodes]));
                            }
                          }}
                        />
                        <Button
                          className="absolute top-0 right-2 z-10"
                          icon={Svgs.plus}
                          type="button"
                          onClick={async () => {
                            const { zipCode } = form.values;
                            if (form.errors.zipCode || !zipCode) return;
                            setFieldValue(
                              "zipCodesEntries",
                              _uniqBy([...zipCodesEntries, zipCode])
                            );
                            await delay(500);
                            setFieldValue(field.name, "");
                          }}
                        ></Button>
                      </div>
                    </Form.Item>
                  );
                }}
              </Field>
              <div className="flex flex-wrap gap-3">
                {_uniqBy(zipCodesEntries)?.map((zip, index) => (
                  <div
                    key={index}
                    className="relative bg-[#DCE8FF] rounded text-sm text-[#303030] flex justify-center items-center py-1 px-2"
                  >
                    {zip}
                    <span
                      className="absolute -top-1 -right-1"
                      onClick={() => {
                        const updatedZipCodes = [...zipCodesEntries];
                        updatedZipCodes.splice(index, 1);
                        setFieldValue("zipCodesEntries", updatedZipCodes);
                      }}
                    >
                      {Svgs.closesm}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Field name="areaCategoryId">
                {({ form, field }) => (
                  <Form.Item
                    className="super__select"
                    label="Area Category:"
                    hasFeedback
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Select
                      {...field}
                      showSearch
                      size="large"
                      className="w-full super__select text-sm font-normal text-dark"
                      placeholder="Choose Category"
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                    >
                      <Select.Option value={0}>None</Select.Option>
                      {areaCatgories.map((item) => (
                        <Select.Option value={item?.id}>
                          {item?.areaCategoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>
              <AddArea setFieldValue={setFieldValue} />
            </div>
          </Form>
        </Col>
        <Col span={24}>
          {" "}
          <Text className="md:text-lg text-base font-medium text-[#303030] capitalize my-3 block">
            Credit Expense Management:
          </Text>
          <Form
            name="login"
            size="large"
            layout="vertical"
            className="flex gap-5 items-end lg:p-6 p-5 rounded-lg bg-[#F5F5F5]"
          >
            <Field name="creditValue">
              {({ field, form }) => (
                <Form.Item
                  label={
                    manualCredit ? "Manual Credit:" : "Auto Generated Credit:"
                  }
                  className="flex flex-col w-full"
                  required
                  {...getFormItemValidationProps(field, form)}
                >
                  <InputNumber
                    {...field}
                    size="large"
                    min={0}
                    value={manualCredit || field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    placeholder="Auto Generated Credit"
                    className="super__select text-sm font-normal text-dark flex-1 w-full"
                  />
                </Form.Item>
              )}
            </Field>
            <Button
                onClick={handleCalculateClick}
                className="bg-[#50586C] lg:text-xl text-base font-normal text-white rounded-xl !h-14 w-full max-w-[240px]"
            >
              Recalculate Value
            </Button>
            <ManualCredit
              setManualCredit={async (value) => {
                if (!value) {
                  await handleCalculateClick(false);
                  return;
                }
                setFieldValue("creditValue", value);
                setFieldValue("manualCredit", value);
                setFieldValue("isManualCredit", true);
              }}
              manualCredit={manualCredit}
            />
          </Form>
        </Col>
        <Col span={24} className="flex justify-center !mt-10">
          <Button
            className="bg-primary lg:text-xl text-base lg:px-40 px-20 font-normal text-white rounded-xl !h-14 w-full max-w-[320px] flex justify-center items-center"
            disabled={isSubmitting || !dirty}
            htmlType="submit"
            block
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AreaForm;
