import { Breadcrumb, Col, Image, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import ClientRequest from "./client-request";

const { Text } = Typography;

const RequestEmpty = ({ refetch }) => {
  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Service Requests</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            All Requests
          </Text>
        </Col>
        <Col>
          <ClientRequest refetch={refetch} />
        </Col>
      </Row>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-client-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2 mb-2">
          No request list to display
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-[360px]">
          Let's start making requests by clicking the{" "}
          <span className="font-medium">Make a Request</span> button
        </Text>
        <ClientRequest refetch={refetch} />
      </section>
    </Content>
  );
};

export default RequestEmpty;
