import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ClientCategory from "../components/Client/client-category";
import ClientDetail from "../components/Client/client-detail";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { fetchUserById } from "../service/request";
import { getAllArea } from "../store/area/areaSlice";
import { getAllCategory } from "../store/category/categorySlice";
import { getAllClients } from "../store/clients/clientsSlice";

export default function Client() {
  const data = useSelector((state) => state.client.clients);
  const categories = useSelector((state) => state?.category?.category?.data);
  const allArea = useSelector((state) => state.area.area);
  const params = useParams();

  const detailRecord = params.userId;

  const { data: user } = useQuery(
    ["categories", { activeOnly: true }],
    () => fetchUserById(detailRecord),
    {
      enabled: !!detailRecord,
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllClients());
    dispatch(getAllCategory());
    dispatch(getAllArea());
    // eslint-disable-next-line 
  }, []);
  useSetTabDetails("Client");
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            {!detailRecord ? (
              <ClientCategory
                data={data}
                categories={categories || []}
                allArea={allArea}
              />
            ) : (
              <ClientDetail
                user={user||{}}
                categories={categories}
                allArea={allArea}
              />
            )}
            {/* <ProposalDetail /> */}
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
