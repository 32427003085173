import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addTabDetails } from '../store/tab-details/tabSlice';

const useSetTabDetails = (name) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addTabDetails({name: name}));
    // eslint-disable-next-line 
    }, [name])
    
  return true
}

export default useSetTabDetails