import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Typography,
  Layout,
  Flex,
  Select,
  Space,
  Form,
  Input,
  message,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { Field, Formik } from "formik";
import { getFormItemValidationProps } from "../../utils/form-util";
import { validationForShareCoupon } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsersByRole,
  sendCouponCodeToVendors,
} from "../../store/coupons/couponSlice";
import { toast } from "react-toastify";
import { getUserId } from "../../constants";

const { Text } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

const VENDOR_ROLE_ID = 3;

const ShareDiscount = ({ couponCode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state?.coupons?.vendors || []);

  const handleSubmit = async (values, action) => {
    message.loading({ content: "Sending emails..." });

    try {
      await dispatch(
        sendCouponCodeToVendors({
          emails: values.emails,
          couponCode,
          userId: getUserId(),
          customMessage: values.customMessage,
        })
      ).unwrap();

      message.destroy();
      action.resetForm({});
      setIsModalOpen(false);
      toast.success("Coupon sent successfully!");
    } catch (error) {
      message.destroy();
      toast.error(error.message || "Failed to send emails.");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUsersByRole(VENDOR_ROLE_ID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, VENDOR_ROLE_ID]);

  const options = vendors.map((vendor) => ({
    label: vendor.name,
    value: `${vendor.name} (${vendor.email})`,
    desc: `${vendor.name} (${vendor.email})`,
    email: vendor.email,
    key: vendor.email,
  }));

  return (
    <div>
      <div onClick={showModal} className="flex gap-2 text-[#2C2C2E]">
        {Svgs.share}
        Share Code
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Formik
            initialValues={{ emails: [], users: [], customMessage: "" }}
            validate={validationForShareCoupon}
            onSubmit={handleSubmit}
          >
            {(formikProps) => {
              return (
                <div className="flex flex-col border border-[#E2E8F0] rounded-xl mt-5 py-3 px-2">
                  <Text className="md:text-xl text-base font-bold text-[#303030] text-center mb-1">
                    Share by Email
                  </Text>
                  <Text className="md:text-base text-sm font-normal text-[#888] text-center">
                    Write your email below and sent it to desire member
                  </Text>
                  <Form className="mt-3">
                    <Field name="users">
                      {({ form, field }) => (
                        <Form.Item
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Select
                            {...field}
                            mode="multiple"
                            className="w-full share-select"
                            placeholder="Select vendor"
                            optionLabelProp="label"
                            options={options}
                            onChange={(value, ref) => {
                              form.setFieldValue("users", value);
                              form.setFieldValue(
                                "emails",
                                ref.map((item) => item.email)
                              );
                            }}
                            optionRender={(option) => (
                              <Space>
                                <span
                                  role="img"
                                  aria-label={option.data.label}
                                ></span>
                                {option.data.desc}
                              </Space>
                            )}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                  {/* customMessage */}
                  <div className="mt-5">
                    <label className="md:text-base text-sm font-bold">
                      Write Email
                    </label>
                    <Field name="customMessage">
                      {({ form, field }) => (
                        <Form.Item {...getFormItemValidationProps(field, form)}>
                          <TextArea
                            {...field}
                            placeholder="Write do something..."
                            rows={5}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </div>
                  <Flex gap={16} className="mt-6 justify-center">
                    <Button
                      disabled={formikProps.isSubmitting}
                      className="bg-primary border border-primary flex justify-center items-center w-full max-w-[280px] py-5 text-sm font-normal text-white rounded-md"
                      onClick={formikProps.handleSubmit}
                    >
                      Send
                    </Button>
                  </Flex>
                </div>
              );
            }}
          </Formik>
        </Content>
      </Modal>
    </div>
  );
};

export default ShareDiscount;
