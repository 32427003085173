import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { format } from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRole } from "../../constants";
import { exportToCSV } from "../../utils/common-util";
import { ROLES } from "../../utils/user-util";
import { Svgs } from "../Svgs/svg-icons";
import { generateLogsDataForCSV } from "./util";

const { Search } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const FilterDropdown = ({ setSearchFilters }) => {
  const [filter, setFilter] = useState("");
  return (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      {/* <Form.Item
        className="super__select filter-dropdown w-full"
        label="Activity:"
      >
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          className="Filter by activity"
          options={[
            {
              value: "Successful Login",
              label: "Successful Login",
            },
            {
              value: "Successful Login",
              label: "Successful Login",
            },
            {
              value: "Failed API Operation",
              label: "Failed API Operation",
            },
          ]}
        />
      </Form.Item> */}
      <Form.Item
        className="super__select filter-dropdown w-full request__range"
        label="Select Date Range:"
      >
        <RangePicker
          format={["YYYY-MM-DD", "YYYY-MM-DD"]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              format(dates[1], "MM-dd-yyyy, hh:mm:ss a");

              setFilter({
                startDate: dates[0].toISOString(),
                endDate: dates[1].toISOString(),
              });
            }
          }}
        />
      </Form.Item>
      <Button
        className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
        type="primary"
        onClick={() => {
          setSearchFilters({
            ...filter,
          });
        }}
      >
        Apply Filter
      </Button>
    </Form>
  );
};

function VendorLogCategory({
  activityLogs,
  pagination,
  total,
  handleTableChange,
  setSearchFilters,
  queryParams,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state?.user?.user);
  const isAdmin = getUserRole(user, "admin");

  const dataSource = activityLogs.map((log) => {
    return {
      key: log.id,
      activity: log.activity,
      type: ROLES[log?.user?.role?.name],
      description: log.description,
      status: log.status,
      userName: log?.user?.name,
      datetime: format(log.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (status) => (
          <span style={{ textTransform: "capitalize" }}>{status}</span>
        ),
      },
      {
        title: "Activity",
        dataIndex: "activity",
        key: "0",
        render: (activity, { status }) =>
          status === "error" ? (
            <Space className="flex gap-2 items-center">
              {Svgs.failed}
              <span>{activity || "Nothing Activity Logged"}</span>{" "}
            </Space>
          ) : (
            <Space className="flex gap-2 items-center">
              {Svgs.successful}
              <span>{activity || "Nothing Activity Logged"}</span>{" "}
            </Space>
          ),
      },

      {
        title: "Description",
        dataIndex: "description",
        key: "0",
      },
      {
        title: "Date & Time",
        dataIndex: "datetime",
        key: "0",
      },
    ];
  };

  const columns = getColumns();
  if (isAdmin) {
    columns.unshift(
      {
        title: "User",
        dataIndex: "userName",
        key: "0",
        render: (userName) => (
          <Space className="flex gap-2 items-center">
            <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src="/assets/images/avatar-vendor.svg"
            />
            <span className="text-primary underline">{userName}</span>{" "}
          </Space>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "0",
      }
    );
  }

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Logs</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Logs
          </Text>
        </Col>
        <Col>
          <Button
            className="bg-primary flex justify-center items-center w-full max-w-[180px] py-5 text-base font-normal text-white rounded-lg"
            type="primary"
            onClick={() => {
              const data = generateLogsDataForCSV(activityLogs)
              exportToCSV(data, 'logs');
            }}
            disabled={!activityLogs.length}
            icon={Svgs.download}
          >
            Export as CSV
          </Button>
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          defaultValue={searchParams.get("query")}
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search logs"
          onSearch={(value) => {
            setSearchFilters({ query: value });
          }}
        />
        <div className="flex gap-2 items-center">
          <Space size="middle" className="w-full">
            <Dropdown
              overlay={<FilterDropdown setSearchFilters={setSearchFilters} />}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
                {Svgs.filter} <span>Filter</span>
              </Space>
            </Dropdown>
          </Space>
          {queryParams && (
            <Button
              type=""
              onClick={() => {
                setSearchFilters({});
                navigate(`/log`);
              }}
              className="bg-[#D7E5FF] flex gap-2 justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg"
            >
              {Svgs.cross} <span>Clear</span>
            </Button>
          )}
        </div>
      </div>
      <div>
        <Table
          className="service-table"
          columns={columns}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.limit,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default VendorLogCategory;
