import React from "react";
import { Typography, Image } from "antd";

const { Text } = Typography;

const PurchaseCreditVendorEmpty = () => {
  return (
    <section className="mt-6 bg-[#F5F5F5] p-3 rounded-xl">
      <Text className="md:text-2xl text-xl font-medium text-gray">
        All Credit Transaction:
      </Text>
      <div className="lg:py-20 py-10 px-8 flex flex-col justify-center items-center">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-credit-vendor-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2 mb-2">
          Don’t have any data to show
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight">
          This page will list all your vendors with their credits.
        </Text>
      </div>
    </section>
  );
};

export default PurchaseCreditVendorEmpty;
