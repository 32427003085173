import { Breadcrumb, Col, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { Formik } from "formik";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Header from "../components/Header";
import ProfileForm from "../components/Profile/ProfileForm";
import { SideBar } from "../components/SideBar";
import { getUserId, getUserRole } from "../constants";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { getUser, update_User } from "../store/user/userSlice";
import { profileValidationSchema } from "../utils/profile-util";

const { Text } = Typography;

export default function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const isVendor = getUserRole(user, "vendor");
  useEffect(() => {
    dispatch(getUser(getUserId()));
  }, [dispatch]);
  const nameArray = user?.name?.split(/\s+/);
  const initialValues = {
    firstName: nameArray?.[0] || "",
    lastName: nameArray?.slice(1)?.join(" ") || "",
    email: user?.email || "",
    password: "",
    confirmPassword: "",
    phoneNumber: user?.phoneNumber || "",
    profile_image_url: user?.profile_image_url || "",
  };

  if (isVendor) {
    initialValues.website = user?.website || "";
    initialValues.reviews = user?.reviews || "";
    initialValues.license = user?.license || "";
    initialValues.insurance = user?.insurance || "";
    initialValues.notificationPreference = user?.notificationPreference || "";
  }
  useSetTabDetails("Profile");
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Breadcrumb
              className="mb-4"
              items={[
                {
                  title: (
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      Admin Profile
                    </button>
                  ),
                },
              ]}
            />
            <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
              <Col className="flex flex-col mb-2">
                <Text className="text-primary md:text-[32px] text-xl font-medium mb-3">
                  Profile Information
                </Text>
                <Text className="text-[#888888] md:text-sm font-nomral">
                  From your profile page, you can make changes to your contact
                  information including your name, email, contact number, and
                  password.
                </Text>
              </Col>
            </Row>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={profileValidationSchema}
              onSubmit={async (values, action) => {
                const cleanedPhoneNumber = values.phoneNumber.replace(
                  /[\s-]/g,
                  ""
                );
                const processedValue = {
                  name: values?.firstName + " " + values?.lastName,
                  email: values?.email,
                  phoneNumber: cleanedPhoneNumber,
                  updatedBy: getUserId(),
                  profile_image_url: values?.profile_image_url,
                  ...(values?.password ? { password: values?.password } : {}),
                };
                if (isVendor) {
                  processedValue.website = values?.website || "";
                  processedValue.reviews = values?.reviews || "";
                  processedValue.license = values?.license || "";
                  processedValue.insurance = values?.insurance || "";
                  processedValue.notificationPreference =
                    values?.notificationPreference || "";
                }
                const resultAction = await dispatch(
                  update_User({ id: user?.id, ...processedValue })
                );
                if (resultAction.payload) {
                  toast.success("Profile updated successfully");
                  await dispatch(getUser(getUserId()));
                  action?.resetForm();
                } else if (resultAction.error) {
                  toast.error(resultAction.error.message);
                }
              }}
            >
              {(formikProps) => {
                return <ProfileForm {...formikProps} isVendor={isVendor} />;
              }}
            </Formik>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
