import React, { useState } from "react";
import { Button, Modal, Typography, Layout, Flex, Image, Spin } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { useMutation } from "react-query";
import axiosInstance from "../../config/Axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const { Text } = Typography;
const { Content } = Layout;

const DeleteProposal = ({ proposalId, proposal, refetch, isReload = true }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const isClient = user?.role?.name === "CLIENT";

  const mutation = useMutation(() => {
    return axiosInstance.delete(`/proposals/${proposalId}`);
  });

  const handleDelete = async () => {
    try {
      await mutation.mutateAsync();
      if (refetch) {
        await refetch();
      }
      handleCancel();
      if (isReload) {
        window.location.pathname = "/proposal";
      }
      toast.success("Proposal Deleted Successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isClient) return null;

  return (
    <div>
      <div onClick={showModal} className="flex gap-2 text-[#D83A52]">
        {Svgs.delete}
        Delete
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Spin spinning={mutation.isLoading}>
          <Content className="flex flex-col">
            <Image
              preview={false}
              className="max-w-[140px] mx-auto"
              src="/assets/images/trash.svg"
            />
            <div className="flex flex-col justify-center items-center mt-5">
              <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
                Are you sure you want to delete{" "}
                <span className="font-bold">
                  {proposal?.request?.category?.name} Proposal
                </span>
                ?
              </Text>
            </div>
            <Flex gap={16} className="mt-6 justify-center">
              <Button
                className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
                type=""
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
                type=""
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Flex>
          </Content>
        </Spin>
      </Modal>
    </div>
  );
};

export default DeleteProposal;
