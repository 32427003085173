import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "../components/Dashbaords/admin-dashbaord";
import CLientDashboard from "../components/Dashbaords/client-dashbaord";
import VendorDashboard from "../components/Dashbaords/vendor-dashboard";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { ROLES_TYPES } from "../utils/user-util";

export default function Home() {
  const user = useSelector((state) => state?.user?.user);
  const loading = useSelector((state) => state?.user?.loading);
  useSetTabDetails("Dashboard");
  const renderDashboard = () => {
    switch (user?.role?.name) {
      case ROLES_TYPES.VENDOR:
        return <VendorDashboard />;
      case ROLES_TYPES.CLIENT:
        return <CLientDashboard />;
        case ROLES_TYPES.ADMIN:
          return <AdminDashboard />;
      default:
        return null;
    }
  };
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <section>
        <Header />
        <Spin spinning={loading}>{renderDashboard()}</Spin>
      </section>
    </div>
  );
}
