import React from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Typography,
  Breadcrumb,
  Menu,
  Space,
  Dropdown,
  Tooltip,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import AddAdminProfile from "./create-admin";
import LogisticRole from "./logistic-role-admin";
import DeleteAdmin from "./modal-delete-admin";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { getAdminTypeName } from "../../utils/user-util";

const { Text } = Typography;

const UserDropdown = (props) => {
  const { user, refetch } = props;

  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item key="0">
        <AddAdminProfile user={user} refetch={refetch} />
      </Menu.Item>
      <Menu.Item key="1">
        <LogisticRole user={user} />
      </Menu.Item>
      <Menu.Item
        className="dropdown__item__header"
        key="2"
        icon={Svgs.activity}
      >
        <span>View Activity Logs</span>
      </Menu.Item>
      <Menu.Item key="3">
        <DeleteAdmin user={user} refetch={refetch} />
      </Menu.Item>
    </Menu>
  );
};

const CardAdmin = (props) => {
  const { adminUsers, refetch } = props;
  return (
    <React.Fragment>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>User Management</span>,
          },
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            title: <a>Admins</a>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-8">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Admins
          </Text>
        </Col>
        <Col>
          <AddAdminProfile refetch={refetch} />
        </Col>
      </Row>
      <div className="flex flex-col gap-5">
        <div className="grid lg:grid-cols-2 gap-5">
          {adminUsers.map((user) => (
            <Card className="bg-white rounded-xl !border-none card__shadow relative">
              <div className="flex items-center gap-3 mb-6">
                <Avatar className="w-20 h-20 rounded-full" src={Svgs.avatar} />
                <div className="flex flex-col">
                  <Text className="md:text-2xl text-xl font-normal text-primary">
                    {user.name}
                  </Text>
                  <Text className="text-sm font-normal text-success">
                    {getAdminTypeName(user?.role?.name)}
                  </Text>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-5">
                <Text className="flex flex-col gap-2 font-normal">
                  <span className="text-base text-gray">Email Address:</span>
                  <span className="text-sm text-grayLight bg-[#F7F7F7] p-3 flex gap-1 items-center">
                    <MailOutlined />
                    <Tooltip title={user?.email}>
                      <span className="w-full max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
                        {user?.email}
                      </span>
                    </Tooltip>
                  </span>
                </Text>
                <Text className="flex flex-col gap-2 font-normal">
                  <span className="text-base text-gray">Phone Number:</span>
                  <span className="text-sm text-grayLight bg-[#F7F7F7] p-3 flex gap-1 items-center">
                    <PhoneOutlined className="rotate-90" />
                    {user?.phoneNumber || "N/A"}
                  </span>
                </Text>
              </div>
              <Space size="middle">
                <Dropdown
                  overlay={<UserDropdown user={user} refetch={refetch} />}
                  placement="bottomRight"
                  trigger={["click"]}
                  className="cursor-pointer absolute top-5 right-5"
                >
                  <Space className="gap-3 items-center">{Svgs.dots}</Space>
                </Dropdown>
              </Space>
            </Card>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardAdmin;
