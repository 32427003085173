import phone from "phone";
import * as Yup from "yup";

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),

  lastName: Yup.string().required("Last Name is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .nullable()
    .transform((value) => (value === "" ? null : value)),

  confirmPassword: Yup.string().test(
    "valid-password",
    "Password does not match",
    function (value) {
      const password = this.parent.password;

      if (password === value || (!password && !value)) {
        return true;
      }
      return false;
    }
  ),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("valid-phone-number", "Invalid phone number", function (value) {
      if (!value) return false;
      const phoneNumber = phone(value);
      return phoneNumber.isValid;
    }),
});
