import React from "react";
import { SideBar } from "../SideBar";
import Header from "../Header";
import { Content } from "antd/es/layout/layout";
import { Spin } from "antd";

const Loader = () => {
  return (
    <Spin>
      <div className="h-full min-h-screen grid grid-columns">
        <SideBar />
        <div className="relative flex flex-col">
          <Header />
          <Content className="px-4 pt-28 pb-6"></Content>
        </div>
      </div>
    </Spin>
  );
};

export default Loader;
