import { Breadcrumb, Col, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StripeWrapper from "../stripe";
import { Svgs } from "../Svgs/svg-icons";
import PurchaseDetail from "./purchase-details";
import PurchasePayment from "./purchase-payments";
import PurchaseReview from "./purchase-review";
import PurchaseThankyou from "./purchase-thankyou";
const { Text } = Typography;

const PurchaseCredits = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const billingDetails = queryParams.get("billing-details");
  const paymentDetails = queryParams.get("payment-details");
  const reviewDetails = queryParams.get("review-details");
  const invoiceDetails = queryParams.get("invoice");

  const handleNavigate = () => {
    if (billingDetails) {
      navigate("/credit");
      return;
    }
    if (paymentDetails) {
      navigate("/purchase-credit?billing-details=1");
      return;
    }
    if (reviewDetails) {
      navigate("/purchase-credit?payment-details=1");
      return;
    }
    if (invoiceDetails) {
      navigate("/credit");
      return;
    }
  };

  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Purchase Credits</span>,
          },
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text
            className="text-primary cursor-pointer md:text-[32px] text-xl font-medium flex items-center gap-3 mb-3"
            onClick={handleNavigate}
          >
            {!invoiceDetails && Svgs.arrowback}
            Purchase Credits
          </Text>
          <Text className="text-[#888888] text-sm font-normal">
            Purchase credits to submit proposals, with each credit equivalent to
            one US dollar.
          </Text>
        </Col>
      </Row>
      {billingDetails && <PurchaseDetail />}
      {paymentDetails && (
        <StripeWrapper>
          <PurchasePayment />
        </StripeWrapper>
      )}
      {reviewDetails && <PurchaseReview />}
      {invoiceDetails && <PurchaseThankyou />}
    </Content>
  );
};

export default PurchaseCredits;
