import { Button, Image, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Svgs } from "../Svgs/svg-icons";
const { Text } = Typography;

const PurchaseThankyou = () => {
  const [searchParams] = useSearchParams();

  return (
    <Content>
      <div className="flex gap-16 justify-center items-center mb-10">
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.details}
          </div>
          <Text className="text-sm text-primary font-semibold">Details</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.payment}
          </div>
          <Text className="text-sm text-primary font-semibold">Payment</Text>
          <div className="flex gap-1 items-center absolute top-4 left-[46px]">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primbg-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.review}
          </div>
          <Text className="text-sm text-primary font-semibold">Review</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.thankyou}
          </div>
          <Text className="text-sm text-primary font-semibold">Thank You</Text>
        </div>
      </div>
      <div className="bg-[#f5f5f5] rounded-lg lg:p-5 p-3 lg:py-20 py-10">
        <div className="flex flex-col justify-center items-center">
          <Image
            preview={false}
            src="/assets/images/purchase-credit-img.png"
            className="w-full max-w-[180px] mx-auto mb-2"
          />
          <Text className="text-base text-[#0f172a] font-normal w-full max-w-[330px] mx-auto text-center mb-6">
            Congratulations! You have successfully purchased the credits
          </Text>
          <Button
            type=""
            className="bg-primary w-full max-w-[320px] rounded-xl text-white flex gap-1 items-center justify-center py-6"
            onClick={() => {
              const invoiceUrl = searchParams.get("invoice");
              if (invoiceUrl) {
                window.open(invoiceUrl);
              }
            }}
          >
            {Svgs.download}
            Download Bill
          </Button>
          <a
            href="/credit"
            className="bg-[#f5f5f5] w-full max-w-[320px] rounded-xl text-[#0f172a] hover:text-[#0f172a] flex gap-1 items-center justify-center py-6"
          >
            Go Back
          </a>
        </div>
      </div>
    </Content>
  );
};

export default PurchaseThankyou;
