import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Typography,
  Layout,
  Flex,
  Image,
  Spin,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { useDispatch } from "react-redux";
import {
  delete_Category,
  getAllCategory,
} from "../../store/category/categorySlice";
import { toast } from "react-toastify";
const { Text } = Typography;
const { Content } = Layout;

const DeleteCategory = ({ rowData, pagination }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  useEffect(() => {
    setId(rowData.id);
  }, [rowData]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const resultAction = await dispatch(delete_Category(id));
      if (resultAction?.payload) {
        await dispatch(getAllCategory(pagination));
        toast.success("Service Category has been deleted Successfully!");
        setIsModalOpen(false);
      } else if (resultAction?.error?.message) {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div onClick={showModal} className="flex gap-2 text-[#D83A52]">
        {Svgs.delete}
        Delete
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Image
            preview={false}
            className="max-w-[140px] mx-auto"
            src="/assets/images/trash.svg"
          />
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
              Are you sure you want to delete this{" "}
              <span className="font-bold">{rowData.data.name}</span> category?
            </Text>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Spin spinning={loading}>
              <Button
                className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
                type="submit"
                onClick={handleSubmit}
              >
                Delete
              </Button>
            </Spin>
            <Button
              className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
              type=""
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Flex>
        </Content>
      </Modal>
    </div>
  );
};

export default DeleteCategory;
