import axios from "../config/Axios";

export const fetchAreaCategory = (pagination) => {
  return axios.get("/areaCategories", { params: pagination });
};

export const createAreaCategory = (areaCategoryData) => {
  return axios.post("/areaCategories", areaCategoryData);
};

export const updateAreaCategory = (id, areaCategoryData) => {
  return axios.put(`/areaCategories/${id}`, areaCategoryData);
};

export const deleteAreaCategory = (id) => {
  return axios.delete(`/areaCategories/${id}`);
};
