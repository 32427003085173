import * as Yup from "yup";

export const adminFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('"Name" is not allowed to empty')
    .min(2, "Name is too short")
    .max(50, "Name is too long"),

  email: Yup.string()
    .email("Invalid email address")
    .required('"Email" is not allowed to empty'),

  roleId: Yup.string().required('"Admin category" is not allowed to empty'),

  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be only digits")
    .min(10, "Phone number is too short")
    .max(15, "Phone number is too long")
    .required('"Phone number" is not allowed to empty'),
});
