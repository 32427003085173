import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchVendorProposalById } from "../../service/vendorProposal";

const initialState = {
  vendorProposal: [],
};

export const getVendorProposalById = createAsyncThunk(
  "vendorProposal/getVendorProposalById",
  async (id) => {
    const response = await fetchVendorProposalById(id);
    return response.data;
  }
);

const vendorProposalSlice = createSlice({
  name: "vendorProposal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getVendorProposalById.fulfilled, (state, action) => {
      state.vendorProposal = action.payload;
    });
  },
});

export default vendorProposalSlice.reducer;
