import axios from "../config/Axios";

export const fetchRoles = () => {
  return axios.get("/roles");
};

export const createRole = (roleData) => {
  return axios.post("/roles", roleData);
};

export const updateRole = (id, roleData) => {
  return axios.put(`/roles/${id}`, roleData);
};

export const deleteRole = (id) => {
  return axios.delete(`/roles/${id}`);
};
