import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchClientSubscriptionById } from "../../service/clientSubscription";

const initialState = {
  clientSubscription: [],
};

export const getClientSubscriptionById = createAsyncThunk(
  "clientSubscription/getClientSubscriptionById",
  async (id) => {
    const response = await fetchClientSubscriptionById(id);
    return response.data;
  }
);

const clientSubscriptionSlice = createSlice({
  name: "clientSubscription",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getClientSubscriptionById.fulfilled, (state, action) => {
      state.clientSubscription = action.payload;
    });
  },
});

export default clientSubscriptionSlice.reducer;
