import { Button, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import axiosInstance from "../../config/Axios";

const AddCreditsModal = ({ userId, visible, onClose, refetch }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await axiosInstance.post(`/users/add-credits/${userId}`, {
                credits: values.credits,
            });
            await refetch();
            message.success('Credits added successfully!');
            onClose();
        } catch (error) {
            message.error('Failed to add credits. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title="Add Manual Credits"
            onCancel={onClose}
            footer={null}
            destroyOnClose
        >
            <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item
                    name="credits"
                    label="Credits"
                    rules={[{ required: true, message: 'Please enter the credits amount' }]}
                >
                    <Input type="number" />
                </Form.Item>
                <br />
                <Form.Item>
                    <Button
                        className="bg-primary border border-primary flex justify-center items-center w-full max-w-[120px] py-5 text-sm font-normal text-white rounded-md"
                        type="primary" htmlType="submit" loading={loading}>
                        Add Credits
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddCreditsModal;
