import React, { useState } from "react";
import { Form, Select, Input, Button, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { constructQueryStringWithObject } from "../../utils/common-util";
import { SUPER_ADMIN_ID, getUserId } from "../../constants";

const PaymentFilterDropdown = ({ vendors }) => {
  const [vendorFilter, setVendorFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const userId = getUserId();

  const isAdmin = userId === SUPER_ADMIN_ID;
  const navigate = useNavigate();
  const handleApplyFilter = () => {
    const filters = {
      userId: vendorFilter,
      status: statusFilter,
      amount: amountFilter,
    };
    const customDateRange = [...dateRangeFilter];
    if (customDateRange.length) {
      filters.endDate = format(
        new Date(customDateRange[1]),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
      filters.startDate = format(
        new Date(customDateRange[0]),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
    }
    const truthyFilters = {};
    for (const key in filters) {
      if (filters[key]) {
        truthyFilters[key] = filters[key];
      }
    }
    const queryString = constructQueryStringWithObject(truthyFilters);
    navigate(`/payment${queryString}`);
  };

  return (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      {isAdmin && (
        <Form.Item
          className="super__select filter-dropdown w-full"
          label="Vendor:"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Filter by vendor"
            onChange={(value) => setVendorFilter(value)}
          >
            {vendors.map((v) => (
              <Select.Option value={v.id}>{v.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Status:"
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Filter by Status"
          onChange={(value) => setStatusFilter(value)}
        >
          <Select.Option value="Paid">Paid</Select.Option>
          <Select.Option value="Failed">Failed</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Amount:"
      >
        <Input
          style={{ width: "100%" }}
          placeholder="Filter by amount"
          onChange={(e) => setAmountFilter(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        className="super__select filter-dropdown w-full request__range"
        label="Select Date Range:"
      >
        <DatePicker.RangePicker
          onChange={(dates) => setDateRangeFilter(dates)}
        />
      </Form.Item>
      <Button
        className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
        type="button"
        onClick={handleApplyFilter}
      >
        Apply Filter
      </Button>
    </Form>
  );
};

export default PaymentFilterDropdown;
