import React from "react";
import { Space, Table, Menu, Dropdown, Col, Typography } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const { Text } = Typography;

const UserDropdown = ({ proposalId }) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Link to={`/proposal/${proposalId}`}>
      <Menu.Item className="dropdown__item__header" key="0" icon={Svgs.view}>
        View details
      </Menu.Item>
    </Link>
  </Menu>
);

function ClientProposal({ proposals }) {
  const dataSource = proposals?.map((prop) => ({
    key: prop.id,
    proposalId: prop.id,
    vendor: prop?.user?.name,
    servicecategory: "",
    areaname: prop?.serviceSubscription?.areas?.[0]?.name,
    areacategory: prop?.user?.areas?.[0]?.areaCategory?.name || "N/A",
    submissiondate: format(prop.createdAt, "yyyy-MM-dd"),
    status: prop.status,
    action: "",
  }));

  const getColumns = () => {
    return [
      {
        title: "Vendors",
        dataIndex: "vendor",
        key: "0",
        render: (text) => (
          <Space>
            <span className="text-primary underline">{text}</span>{" "}
          </Space>
        ),
      },

      {
        title: "Area Name",
        dataIndex: "areaname",
        key: "areaname",
      },
      {
        title: "Area Category",
        dataIndex: "areacategory",
        key: "areacategory",
      },
      {
        title: "Submission Date",
        dataIndex: "submissiondate",
        key: "submissiondate",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (text) =>
          text === "accepted" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                Accept
              </div>
            </Space>
          ) : text === "rejected" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#F00F00] py-[2px] px-2 rounded-2xl">
                Rejected
              </div>
            </Space>
          ) : (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#FFC107] py-[2px] px-2 rounded-2xl">
                Pending
              </div>
            </Space>
          ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={<UserDropdown proposalId={record.proposalId} />}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Col span={24} className="bg-white box__shadow lg:p-5 p-3 rounded-xl">
      <Text className="md:text-xl text-lg font-medium text-gray">
        Proposals:
      </Text>
      <div className="mt-3">
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </Col>
  );
}

export default ClientProposal;
