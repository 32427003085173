import React, { useState } from "react";
import { Button, Modal, Typography, Layout } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import AdminForm from "./admin-form";

const { Text } = Typography;
const { Content } = Layout;

const AddAdminProfile = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      {props.user ? (
        <div onClick={showModal} className="flex gap-2">
          {Svgs.editg}
          Edit
        </div>
      ) : (
        <ModelPopupButton showModal={showModal} />
      )}

      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          {props.user ? <EditAdminHeader /> : <CreateAdminHeader />}
          <AdminForm closePopup={() => setIsModalOpen(false)} {...props} />
        </Content>
      </Modal>
    </React.Fragment>
  );
};

const ModelPopupButton = (props) => {
  const { showModal } = props;
  return (
    <Button
      onClick={showModal}
      className="bg-primary flex justify-center items-center w-full max-w-[170px] py-5 text-base font-normal text-white rounded-lg"
      type=""
      icon={Svgs.addiconw}
    >
      Add New Admin
    </Button>
  );
};

const CreateAdminHeader = () => (
  <React.Fragment>
    <Text className="text-primary md:text-[32px] text-xl font-medium text-center mb-4">
      Add New Admin
    </Text>
    <Text className="text-[#888888] text-sm font-normal text-center w-full max-w-[320px] mx-auto">
      Fill the information below to add new admin as per your requirment.
    </Text>
  </React.Fragment>
);

const EditAdminHeader = () => (
  <React.Fragment>
    <Text className="text-primary md:text-[32px] text-xl font-medium text-center mb-4">
      Edit Admin
    </Text>
    <Text className="text-[#888888] text-sm font-normal text-center w-full max-w-[320px] mx-auto">
      Update the information below to edit admin as per your requirement.
    </Text>
  </React.Fragment>
);

export default AddAdminProfile;
