import React, { useState } from "react";
import { format } from "date-fns";
import {
  Layout,
  Space,
  Table,
  Dropdown,
  Input,
  Button,
  Typography,
  Form,
  DatePicker,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";

const { Search } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const FilterDropdown = ({ appliedFilter, setFilters }) => {
  const [filter, setFilter] = useState("");
  return (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      {/* <Form.Item
        className="super__select filter-dropdown w-full"
        label="Balance Credits:"
      >
        <Input
          showSearch
          size="large"
          className="w-full super__select text-sm font-normal text-dark"
          placeholder="Filter by credits"
          onChange={(e) => {
            setFilter({
              amount: e.target.value,
            });
          }}
        />
      </Form.Item> 
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Amount:"
      >
        <Input
          showSearch
          size="large"
          className="w-full super__select text-sm font-normal text-dark"
          placeholder="Filter by credits"
          onChange={(e) => {
            setFilter({
              credits: e.target.value,
            });
          }}
        />
      </Form.Item>*/}
      <Form.Item
        className="super__select filter-dropdown w-full request__range"
        label="Select Date Range:"
      >
        <RangePicker
          format={["YYYY-MM-DD", "YYYY-MM-DD"]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              format(dates[1], "MM-dd-yyyy, hh:mm:ss a");

              setFilter({
                startDate: format(dates[0], "MM-dd-yyyy, hh:mm:ss a"),
                endDate: format(dates[1], "MM-dd-yyyy, hh:mm:ss a"),
              });
            }
          }}
        />
      </Form.Item>
      <Button
        className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
        type="primary"
        onClick={() => {
          setFilters({
            ...appliedFilter,
            ...filter,
          });
        }}
      >
        Apply Filter
      </Button>
    </Form>
  );
};

function PurchaseCreditVendorCategory({
  subscriptions,
  total,
  pagination,
  handleTableChange,
  setFilters,
  appliedFilter,
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dataSource = subscriptions.map((sub) => {
    return {
      key: sub.id,
      servicedescription: sub?.subscribeService?.serviceId?.name,
      addexpense: Number(sub.credit) || "--",
      deductexpense: sub.debit,
      balance: sub.balanceCredits,
      datetime: format(sub.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Service Description",
        dataIndex: "servicedescription",
        key: "0",
        render: (serviceName) => (
          <Space className="flex gap-1 items-center">
            <span>{serviceName}</span>{" "}
          </Space>
        ),
      },
      {
        title: "Credit",
        dataIndex: "addexpense",
        key: "0",
      },
      {
        title: "Debit",
        dataIndex: "deductexpense",
        key: "0",
      },
      {
        title: "Balance Credits",
        dataIndex: "balance",
        key: "0",
      },
      {
        title: "Date & Time",
        dataIndex: "datetime",
        key: "0",
      },
    ];
  };

  const isFilterApplied = Object.values(appliedFilter)?.filter(Boolean)?.length;

  return (
    <Layout className="p-3 !bg-[#F5F5F5] rounded-xl mt-5">
      <Text className="text-gray md:text-2xl text-xl font-medium">
        All Credit Transaction:
      </Text>
      <div className="flex gap-3 my-5">
        <Search
          allowClear
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search here..."
          defaultValue={searchParams.get("query")}
          onSearch={(value) => {
            if (!value) {
              setFilters({ ...appliedFilter, query: value.trim() });
              return;
            }
            setFilters({ ...appliedFilter, query: value.trim() });
          }}
        />
        <Space size="middle" className="w-full">
          <Dropdown
            overlay={
              <FilterDropdown
                setFilters={setFilters}
                appliedFilter={appliedFilter}
              />
            }
            placement="bottomRight"
            trigger={["click"]}
            className="cursor-pointer"
          >
            <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
              {Svgs.filter} <span>Filter</span>
            </Space>
          </Dropdown>
          {isFilterApplied ? (
            <Space
              onClick={() => {
                setFilters({});
                navigate("/credit");
              }}
              className="bg-[#D7E5FF] flex cursor-pointer justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg"
            >
              {" "}
              <span>Clear</span>
            </Space>
          ) : null}
        </Space>
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default PurchaseCreditVendorCategory;
