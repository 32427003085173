import { useQuery } from "react-query";
import axios from "../config/Axios";

const fetchProposalStats = async () => {
  const response = await axios.get("/proposals/graph-data");
  return response.data;
};

export const useFetchProposalStats = (params) => {
  return useQuery(
    ["fetchProposalStats", params],
    () => fetchProposalStats(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};
