import React, { useContext } from "react";
import {
  Avatar,
  Space,
  Typography,
  Input,
  Menu,
  Dropdown,
  Badge,
  Row,
  Divider,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Svgs } from "../Svgs/svg-icons";
import { ToggleContext } from "../../context";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROLES } from "../../utils/user-util";
import PerfectScrollbar from "react-perfect-scrollbar";

const { Text } = Typography;

const userDropdown = (navigate) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item
      className="dropdown__item__header"
      key="0"
      icon={Svgs.profilegray}
      onClick={() => navigate("/profile")}
    >
      Profile
    </Menu.Item>
    <Menu.Item
      className="dropdown__item__header"
      key="1"
      icon={Svgs.passwordgray}
      onClick={() => navigate("/profile")}
    >
      Change Password
    </Menu.Item>
    <Menu.Item
      className="dropdown__item__header"
      key="2"
      icon={Svgs.logoutgray}
      onClick={() => {
        localStorage.clear();
        navigate("/login");
      }}
    >
      Logout
    </Menu.Item>
  </Menu>
);

const badgeDropdwon = (unReadMessages, isClient) => (
  <div className="flex flex-col bg-white shadow-lg w-full max-w-[300px] rounded">
    <PerfectScrollbar className="max-h-[300px]">
      {unReadMessages?.map((message) => {
        return (
          <React.Fragment>
            <Typography.Paragraph className="!m-0 !text-primaryContrast hover:!text-black !text-sm py-2 px-3 cursor-pointer bg-white hover:bg-grayLight/20 transition-all ease-in-out">
              New message on {isClient ? "Proposal" : "Request"}{" "}
              <span className="font-bold">
                {isClient ? message?.request?.id : message?.proposal?.id}
              </span>{" "}
              from <span className="font-bold">{message?.user?.name}</span>
              <Link
                to={`${isClient ? "/proposal/" : "/request/"}${
                  isClient ? message?.proposal?.id : message?.request?.id
                }?message=true`}
              >
                {" "}
                Read Now
              </Link>
              .
            </Typography.Paragraph>
            <Divider className="!m-0" />
          </React.Fragment>
        );
      })}
    </PerfectScrollbar>
  </div>
);

function Header({ children }) {
  const navigate = useNavigate();
  const usr = useSelector((state) => state?.user?.user);
  const isClient = usr?.role?.name === "CLIENT";
  const unReadMessages = usr?.messages?.filter((message) => !message?.isRead);
  const { toggleSideBar } = useContext(ToggleContext);
  return (
    <div className="fixed z-10 w-full grid-width">
      <header className="flex justify-between items-center bg-[#f5f5f5] header__box__shadow py-5 px-4 lg:gap-0 gap-3">
        <Row onClick={toggleSideBar} className="lg:hidden block mr-3 shrink-0">
          {Svgs.bars}
        </Row>
        <div className="bg-white border border-white h-12 rounded-lg flex justify-center items-center px-3 py-1 w-full max-w-[450px]">
          {Svgs.search}
          <Input
            className="text-[#50586c] text-base placeholder:text-[#50586c] !pl-[5px]"
            placeholder="Search..."
            bordered={false}
          />
        </div>
        <div className="flex justify-center items-center lg:gap-6 gap-3">
          <Dropdown
            overlay={badgeDropdwon(unReadMessages, isClient)}
            placement="bottomRight"
            trigger={["click"]}
            className="cursor-pointer"
          >
            <div className="border border-[#e1e3ec] rounded-lg w-12 h-12 flex justify-center items-center badge__super__count">
              <Badge count={unReadMessages?.length}>{Svgs.bell}</Badge>
            </div>
          </Dropdown>
          <Dropdown
            overlay={userDropdown(navigate)}
            placement="bottomRight"
            trigger={["click"]}
            className="cursor-pointer"
          >
            <Space className="gap-3 items-center">
              <Avatar
                className="rounded-lg w-12 h-12 flex justify-center items-center"
                size="large"
                icon={<UserOutlined />}
              />
              <div className="lg:flex items-center gap-3 hidden">
                <div className="flex flex-col">
                  <Text className="text-base font-normal text-primaryContrast">
                    {usr?.name || "Guest"}
                  </Text>
                  <Text className="text-xs font-normal text-lightContrast">
                    {ROLES[usr?.role?.name] || "Guest"}
                  </Text>
                </div>
                {Svgs.arrowdown}
              </div>
            </Space>
          </Dropdown>
        </div>
      </header>
    </div>
  );
}

export default Header;
