import React from "react";
import { format } from "date-fns";
import debounce from "lodash.debounce";
import {
  Layout,
  Space,
  Table,
  Menu,
  Dropdown,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Switch,
  Tooltip,
  message,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import DeleteDiscount from "./delete-discount";
import ShareDiscount from "./share-discount";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  duplicateCoupon,
  getAllCoupons,
  search_Coupons,
  updateCouponStatus,
} from "../../store/coupons/couponSlice";
import { toast } from "react-toastify";
import { getUserId } from "../../constants";
import { constructQueryString } from "../../utils/common-util";

const { Search } = Input;
const { Text } = Typography;

const UserDropdown = ({ record, pagination, handleDuplicate }) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item key="0">
      <ShareDiscount couponCode={record.couponCode} />
    </Menu.Item>
    <Menu.Item className="dropdown__item__header" key="1" icon={Svgs.copylink}>
      <span
        onClick={() => {
          navigator.clipboard.writeText(record.couponCode);
          message.open({ content: "Copied!" });
        }}
      >
        Copy Code
      </span>
    </Menu.Item>
    <Menu.Item className="dropdown__item__header" key="2" icon={Svgs.duplicate}>
      <span onClick={() => handleDuplicate(record.couponCode)}>Duplicate</span>
    </Menu.Item>
    <Menu.Item className="dropdown__item__header" key="3" icon={Svgs.editg}>
      <Link to={`/coupons/${record.id}`}>Edit</Link>
    </Menu.Item>
    <Menu.Item key="4">
      <DeleteDiscount couponCode={record.couponCode} pagination={pagination} />
    </Menu.Item>
  </Menu>
);

function DiscountCategory({ coupons, pagination, total, handleTableChange }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleInputChange = debounce((value = "") => {
    dispatch(search_Coupons({ query: value.trim(), page: 1, pageSize: 10 }));
  }, 500);

  const dataSource = coupons.map((coupon) => {
    const isPercentage = coupon.couponType === "Percentage";
    const redemptionLimit = coupon.redemptionLimit;
    const expDate = coupon.expDate;
    const percentageText = (coupon) => {
      return `${Number(coupon.discountPercentage || 0).toFixed(0)}%`;
    };
    return {
      key: coupon.id,
      couponCode: coupon.couponCode,
      id: coupon.id,
      name: coupon.couponName,
      terms: `${
        isPercentage ? percentageText(coupon) : coupon.discountValue
      } OFF`,
      redemptions: `${coupon.redemptionCount}${
        redemptionLimit ? "/" + redemptionLimit : ""
      }`,
      expiredate: expDate ? format(expDate, "yyyy-MM-dd") : "-",
      isActive: coupon.isActive,
    };
  });

  const handleChangeStatus = (couponCode, checked) => {
    const userId = getUserId();
    message.loading({ content: "Updating status..." });

    dispatch(updateCouponStatus({ couponCode, isActive: checked, userId }))
      .unwrap()
      .then(() => {
        dispatch(getAllCoupons({ userId, ...pagination }));
        message.destroy();
        toast.success("Coupon status updated successfully");
      })
      .catch((error) => {
        const errorMessage =
          error?.message?.message || "Failed to update status";
        message.destroy();
        toast.error(errorMessage);
      });
  };

  const handleDuplicate = (couponCode) => {
    const userId = getUserId();
    message.loading({ content: "Duplicating coupon..." });

    dispatch(duplicateCoupon({ couponCode, userId }))
      .unwrap()
      .then(() => {
        dispatch(getAllCoupons({ userId, ...pagination }));
        message.destroy();
        toast.success("Coupon duplicated successfully");
      })
      .catch((error) => {
        const errorMessage = error?.message || "Failed to duplicate coupon";
        message.destroy();
        toast.error(errorMessage);
      });
  };

  const getColumns = () => {
    return [
      {
        title: "Code",
        dataIndex: "couponCode",
        key: "couponCode",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Terms",
        dataIndex: "terms",
        key: "0",
      },
      {
        title: "Redemptions",
        dataIndex: "redemptions",
        key: "0",
      },
      {
        title: "Expire Date",
        dataIndex: "expiredate",
        key: "0",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (_, record) => (
          <div className="flex items-center gap-5">
            <Tooltip
              title={record.isActive ? "Disable Discount" : "Enable Discount"}
            >
              <Switch
                defaultChecked={record.isActive}
                onChange={(checked) =>
                  handleChangeStatus(record.couponCode, checked)
                }
              />
            </Tooltip>
            <Dropdown
              overlay={
                <UserDropdown
                  record={record}
                  pagination={pagination}
                  handleDuplicate={handleDuplicate}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </div>
        ),
      },
    ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/coupons">Discount Code</Link>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Discount Code
          </Text>
        </Col>
        <Col>
          <Button
            className="bg-primary flex justify-center items-center w-full max-w-[160px] py-5 text-base font-normal text-white rounded-lg"
            type=""
            icon={Svgs.addiconw}
            onClick={() => navigate("/coupons/create")}
          >
            Add New Code
          </Button>
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          defaultValue={searchParams.get('search')}
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search By Coupon Code and Coupon Name"
          onChange={(e) => {
            if (!e.target.value) {
              handleInputChange(e.target.value);
            }
          }}
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                search: value,
              },
              "search"
            );
            navigate(`/coupons${queryString}`);
            handleInputChange(value);
          }}
        />
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default DiscountCategory;
