import React from "react";
import { Spin } from "antd";
import { useQuery } from "react-query";
import { Content } from "antd/es/layout/layout";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import CardAdmin from "../components/Admin/admin-card";
import PerfectScrollbar from "react-perfect-scrollbar";
import AdminEmpty from "../components/Admin/admin-empty";
// import AdminCategory from "../components/Admin/admin-category";
import { getAdminUsers } from "../service/userservice";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function AdminProfile() {
  const { data, isLoading, refetch } = useQuery("ADMIN_USER", getAdminUsers);

  const adminUserList = data || [];
  useSetTabDetails('Admins');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Spin spinning={isLoading}>
              {!adminUserList.length ? (
                <AdminEmpty refetch={refetch} />
              ) : (
                <CardAdmin refetch={refetch} adminUsers={data || []} />
              )}
              {/* <AdminCategory /> */}
            </Spin>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
