import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required"),
  lname: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  country: Yup.string().required("Country is required"),
  postalCode: Yup.string().required("Zip Code is required"),
  amount: Yup.number()
    .typeError("Amount must be a number")
    .required("Amount is required"),
});

export const paymentValidationSchema = (values) => {
  const errors = {};
  if (values.useExistingPaymentMethod) return errors;
  if (!values.fname) {
    errors.fname = "First name is required";
  }
  if (!values.lname) {
    errors.lname = "Last name is required";
  }

  if (!values.term) {
    errors.term = "You must accept the terms";
  }

  if (!values.cardNumber && !values.stripeToken) {
    errors.cardNumber = "Card number is required";
  }

  return errors;
};
