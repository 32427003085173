import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Typography, Breadcrumb, Row, Col, Button, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import PurchaseCreditVendorEmpty from "./purchase-credit-vendor-empty";
import PurchaseCreditVendorCategory from "./purchase-credit-vendor-category";
import PurchaseCreditStatus from "./purchase-credit-status";
import { getAllVendorPurchases } from "../../service/transaction";
import { createFilterQueryString } from "../../utils/common-util";

const { Text } = Typography;
const PurchaseCreditEmpty = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const filters = {
    query: searchParams.get("query"),
    amount: searchParams.get("amount"),
    credits: searchParams.get("credits"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
  };

  const [appliedFilter, setFilters] = useState(filters || {});

  const { data, isLoading, isFetching } = useQuery(
    [
      "GET_ALL_VENDOR_PURCHASES",
      { ...filters, page: pagination.page, pageSize: pagination.pageSize },
    ],
    getAllVendorPurchases,
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: 'always',
      refetchOnMount: true,
    }
  );

  const subscriptions = data?.data;
  const total = data?.total;
  const subscribeCredits = data?.subscribeCredits;
  const last7DaysCount = data?.last7DaysCount;
  const totalSubscription = data?.totalSubscription;

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };

  useEffect(() => {
    const createdSearch = createFilterQueryString(appliedFilter);
    if (
      window.location.search !== `?${createdSearch}` &&
      Object.keys(appliedFilter).length > 0
    ) {
      navigate(`/credit?${createdSearch}`);
    }
  }, [appliedFilter, navigate]);

  const isFilterApplied = Object.values(filters)?.filter(Boolean)?.length;

  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <a>Credits</a>,
          },
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium">
            Credits
          </Text>
        </Col>
        <Col>
          <Button
            onClick={() => navigate("/purchase-credit?billing-details=1")}
            className="w-full max-w-[140px] py-5 px-6 bg-primary border border-primary text-white text-base font-normal flex items-center justify-center"
          >
            Purchase Credit
          </Button>
        </Col>
      </Row>
      <Spin spinning={isLoading || isFetching}>
        <PurchaseCreditStatus
          total={total}
          subscriptions={subscriptions}
          last7DaysCount={last7DaysCount}
          subscribeCredits={subscribeCredits}
          totalSubscription={totalSubscription}
        />
        {!subscriptions?.length && !isFilterApplied ? (
          <PurchaseCreditVendorEmpty />
        ) : null}
        {subscriptions?.length || isFilterApplied ? (
          <PurchaseCreditVendorCategory
            total={total}
            setFilters={setFilters}
            pagination={pagination}
            appliedFilter={appliedFilter}
            subscriptions={subscriptions || []}
            setPagination={setPagination}
            handleTableChange={handleTableChange}
          />
        ) : null}
      </Spin>
    </Content>
  );
};

export default PurchaseCreditEmpty;
