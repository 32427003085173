import {
  DatePicker,
  Divider,
  Image,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
} from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../../constants";
import useSetTabDetails from "../../hooks/use-set-tab-name";
import { useFetchLogs } from "../../service/logs";
import { useFetchServiceRequests } from "../../service/request";
import { getAllTopSubscriptions } from "../../store/vendors/vendorsSlice";
import { ROLES } from "../../utils/user-util";
import { Svgs } from "../Svgs/svg-icons";
import Proposals from "./common/proposals";
import Statistics from "./common/statistics";
import Subscriptions from "./common/subscriptions";
const { Text } = Typography;

function VendorDashboard() {
  const dispatch = useDispatch();
  useSetTabDetails("Dashboard");
  const currentYearStart = format(startOfYear(new Date()), "yyyy-MM-dd");
  const currentYearEnd = format(endOfYear(new Date()), "yyyy-MM-dd");
 
  const [dates, setDates] = useState({
    startDate: currentYearStart,
    endDate: currentYearEnd,
  });
  const user = useSelector((state) => state?.user?.user);
  const isAdmin = getUserRole(user, "admin");
  const topSubscriptions = useSelector(
    (state) => state?.vendor?.topSubscriptions
  );
  useEffect(() => {
    dispatch(
      getAllTopSubscriptions({
        userId: !isAdmin ? user?.id : null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { data: requestStatsData, isLoading: requestStatsLoading } =
    useFetchServiceRequests({
      userId: user?.id,
    });
  const { data, isLoading } = useFetchLogs({
    page: 1,
    limit: 10,
    startDate: dates.startDate,
    endDate: dates.endDate,
    userId: !isAdmin ? user?.id : null,
    query: "",
  });
  const activityLogs = data?.data;
  const handleDateChange = (date) => {
    if (date) {
      const startDate = format(startOfMonth(date.toDate()), "yyyy-MM-dd");
      const endDate = format(endOfMonth(date.toDate()), "yyyy-MM-dd");
      setDates({ startDate, endDate });
    }
  };
  // const MoreDropdown = ({ classes }) => {
  //   const userDropdown = (
  //     <Menu>
  //       <Menu.Item key="1">Option 1</Menu.Item>
  //       <Menu.Item key="2">Option 2</Menu.Item>
  //       <Menu.Item key="3">Option 3</Menu.Item>
  //     </Menu>
  //   );

  //   return (
  //     <Dropdown
  //       overlay={userDropdown}
  //       placement="bottomRight"
  //       trigger={["click"]}
  //       className="cursor-pointer"
  //       overlayStyle={{ minWidth: "150px" }}
  //     >
  //       <Space className={`gap-3 items-center !px-2 text-[#888888] ${classes}`}>
  //         {Svgs.drpdwndots}
  //       </Space>
  //     </Dropdown>
  //   );
  // };
  const dataSource = activityLogs?.map((log) => {
    return {
      key: log.id,
      activity: log.activity,
      type: ROLES[log?.user?.role?.name],
      description: log.description,
      status: log.status,
      userName: log?.user?.name,
      datetime: format(log.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (status) => (
          <span style={{ textTransform: "capitalize" }}>{status}</span>
        ),
      },
      {
        title: "Activity",
        dataIndex: "activity",
        key: "0",
        render: (activity, { status }) =>
          status === "error" ? (
            <Space className="flex gap-2 items-center">
              {Svgs.failed}
              <span>{activity || "Nothing Activity Logged"}</span>{" "}
            </Space>
          ) : (
            <Space className="flex gap-2 items-center">
              {Svgs.successful}
              <span>{activity || "Nothing Activity Logged"}</span>{" "}
            </Space>
          ),
      },

      {
        title: "Description",
        dataIndex: "description",
        key: "0",
      },
      {
        title: "Date & Time",
        dataIndex: "datetime",
        key: "0",
      },
    ];
  };

  const columns = getColumns();
  const displayDateString = () => {
    const currentDate = new Date();
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);

    const formattedStart = format(start, "MMM dd");
    const formattedEnd = format(end, "MMM dd");

    const dateString = `Date: ${formattedStart} - ${formattedEnd}`;
    return dateString;
  };
  
  return (
    <section className="px-4 pt-32">
      <div className="flex items-center justify-between gap-4">
        <h2 className="text-primary md:text-[32px] text-xl font-medium ">
          Dashboard
        </h2>
        {/* <button className="lg:px-6 lg:py-9px max-lg:wh-50px rounded-md bg-[#D7E5FF] flex items-center justify-center gap-4 text-primary text-base font-medium">
          {Svgs.filter}
          <span className="max-lg:hidden">Manage Board</span>
        </button> */}
      </div>
      <div className="lg:mt-10 max-lg:mt-6">
        <div className="">
          <Statistics />
        </div>
        <div className="flex items-stretch gap-6 max-lg:flex-col mt-8">
          <div className="rounded-2xl bg-white p-4 pb-3 shadow-md lg:max-w-65/100 w-full border border-[#F8F9FA] space-y-5">
            <div className="flex items-center justify-between gap-3">
              <h4 className="lg:text-20px max-lg:text-17px text-primary flex items-center gap-5 font-semibold">
                <span className="leading-none">Request Stats</span>
                <Tooltip title="Request Stats">
                  <span>{Svgs.tooltip}</span>
                </Tooltip>
              </h4>
              {/* <div className="flex items-center justify-end gap-3">
                <DatePicker
                  picker="month"
                  onChange={handleGraphDateChange}
                  allowClear={false}
                />
              </div> */}
            </div>
            <div>
              <Spin spinning={requestStatsLoading}>
                <Proposals proposalStatsData={requestStatsData} />
              </Spin>
            </div>
          </div>
          <div className="flex-1 rounded-2xl bg-white p-4 pb-3 shadow-md lg:max-w-60/100 w-full border border-[#F8F9FA] space-y-5 flex flex-col relative">
            <h4 className="lg:text-20px max-lg:text-17px text-primary flex items-center gap-5 font-semibold">
              <span className="leading-none">Top Subscriptions</span>
              <Tooltip title="Top Subscriptions">
                <span>{Svgs.tooltip}</span>
              </Tooltip>
            </h4>
            {/* <p className="text-sm text-[#4E778C] max-w-261px">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.{" "}
            </p> */}
            <div className="p-4 rounded-lg bg-[#F7F7F7] flex flex-col relative flex-1">
              <p className="text-[12px] font-normal text-primary">
                {displayDateString()}
              </p>

              <Subscriptions topServices={topSubscriptions} />
            </div>
            {/* <MoreDropdown classes="absolute top-0 right-3" /> */}
          </div>
        </div>
        <div className="flex items-stretch gap-6 max-lg:flex-col mt-8 lg:mb-14 max-lg:mb-9">
          <div className="rounded-2xl bg-white p-4 pb-3 shadow-md  w-full border border-[#F8F9FA]">
            <div className="flex items-center justify-between gap-3">
              <h4 className="lg:text-20px max-lg:text-17px text-primary flex items-center gap-5 font-semibold">
                <span className="leading-none">Latest Activities Logs</span>
                <Tooltip title="Latest Activities Logs">
                  <span>{Svgs.tooltip}</span>
                </Tooltip>
              </h4>
              <div className="flex items-center justify-end gap-3">
                <DatePicker
                  allowClear={false}
                  picker="month"
                  onChange={handleDateChange}
                />
                {/* <MoreDropdown classes="-mr-3" /> */}
              </div>
            </div>
            <Divider className="!my-3" />
            <Spin spinning={isLoading}>
              {!activityLogs?.length ? (
                <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
                  <Image
                    preview={false}
                    className="lg:max-w-md mx-auto mb-3"
                    src="/assets/images/no-logs-img.svg"
                  />
                  <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2 mb-2">
                    No activity has been performed yet
                  </Text>
                  <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-full">
                    This page will list all the activities performed on the
                    system
                  </Text>
                </section>
              ) : (
                <div className="!mt-0 max-lg:overflow-x-auto overflow-x-auto">
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    bordered={false}
                    pagination={false}
                    className="latest-activity-logs-table max-lg:!max-w-[calc(100vw-82px)]"
                  />
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VendorDashboard;
