import React, { useState } from "react";
import { Button, Modal, Typography, Layout, Flex, Avatar } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { format } from "date-fns";

const { Text } = Typography;
const { Content } = Layout;

const ViewProposal = ({ proposal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div onClick={showModal} className="flex gap-2">
        {Svgs.view}
        View Proposal
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <div className="flex flex-col justify-center items-center mb-4">
            <Text className="md:text-[32px] text-2xl font-medium text-primary text-center mb-3">
              Proposal
            </Text>
            <Text className="text-sm font-normal text-[#888888] text-center max-w-[320px]">
              you can see all details of your proposal for this request.
            </Text>
          </div>
          <div className="flex justify-between items-center mb-6">
            <div className="flex gap-2 items-center">
              <Avatar
                className="w-16 h-16 rounded-full"
                src={
                  proposal?.request?.category?.iconURL ||
                  "/assets/images/avatar.svg"
                }
              />
              <Text className="text-base text-primary font-medium">
                {proposal?.request?.category?.name}
              </Text>
            </div>
            <div className="w-full lg:max-w-[180px] max-w-[140px] bg-[#eaeaea] border border-[#e2e8f0] text-[#303030] rounded-xl flex items-center justify-center gap-2 py-2">
              <span className="text-base font-normal">Price:</span>
              <span className="text-lg font-medium">{proposal?.budget}</span>
            </div>
          </div>
          <div className="flex flex-col gap-1 mb-4">
            <Text className="text-base font-normal text-[#0f172a]">
              Description:
            </Text>
            <Text className="text-sm font-normal text-[#303030] bg-[#eaeaea] border border-[#e2e8f0] rounded-xl p-2">
              {proposal?.description}
            </Text>
          </div>
          <div className="flex flex-col gap-1 mb-4">
            <Text className="text-base font-normal text-[#0f172a]">
              Available Date:
            </Text>
            <div className="flex flex-wrap gap-2">
              {proposal?.availableDates?.map((date) => (
                <Text className="text-sm font-normal text-[#303030] bg-[#eaeaea] border border-[#e2e8f0] rounded-xl py-2 px-6">
                  {format(date, "yyyy-MM-dd")}
                </Text>
              ))}
            </div>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Button
              className="bg-primary border border-primary flex justify-center items-center w-full max-w-[200px] py-6 text-sm font-normal text-white rounded-md"
              type=""
              onClick={handleCancel}
            >
              Okay
            </Button>
          </Flex>
        </Content>
      </Modal>
    </div>
  );
};

export default ViewProposal;
