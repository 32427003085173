import Login from "../pages/login.js";
import SignUp from "../pages/sign-up.js";
import Home from "../pages/dashboard.js";
import AdminProfile from "../pages/admin.js";
import Service from "../pages/service.js";
import ForgetPassword from "../pages/forget-password.js";
import ResetPassword from "../pages/reset-password.js";
import Area from "../pages/area.js";
import Vendor from "../pages/vendor.js";
import Client from "../pages/client.js";
import Credit from "../pages/credit.js";
import CouponCreate from "../pages/coupons/create/index.js";
import CouponUpdate from "../pages/coupons/details/index.js";
import Coupons from "../pages/discount.js";
import Log from "../pages/log.js";
import Proposal from "../pages/proposal.js";
import Profile from "../pages/profile.js";
import Payment from "../pages/payment.js";
import CreateArea from "../pages/area/create";
import UpdateArea from "../pages/area/details";
import ServiceSubscription from "../pages/service-subscription.js";
import PurchaseCredit from "../pages/purchase-credit.js";
import Page404 from "../pages/no-data-found.js";
import ServiceRequest from "../pages/service-request.js";
import ProposalManagement from "../pages/proposal-management.js";
import ServiceRequestDetails from "../pages/request-details.js";
import ProposalDetailPage from "../pages/proposal-details.js";

const routes = [
  {
    path: "/",
    pages: () => <Home />,
  },
  {
    path: "/login",
    pages: () => <Login />,
    isPublic: true,
  },
  {
    path: "/signup",
    pages: SignUp,
    isPublic: true,
  },
  {
    path: "/forget-password",
    pages: ForgetPassword,
    isPublic: true,
  },
  {
    path: "/reset-password/:token",
    pages: ResetPassword,
    isPublic: true,
  },
  {
    path: "/admin",
    pages: () => <AdminProfile />,
    isSuperAdminOnly: true,
  },
  {
    path: "/service",
    pages: Service,
    requiredPermission: "manage_services",
  },

  {
    path: "/area",
    pages: Area,
    requiredPermission: "manage_areas",
  },
  {
    path: "/area/create",
    pages: CreateArea,
    requiredPermission: "manage_areas",
  },

  {
    path: "/area/:areaId",
    pages: UpdateArea,
    requiredPermission: "manage_areas",
  },

  // Discount Code Routes Start
  {
    path: "/coupons",
    pages: Coupons,
    requiredPermission: "manage_discounts",
  },
  {
    path: "/coupons/create",
    pages: CouponCreate,
    requiredPermission: "manage_discounts",
  },
  {
    path: "/coupons/:couponCode",
    pages: CouponUpdate,
    requiredPermission: "manage_discounts",
  },
  // Discount Code Routes end
  {
    path: "/vendor",
    pages: Vendor,
    requiredPermission: "manage_users",
  },
  {
    path: "/vendor/:vendorId",
    pages: Vendor,
    requiredPermission: "manage_users",
  },
  {
    path: "/client",
    pages: Client,
    requiredPermission: "manage_users",
  },
  {
    path: "/client/:userId",
    pages: Client,
    requiredPermission: "manage_users",
  },
  {
    path: "/credit",
    pages: Credit,
    requiredPermission: "manage_credits",
  },
  {
    path: "/log",
    pages: Log,
    requiredPermission: "manage_logs",
  },
  {
    path: "/proposal",
    pages: Proposal,
    requiredPermission: "manage_proposals",
  },
  {
    path: "/proposal/:proposalId",
    pages: ProposalDetailPage,
    requiredPermission: "manage_proposals",
  },
  {
    path: "/profile",
    pages: Profile,
  },
  {
    path: "/payment",
    pages: Payment,
    requiredPermission: "manage_payments",
  },
  {
    path: "/service-subscription",
    pages: ServiceSubscription,
  },
  {
    path: "/purchase-credit",
    pages: PurchaseCredit,
    requiredPermission: "manage_credits",
  },
  {
    path: "/request",
    pages: ServiceRequest,
    requiredPermission: "manage_requests",
  },
  {
    path: "/request/:requestId",
    pages: ServiceRequestDetails,
    requiredPermission: "manage_requests",
  },
  {
    path: "/service-request",
    pages: ServiceRequest,
    requiredPermission: "manage_requests",
  },
  {
    path: "/service-request/:requestId",
    pages: ServiceRequestDetails,
    requiredPermission: "manage_requests",
  },
  {
    path: "/proposal-management",
    pages: ProposalManagement,
  },
  {
    path: "*",
    pages: Page404,
  },
];

export default routes;
