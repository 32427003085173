import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DiscountCategory from "../components/Discount/discount-category";
import DiscountEmpty from "../components/Discount/discount-empty";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import { getUserId } from "../constants";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { getAllCoupons, search_Coupons } from "../store/coupons/couponSlice";

export default function Coupons() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const coupons = useSelector((state) => state.coupons.coupons);
  const total = useSelector((state) => state.coupons.total);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const userId = getUserId();
  const paramsCount = Array.from(searchParams.entries()).length;

  useEffect(() => {
    if (paramsCount) {
      dispatch(
        search_Coupons({
          query: searchParams.get("search"),
          page: 1,
          pageSize: 10,
        })
      );
    } else {
      dispatch(getAllCoupons({ userId, ...pagination }));
    }
    // eslint-disable-next-line 
  }, [dispatch, pagination]);

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };
  useSetTabDetails('Discounts');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Spin spinning={typeof coupons === "undefined"}>
              {!coupons?.length &&
              !paramsCount &&
              typeof coupons !== "undefined" ? (
                <DiscountEmpty />
              ) : (
                <DiscountCategory
                  total={total}
                  coupons={coupons || []}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleTableChange={handleTableChange}
                />
              )}
            </Spin>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
