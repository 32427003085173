import React, { useState } from "react";
import {
  Layout,
  Space,
  Table,
  Dropdown,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Form,
  Avatar,
  Select,
  Menu,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import DeleteProposal from "./delete-proposal";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { constructQueryString } from "../../utils/common-util";
import { useQuery } from "react-query";
import { fetchAllCategories } from "../../service/request";

const { Search } = Input;
const { Text } = Typography;

const UserDropdown = ({ proposalId, proposal, refetch }) => {
  const user = useSelector((state) => state?.user?.user);
  const isClient = user?.role?.name === "CLIENT";
  const navigate = useNavigate();
  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item key="0">
        <div
          onClick={() => navigate(`/proposal/${proposalId}/`)}
          className="flex gap-2"
        >
          {Svgs.view}
          View Details
        </div>
      </Menu.Item>
      {!isClient ? (
        <Menu.Item key="1">
          <DeleteProposal
            refetch={refetch}
            proposalId={proposalId}
            proposal={proposal}
          />
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

const FilterDropdown = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const { data: categories, isLoading } = useQuery(
    ["categories", { activeOnly: true }],
    () => fetchAllCategories(true)
  );

  const handleChange = (value) => {
    setCategory(value);
  };

  return (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Select Service Category"
      >
        <Select
          style={{
            width: "100%",
          }}
          loading={isLoading}
          placeholder="Filter by Service Category"
          onChange={handleChange}
          options={categories?.map((cat) => ({
            value: cat?.data?.id,
            label: cat?.data?.name,
          }))}
        />
      </Form.Item>
      <Button
        className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
        type=""
        onClick={() => {
          const queryString = constructQueryString(
            {
              category,
            },
            "category"
          );
          navigate(`/proposal${queryString}`);
        }}
      >
        Apply Filter
      </Button>
    </Form>
  );
};
function ProposalCategory({
  proposals,
  handleTableChange,
  total,
  pagination,
  paramsCount,
  refetch,
}) {
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isAdmin = user?.role?.name === "ADMIN";

  const dataSource = proposals?.map((p) => {
    return {
      key: p.id,
      proposal: p,
      proposalId: p.id,
      servicecategory: p?.request?.category?.id,
      client: p?.user?.name || "Guest",
      description: `${p?.description?.slice(0, 25)}...`,
      requestdate: format(p?.createdAt, "MM-dd-yyyy"),
      response: "N/A",
      status: p?.status,
      budget: `$${p.budget}`,
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Proposal ID",
        dataIndex: "proposalId",
        key: "0",
        render: (text, record) => (
          <Space className="flex gap-2 items-center">
            <span>{text}</span>{" "}
          </Space>
        ),
      },
      {
        title: "Request ID",
        dataIndex: "servicecategory",
        key: "0",
        render: (text, record) => (
          <Space className="flex gap-2 items-center">
            {/* <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src={
                record?.proposal?.request?.category?.iconURL ||
                "/assets/images/avatar.svg"
              }
            /> */}
            <span>{text}</span>{" "}
          </Space>
        ),
      },
      isAdmin
        ? {
            title: "Client",
            dataIndex: "client",
            key: "0",
            render: (text) => (
              <Space className="flex gap-2 items-center">
                <Avatar
                  className="rounded-full w-9 h-9 flex justify-center items-center"
                  size="large"
                  src="/assets/images/avatar-vendor.svg"
                />
                <span className="text-primary underline">{text}</span>{" "}
              </Space>
            ),
          }
        : null,
      {
        title: "Request Date",
        dataIndex: "requestdate",
        key: "0",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      !isAdmin
        ? {
            title: "Price",
            dataIndex: "budget",
            key: "0",
          }
        : null,
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (text, record) =>
          text === "accepted" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                Accept
              </div>
            </Space>
          ) : text === "rejected" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#F00F00] py-[2px] px-2 rounded-2xl">
                Rejected
              </div>
            </Space>
          ) : (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#FFC107] py-[2px] px-2 rounded-2xl">
                Pending
              </div>
            </Space>
          ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={
                <UserDropdown
                  proposalId={record.proposalId}
                  proposal={record.proposal}
                  refetch={refetch}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ].filter(Boolean);
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Proposals</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            All Proposals
          </Text>
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search"
          value={searchParams.get("search")}
          onChange={(e) => {
            const queryString = constructQueryString(
              {
                search: e.target.value,
              },
              "search"
            );
            navigate(`/proposal${queryString}`);
          }}
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                search: value,
              },
              "search"
            );
            navigate(`/proposal${queryString}`);
          }}
        />
        {
          <div className="flex gap-2 items-center">
            <Space size="middle" className="w-full">
              <Dropdown
                overlay={<FilterDropdown />}
                placement="bottomRight"
                trigger={["click"]}
                className="cursor-pointer"
              >
                <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
                  {Svgs.filter} <span>Filter</span>
                </Space>
              </Dropdown>
            </Space>
            {paramsCount ? (
              <Button
                onClick={() => navigate(`/proposal`)}
                type=""
                className="bg-[#D7E5FF] flex gap-2 justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg"
              >
                <span>Clear</span> {Svgs.cross}
              </Button>
            ) : null}
          </div>
        }
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          dataSource={dataSource}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default ProposalCategory;
