import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import _debounce from "lodash.debounce";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  filter_Areas,
  getAllArea,
  search_Areas,
  update_Area,
} from "../../store/area/areaSlice";
import { Svgs } from "../Svgs/svg-icons";
import DeleteArea from "./delete-area";

const { Search } = Input;
const { Text } = Typography;

function CategoryAreaList(props) {
  const { areas, areaCategories, total, pagination, handleTableChange } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const areaZipCodes = searchParams.get("areaZipCodeSearch");
  const areaZipCodeSearchArray = areaZipCodes ? areaZipCodes.split("+") : [];

  const [areaZipCodeSearch, setAreaZipCodeSearch] = useState(
    areaZipCodeSearchArray
  );
  const [areaCatId, setAreaCatId] = useState(searchParams.get("areaCatId"));


  const handleInputChange = _debounce((value = "") => {
    dispatch(search_Areas({ query: value.trim(), page: 1, pageSize: 10 }));
  }, 500);

  const handleStatusToggle = async (record) => {
    message.loading({ content: "Updating Area status..." });
    try {
      await dispatch(
        update_Area({ id: record?.id, isActive: !record?.isActive })
      );

      dispatch(getAllArea({ ...pagination }));
      message.destroy();
      toast.success("Area status updated successfully");
    } catch (error) {
      const errorMessage = error?.message?.message || "Failed to update status";
      message.destroy();
      toast.error(errorMessage);
    }
  };

  const constructQueryString = (params, key) => {
    const queryParts = [];
    if (key && params[key]) {
      queryParts.push(`${key}=${encodeURIComponent(params[key])}`);
    }
    if (params.areaZipCodeSearch) {
      queryParts.push(
        `areaZipCodeSearch=${encodeURIComponent(
          params.areaZipCodeSearch.join("+")
        )}`
      );
    }
    if (params.areaCatId) {
      queryParts.push(`areaCatId=${encodeURIComponent(params.areaCatId)}`);
    }
    return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
  };

  const userDropdown = (record) => (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item className="dropdown__item__header" key="1" icon={Svgs.editg}>
        <Link to={`/area/${record.id}`}>Edit Area</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <DeleteArea data={record} />
      </Menu.Item>
    </Menu>
  );

  const filterDropdown = (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Area Category:"
      >
        <Select
          showSearch
          allowClear
          size="large"
          className="w-full super__select text-sm font-normal text-dark"
          placeholder="Choose Area:"
          optionFilterProp="children"
          value={areaCategories.find((item) => item.id === +areaCatId)?.areaCategoryName}
          style={{ width: "300px" }}
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value) => setAreaCatId(value)}
          options={areaCategories?.map((category) => ({
            value: category?.id,
            label: category?.areaCategoryName,
          }))}
        />
      </Form.Item>
      <Row>
        <Col sm={12}>
          <Button
            className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
            onClick={async () => {
              const queryString = constructQueryString({
                areaZipCodeSearch,
                areaCatId,
              });
              await dispatch(
                filter_Areas({
                  page: 1,
                  pageSize: 10,
                  zipCodes: areaZipCodeSearch,
                  areaCategoryId: areaCatId,
                })
              );
              navigate(`/area${queryString}`);
            }}
            disabled={!areaZipCodeSearch.length && !areaCatId}
          >
            Apply Filter
          </Button>
        </Col>
        <Col sm={12}>
          <Button
            className="flex justify-center items-center py-5 mt-2"
            type="default"
            onClick={async () => {
              setAreaZipCodeSearch([]);
              setAreaCatId(null);
              await dispatch(
                filter_Areas({
                  page: 1,
                  pageSize: 10,
                  zipCodes: [],
                  areaCategoryId: null,
                })
              );
              navigate(`/area`);
            }}
            disabled={!areaZipCodeSearch.length && !areaCatId}
          >
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const dataSource = areas.map((area) => {
    return {
      ...area,
      name: area.name,
      zipCodes: area.zipCodes,
      areaCategoryName: area?.areaCategory?.areaCategoryName || 'N/A',
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Area Name",
        dataIndex: "name",
        key: "0",
        sorter: (a, b) => a.name < b.name,
      },
      {
        title: "Zip Codes",
        dataIndex: "zipCodes",
        key: "zipCodes",
        render: (zipCodes) => {
          if (zipCodes.length > 3) {
            return (
              <Tooltip title={zipCodes.join(", ")} placement="topLeft">
                <span>{zipCodes.slice(0, 3).join(", ")}</span>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={zipCodes.join(",")} placement="topLeft">
                <span>{zipCodes.join(", ")}</span>
              </Tooltip>
            );
          }
        },
        sorter: (a, b) => a.zipCodes < b.zipCodes,
      },
      {
        title: "Area Category",
        dataIndex: "areaCategoryName",
        key: "areaCategoryName",
        sorter: (a, b) => a.areaCategoryName < b.areaCategoryName,
        render: (areaCategoryName) => {
          return areaCategoryName;
        },
      },
      {
        title: "Credit Value",
        dataIndex: "creditValue",
        key: "creditValue",
        sorter: (a, b) => a.creditValue < b.creditValue,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record, rowIndex) => (
          <Space
            className="cursor-pointer"
            onClick={() => handleStatusToggle(record)}
          >
            {record?.isActive === true ? (
              <Tooltip title="Pause Area">
                <Space className="">{Svgs.pause}</Space>
              </Tooltip>
            ) : (
              <Tooltip title="Resume Area">
                <Space className="">{Svgs.resume}</Space>
              </Tooltip>
            )}
          </Space>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={userDropdown(record)}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/area">Area Management</Link>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Area Management
          </Text>
        </Col>
        <Col>
          <Button
            className="bg-primary flex justify-center items-center w-full max-w-[160px] py-5 text-base font-normal text-white rounded-lg"
            type=""
            icon={Svgs.addiconw}
            onClick={() => navigate("/area/create")}
          >
            Add New Area
          </Button>
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          className="search-input-custom w-full max-w-[400px] h-30"
          placeholder="Search area name or zip Codes"
          defaultValue={searchParams.get("search")}
          onChange={(e) => {
            if (!e.target.value) {
              handleInputChange(e.target.value);
            }
          }}
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                search: value,
              },
              "search"
            );
            navigate(`/area${queryString}`);
            handleInputChange(value);
          }}
        />
        <Space size="middle" className="w-full">
          <Dropdown
            overlay={filterDropdown}
            placement="bottomRight"
            trigger={["click"]}
            className="cursor-pointer"
          >
            <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
              {Svgs.filter} <span>Filter</span>
            </Space>
          </Dropdown>
        </Space>
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default CategoryAreaList;
