import axios from "axios";
const axiosInstance = axios.create({
  baseURL: "https://backend-dta.seekmyservice.com",
  //baseURL: "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem('token')}`,
  },
});

export default axiosInstance;
