import {
  Button,
  Flex,
  Form,
  InputNumber,
  Modal,
  Spin,
  Typography
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { Field, Formik } from "formik";
import _pick from "lodash.pick";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../config/Axios";
import { getUserId } from "../../constants";
import { getFormItemValidationProps } from "../../utils/form-util";
import { Svgs } from "../Svgs/svg-icons";
import { validateProposalForm } from "./validation";

const { Text } = Typography;

const SubmitProposal = ({
  request,
  refetch,
  isAlreadyProposalSubmitted,
  isDropdown,
  proposal,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mutation = useMutation((newProposal) => {
    return axiosInstance.post("/proposals", newProposal);
  });

  const updateMutation = useMutation((data) => {
    return axiosInstance.put(`/proposals/${proposal.id}`, data);
  });

  const handleSubmit = async (variables) => {
    try {
      if (proposal) {
        await updateMutation.mutateAsync(
          _pick(variables, ["description", "budget", "availableDates"])
        );
      } else {
        await mutation.mutateAsync(variables);
      }
      handleCancel();
      if (proposal) {
        toast.success("Proposal Updated Successfully");
      } else {
        toast.success("Proposal Submitted Successfully");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {proposal?.status === "withdraw" ? (
        <div
          className={`flex gap-3 items-center ${
            !isAlreadyProposalSubmitted ? "bg-primary" : "bg-success"
          } py-2 px-3 rounded cursor-pointer w-full max-w-[260px]`}
        >
          <div className="flex items-center gap-2">
            <Text className="text-base text-white font-normal">
              Proposal Withdrawn
            </Text>
          </div>
        </div>
      ) : !isDropdown ? (
        <div
          onClick={isAlreadyProposalSubmitted ? null : showModal}
          className={`flex gap-3 items-center ${
            !isAlreadyProposalSubmitted ? "bg-primary" : "bg-success"
          } py-2 px-3 rounded cursor-pointer w-full max-w-[260px]`}
        >
          <div className="flex items-center gap-2">
            <Text className="text-base text-white font-normal">
              {isAlreadyProposalSubmitted
                ? "Proposal Submitted"
                : "Submit Proposal"}
            </Text>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-3" onClick={showModal}>
          {Svgs.editg} Edit Proposal
        </div>
      )}
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <div className="flex flex-col items-center">
          <Text className="md:text-[32px] text-2xl font-medium text-primary text-center mb-3">
            Submit Proposal
          </Text>
          <Text className="text-sm font-normal text-[#888888] max-w-[300px] mx-auto w-full text-center">
            Fill the information below to add proposal as per your requirement.
          </Text>
        </div>
        <Spin spinning={mutation.isLoading || updateMutation.isLoading}>
          <Formik
            initialValues={{
              availableDates: [],
              availableDate: dayjs(proposal?.availableDates[0], "YYYY-MM-DD"),
              budget: proposal?.budget || null,
              description: null,
              ...proposal,
            }}
            validate={validateProposalForm}
            onSubmit={async (values) => {
              try {
                const variables = {
                  requestId: request?.id,
                  serviceId: request?.category?.id,
                  budget: values.budget,
                  description: values?.description,
                  availableDates: values?.availableDates,
                  userId: getUserId(),
                  status: "pending",
                };
                await handleSubmit(variables);
                if (refetch) {
                  await refetch();
                }
              } catch (error) {
                toast.error(error.message || "Something went wrong");
              }
            }}
          >
            {({ handleSubmit, dirty }) => {
              return (
                <Form
                  name="login"
                  size="large"
                  layout="vertical"
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    margin: "30px auto 0 auto",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="flex flex-col gap-3"
                >
                  <Field name="budget">
                    {({ form, field }) => (
                      <Form.Item
                        label="Price:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <InputNumber
                          {...field}
                          size="large"
                          placeholder="Enter price"
                          min={1}
                          max={10000}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          className="super__select text-sm font-normal text-dark w-full"
                          prefix={<></>}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Field name="description">
                    {({ form, field }) => (
                      <Form.Item
                        label="Description:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <TextArea
                          {...field}
                          className="font-normal text-sm"
                          autoComplete="on"
                          autoSize={{ minRows: 4, maxRows: 5 }}
                          placeholder="Write description here..."
                        />
                      </Form.Item>
                    )}
                  </Field>
                  {/* <Field name="availableDates">
                    {({ form, field }) => (
                      <Form.Item
                        label="Available Date:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <DatePicker
                          className="super__select !text-sm font-normal text-dark w-full !h-14"
                          onChange={(_, dateString) => {
                            form.setFieldValue("availableDates", [dateString]);
                          }}
                          disabledDate={(current) => {
                            const startOfToday = new Date();
                            startOfToday.setHours(0, 0, 0, 0);
                            return (
                              current &&
                              new Date(current).getTime() <
                                startOfToday.getTime()
                            );
                          }}
                          defaultValue={proposal ? form.values.availableDate : null}
                        />
                      </Form.Item>
                    )}
                  </Field> */}
                  <Form.Item>
                    <Flex vertical style={{ marginTop: 20 }}>
                      <Button
                        onClick={handleSubmit}
                        disabled={!dirty}
                        className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[240px] mx-auto"
                        type="primary"
                        htmlType="submit"
                        block
                      >
                        Submit
                      </Button>
                    </Flex>
                  </Form.Item>
                </Form>
              );
            }}
          </Formik>
        </Spin>
      </Modal>
    </div>
  );
};

export default SubmitProposal;
