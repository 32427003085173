import React from "react";
import { Formik } from "formik";
import DiscountCode from "./discount-code";
import { validateCouponForm } from "./validation";

const DiscountForm = (props) => {
  const { fixedValue = {}, title, subTitle, handleSubmit, fixedCoupon } = props;
  return (
    <Formik
      enableReinitialize={fixedValue.FORM_MODE === "update"}
      initialValues={{
        couponCode: null,
        couponType: "Percentage",
        couponName: null,
        redemptionLimit: null,
        isCouponIdAutoGenerated: false,
        couponUsageDate: null,
        discountPercentage: null,
        discountValue: null,
        isCouponHasLimit: typeof fixedValue.redemptionLimit !== "undefined",
        isCouponHasDateLimit: fixedValue.startDate && fixedValue.expDate,
        fixedCoupon,
        ...fixedValue,
      }}
      validate={validateCouponForm}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <DiscountCode {...formikProps} title={title} subTitle={subTitle} />
        );
      }}
    </Formik>
  );
};

export default DiscountForm;
