import React, { useMemo } from "react";
import { Breadcrumb, Row, Col, Button, InputNumber } from "antd";
import { Typography, Form, Input, Select, Checkbox, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { Field } from "formik";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import { Svgs } from "../Svgs/svg-icons";
import { getFormItemValidationProps } from "../../utils/form-util";
import { validateCouponCode } from "../../store/coupons/couponSlice";
import { useDispatch } from "react-redux";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const DiscountCode = (props) => {
  const {
    handleSubmit,
    values,
    title,
    subTitle,
    isSubmitting,
    dirty,
    setFieldValue,
  } = props;

  const dispatch = useDispatch();
  const isEditMode = values.FORM_MODE === "update";
  const fixedCoupon = values.fixedCoupon;

  const handleInputChange = debounce(async (value = "") => {
    try {
      const discountCode = await dispatch(validateCouponCode(value)).unwrap();
      if (discountCode?.isCouponNotExist) {
        setFieldValue("isCouponAlreadyExist", null);
      }
    } catch (e) {
      const message = e.message?.message;
      if ((message || "").includes("already exists")) {
        setFieldValue("isCouponAlreadyExist", message);
      }
    }
  }, 500);

  const percentageField = () => {
    const isPercentage = values.couponType === "Percentage";

    const label = isPercentage ? "Percentage:" : "Amount:";
    const placeholder = isPercentage ? "Percentage" : "Amount";
    const name = isPercentage ? "discountPercentage" : "discountValue";
    const suffix = isPercentage ? Svgs.percentage : <span />;
    const max = isPercentage ? 100 : 100000;
    return {
      label,
      suffix,
      placeholder,
      name,
      max,
    };
  };

  const generateUniqueCouponId = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const defaultRangeValue = useMemo(() => {
    if (values.startDate && values.expDate) {
      return [
        dayjs(values.startDate, "YYYY-MM-DD"),
        dayjs(values.expDate, "YYYY-MM-DD"),
      ];
    }
    return [];
  }, [values.startDate, values.expDate]);

  return (
    <React.Fragment>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/coupons">Discount Codes</Link>,
          },
          {
            title: <Link>Add Codes</Link>,
          },
        ]}
      />
      <Row className="flex flex-col lg:flex-row justify-between lg:items-center items-start mb-1">
        <Col className="gap-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center justify-start gap-4 mb-3">
            <Link to="/coupons">{Svgs.arrowback}</Link>
            {title || "Add New Discount Code"}
          </Text>
          <Text className="text-[#868687] text-sm font-normal mb-4 block">
            {subTitle ||
              `Add a new discounts/coupon by entering its name, type, amount,
            duration.`}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            name="login"
            size="large"
            layout="vertical"
            className=" lg:p-6 p-5 rounded-lg bg-[#F5F5F5]"
          >
            <Form.Item
              className="super__select !mb-2"
              style={{ fontWeight: "400" }}
            >
              <Field name="isCouponIdAutoGenerated">
                {({ field, form }) => (
                  <Form.Item valuePropName="" noStyle>
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        const checkboxChecked = e.target.checked;
                        form.setFieldValue(field.name, checkboxChecked);

                        if (
                          checkboxChecked &&
                          !values.isCouponIdAutoGenerated
                        ) {
                          const newCouponId = generateUniqueCouponId();
                          form.setFieldValue("couponCode", newCouponId);
                          form.setFieldValue("isCouponIdAutoGenerated", true);
                        }
                      }}
                      className="super__select md:text-xl text-base text-primary font-medium flex"
                    >
                      <div className="flex items-center gap-2">
                        <span>Generate auto Coupon ID</span>
                        <span>{Svgs.exclamatory}</span>
                      </div>
                    </Checkbox>
                  </Form.Item>
                )}
              </Field>
            </Form.Item>
            <div className="grid lg:grid-cols-2 gap-3">
              <Field name="couponCode">
                {({ field, form }) => (
                  <Form.Item
                    label="Coupon Id:"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      disabled={values.isCouponIdAutoGenerated}
                      onChange={(e) => {
                        field.onChange(e);
                        form.setFieldValue("isCouponIdAutoGenerated", false);
                        if (fixedCoupon?.couponCode !== e.target.value && isEditMode) {
                          handleInputChange(e.target.value);
                          return;
                        }
                        if (!isEditMode) {
                          handleInputChange(e.target.value);
                        }
                      }}
                      size="large"
                      placeholder="Enter ID eg. 221155"
                      className="super__select text-sm font-normal text-dark flex-1"
                      prefix={<></>}
                    />
                  </Form.Item>
                )}
              </Field>
              <Field name="couponName">
                {({ field, form }) => (
                  <Form.Item
                    label="Coupon Name:"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      size="large"
                      placeholder="Enter Name"
                      className="super__select text-sm font-normal text-dark flex-1"
                      prefix={<></>}
                    />
                  </Form.Item>
                )}
              </Field>
              <div className="flex flex-col gap-1">
                <Field name="couponType">
                  {({ field, form }) => (
                    <Form.Item
                      className="super__select"
                      label="Type:"
                      required
                      {...getFormItemValidationProps(field, form)}
                    >
                      <Select
                        {...field}
                        defaultValue="Percentage"
                        size="large"
                        className="w-full super__select text-sm font-normal text-dark"
                        placeholder="Select Type"
                        optionFilterProp="children"
                        onChange={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      >
                        <Select.Option value="Percentage">
                          Percentage
                        </Select.Option>
                        <Select.Option value="Fixed">Fixed</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Field>
              </div>
              <Field name={percentageField().name}>
                {({ field, form }) => (
                  <Form.Item
                    label={percentageField().label}
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <InputNumber
                      {...field}
                      min={0}
                      max={percentageField().max}
                      size="large"
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      placeholder={percentageField().placeholder}
                      style={{ width: "100%" }}
                      className="super__select text-sm font-normal text-dark flex-1"
                      suffix={percentageField().suffix}
                    />
                  </Form.Item>
                )}
              </Field>
            </div>
          </Form>
        </Col>
        <Col span={24}>
          <Text className="md:text-lg text-base font-medium text-[#303030] capitalize my-3 block">
            Redemption Limits:
          </Text>
          <Form
            name="login"
            size="large"
            layout="vertical"
            className="flex flex-col gap-1 lg:p-6 p-5 rounded-lg bg-[#F5F5F5]"
          >
            <div>
              <Field name="isCouponHasDateLimit">
                {({ field, form }) => (
                  <Form.Item>
                    <Checkbox
                      checked={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                      }
                      className="super__select md:text-xl text-base text-primary font-medium flex"
                    >
                      <div className="flex items-center gap-2">
                        <span>
                          Limit the date range when customers can redeem this
                          coupon
                        </span>
                        <span>{Svgs.exclamatory}</span>
                      </div>
                    </Checkbox>
                  </Form.Item>
                )}
              </Field>
              {values.isCouponHasDateLimit && (
                <Field name="couponUsageDate">
                  {({ field, form }) => {
                    return (
                      <Form.Item
                        className="!mt-1"
                        label="Date Range:"
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <RangePicker
                          value={defaultRangeValue}
                          className="!mb-2"
                          format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                          onChange={(dates) => {
                            if (dates && dates.length === 2) {
                              const startDate = dates[0].format("YYYY-MM-DD");
                              const expDate = dates[1].format("YYYY-MM-DD");
                              form.setFieldValue("startDate", startDate);
                              form.setFieldValue("expDate", expDate);
                            } else {
                              form.setFieldValue("startDate", null);
                              form.setFieldValue("expDate", null);
                            }
                          }}
                        />
                      </Form.Item>
                    );
                  }}
                </Field>
              )}
            </div>
            <div>
              <Field name="isCouponHasLimit">
                {({ field, form }) => (
                  <Form.Item noStyle>
                    <Checkbox
                      checked={field.value}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                      }
                      className="super__select md:text-xl text-base text-primary font-medium flex"
                    >
                      <div className="flex items-center gap-2">
                        <span>
                          Limit the total number of times this coupon can be
                          redeemed
                        </span>
                        <span>{Svgs.exclamatory}</span>
                      </div>
                    </Checkbox>
                  </Form.Item>
                )}
              </Field>
              {values.isCouponHasLimit && (
                <Field name="redemptionLimit">
                  {({ field, form }) => (
                    <Form.Item
                      className="!mt-1"
                      label="Number of Times Redemption:"
                      required
                      {...getFormItemValidationProps(field, form)}
                    >
                      <InputNumber
                        min={0}
                        {...field}
                        size="large"
                        onChange={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                        placeholder="Number of Times Redemption:"
                        className="super__select text-sm font-normal text-dark flex-1 w-full"
                      />
                    </Form.Item>
                  )}
                </Field>
              )}
            </div>
          </Form>
        </Col>
        <Col span={24} className="flex justify-center !mt-10">
          <Button
            disabled={isSubmitting || !dirty}
            className="bg-primary lg:text-xl text-base font-normal text-white rounded-xl !h-14 w-full max-w-[320px]"
            htmlType="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DiscountCode;
