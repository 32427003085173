import React from "react";
import ReactApexChart from "react-apexcharts";

function Subscriptions({ topServices, isAdmin }) {
  const chartData = {
    series:
      topServices && topServices.length > 0
        ? topServices.map((service) => parseFloat(service.subscriptionCount))
        : [0],
    options: {
      chart: {
        type: "donut",
        width: "100%",
        height: 500,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          expandOnClick: false,
          donut: {
            size: "80%",
            labels: {
              show: true,
              total: {
                show: true,
                label: isAdmin ? "Top Services" :"Top Subscriptions",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                },
              },
            },
          },
          dataLabels: {
            enabled: false, // Hide labels on each pie slice
          },
        },
      },
      dataLabels: {
        enabled: false, // Hide labels on each pie slice
      },
      labels:
        topServices && topServices.length > 0
          ? topServices.map((service) => service.categoryName)
          : ["No Data"],
      legend: {
        show: true,
        position: "bottom",
      },
      title: {
        display: false,
      },
      colors: ["#5C61F2", "#FF9766", "#61AE41"], // Set colors for each pie slice
    },
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="donut"
      width="100%"
      height="400"
    />
  );
}

export default Subscriptions;
