import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROLES_TYPES } from "../../utils/user-util";
import Logo from "../Logo";
import Logout from "./logout";
import { clientMenuItems, menuItems, vendorMenuItems } from "./menu-items";
import RenderMenus from "./render-menu";

function Nav() {
  const user = useSelector((state) => state?.user?.user);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate("/signup");
  // };
  let navContent = <RenderMenus menus={menuItems} />;
  if (user?.role?.name === ROLES_TYPES.VENDOR) {
    navContent = <RenderMenus menus={vendorMenuItems} />;
  } else if (user?.role?.name === ROLES_TYPES.CLIENT) {
    navContent = <RenderMenus menus={clientMenuItems} />;
  }

  return (
    <div className="px-2 py-6">
      <Link to="/">
        <Logo />
      </Link>
      <PerfectScrollbar className="height-scroll">
        {navContent}
      </PerfectScrollbar>
      <Link className="border-[#CED3DA] border-opacity-[0.2] border-t-2 pt-5 w-[94%] lg:fixed lg:bottom-0 lg:left-[2.5] bg-primary h-[100px] lg:w-[260px]">
        <Logout />
      </Link>
    </div>
  );
}

export default Nav;
