import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import roleReducer from "./roles/roleSlice";
import tabDetailsReducer from "./tab-details/tabSlice";
import categoryReducer from "./category/categorySlice";
import areaReducer from "./area/areaSlice";
import clientRequestReducer from "./clientRequest/clientRequestSlice";
import serviceReducer from "./service/serviceSlice";
import userCreditsReducer from "./userCredits/userCreditsSlice";
import couponsReducer from "./coupons/couponSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import vendorReducer from "./vendors/vendorsSlice";
import clientReducer from "./clients/clientsSlice";
import vendorProposalReducer from "./vendorProposal/vendorProposalSlice";
import areaCategoryReducer from "./areaCategory/areaCategorySlice";
import clientProposalReducer from "./clientProposal/clientProposalSlice";
import clientSubscriptionReducer from "./clientSubscription/clientSubscriptionSlice";
const rootReducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  category: categoryReducer,
  area: areaReducer,
  clientRequest: clientRequestReducer,
  services: serviceReducer,
  userCredits: userCreditsReducer,
  vendor: vendorReducer,
  vendorProposal: vendorProposalReducer,
  coupons: couponsReducer,
  areaCategory: areaCategoryReducer,
  client: clientReducer,
  clientSubscription: clientSubscriptionReducer,
  clientProposal: clientProposalReducer,
  tabDetails: tabDetailsReducer
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
