/* eslint-disable array-callback-return */
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { update_User } from "../../store/user/userSlice";
import { getAllVendors } from "../../store/vendors/vendorsSlice";
import { Svgs } from "../Svgs/svg-icons";
import AddVendor from "./add-vendor";
import DeleteVendor from "./delete-vendor";
import EditVendor from "./edit-vendor";
import VendorEmpty from "./vendor-empty";
const { Search } = Input;
const { Text } = Typography;

function VendorCategory({ data, categories, allArea }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [orginalData, setOrginalData] = useState(data);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAreaOption, setSelectedAreaOption] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const handleActiveFileter = (booleanValue) => setClearFilter(booleanValue);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const userDropdown = (record) => (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item
        className="dropdown__item__header"
        key="0"
        icon={Svgs.view}
        onClick={() => navigate(`/vendor/${record.userId}`)}
        trigger={["click"]}
      >
        <span>View Details</span>
      </Menu.Item>
      <Menu.Item key="1">
        <EditVendor data={record} />
      </Menu.Item>
      <Menu.Item key="2">
        <DeleteVendor data={record} />
      </Menu.Item>
    </Menu>
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
    handleActiveFileter(!!value);
    filterData();
  };
  const handleOptionChange = (value, option) => {
    handleActiveFileter(!!option);
    setSelectedOption(option);
  };

  const handleAreaOptionChange = (value, option) => {
    handleActiveFileter(!!option);
    setSelectedAreaOption(option);
  };
  const handleStatusOptionChange = (value) => {
    handleActiveFileter(!!value);
    setSelectedStatusOption(value);
  };

  const handleApplyFilter = () => {
    filterData();
  };
  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, data, clearFilter]);
  useEffect(() => {
    setFilteredData(data);
    setOrginalData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterData = () => {
    const lowercasedValue = searchTerm.toLowerCase();
    let filtered = data;
    let optionObject = selectedOption;
    let optionAreaObject = selectedAreaOption;
    if (optionObject) {
      const category = filtered.filter((e) => {
        if (e?.categoryId === optionObject?.id) {
          return e;
        }
      });
      filtered = category;
    }

    if (!optionObject) {
      filtered = filtered.filter((item) =>
        item?.name.toLowerCase().includes(lowercasedValue)
      );
    }

    if (optionAreaObject) {
      const area = filtered.filter((e) => {
        if (e?.categoryId === optionAreaObject?.id) {
          return e;
        }
      });
      filtered = area;
    }
    let status;
    if (selectedStatusOption) {
      let statusValue = selectedStatusOption === "Active" ? 1 : 0;
      status = filtered.filter((e) => {
        if (e?.isPaused === statusValue) {
          return e;
        }
      });
    }
    setFilteredData(status || filtered);
  };
  const handleStatusToggle = async (record) => {
    let id = record?.userId;
    const createdAt = new Date(record?.createdAt);
    const updatedAt = new Date();

    const updatedFormValues = {
      userId: id,
      name: String(record?.name),
      email: String(record?.email),
      createdBy: Number(record?.createdBy),
      updatedBy: Number(record?.updatedBy),
      isPaused: !record.isPaused,
      roleId: 3,
      password: String(record?.password),
      phoneNumber: String(record?.phoneNumber),
      createdAt: isNaN(createdAt) ? null : createdAt,
      updatedAt: updatedAt,
    };

    const updatedData =
      data &&
      data?.map((item) => {
        return item?.userId === record?.userId
          ? { ...item, isPaused: !record.isPaused }
          : item;
      });
    const resultAction = await dispatch(
      update_User({ id, ...updatedFormValues })
    );
    if (resultAction.payload) {
      toast.success("User Status Updated");
      dispatch(getAllVendors());
    } else if (resultAction.error) {
      toast.error(resultAction.error.message);
    }
    setOrginalData(updatedData);
    setFilteredData(updatedData);
  };
  const handleClearFilter = () => {
    setSelectedAreaOption(null);
    setSelectedStatusOption(null);
    setSelectedOption(null);
    setSearchTerm("");
    setClearFilter(true);
  };

  const filterDropdown = (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Service:"
      >
        <Select
          showSearch
          size="large"
          className="w-full super__select text-sm font-normal text-dark"
          placeholder="Filter by service"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value, option) => handleOptionChange(value, option)}
          value={selectedOption?.value}
          options={
            categories &&
            categories?.map((item) => ({
              id: item?.id,
              value: item?.name,
              label: item?.name,
            }))
          }
        />
      </Form.Item>
      <Form.Item className="super__select filter-dropdown w-full" label="Area:">
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Filter by area"
          onChange={(value, option) => handleAreaOptionChange(value, option)}
          value={selectedAreaOption?.value}
          options={
            allArea.length > 0
              ? allArea?.map((item) => ({
                  id: item?.categoryId,
                  value: item?.name,
                  label: item?.name,
                }))
              : []
          }
        />
      </Form.Item>
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Status:"
      >
        <Select
          showSearch
          size="large"
          className="w-full super__select text-sm font-normal text-dark"
          placeholder="Filter by status:"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value) => handleStatusOptionChange(value)}
          value={selectedStatusOption}
          options={[
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Inactive",
              label: "Inactive",
            },
          ]}
        />
      </Form.Item>
      <div className="flex gap-2 justify-center mt-2">
        <Button
          className="bg-primary flex justify-center items-center w-full max-w-[100px] py-5 text-base font-normal text-white rounded-lg"
          type=""
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Button>
        {!!clearFilter && (
          <Button
            className="bg-[#D9D9D9] flex justify-center items-center w-full max-w-[100px] py-5 text-base font-normal text-black rounded-lg"
            type=""
            onClick={handleClearFilter}
          >
            Clear Filter
          </Button>
        )}
      </div>
    </Form>
  );
  const getColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "0",
        render: (text, record) => (
          <Link to={`/vendor/${record.userId}`}>
            <Space className="flex gap-1 items-center">
              <Avatar
                  className="rounded-full w-9 h-9 flex justify-center items-center"
                  size="large"
                  src="/assets/images/avatar-vendor.svg"
              />
              <span>{text}</span>{" "}
            </Space>
          </Link>
        ),
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: "Email address",
        dataIndex: "email",
        key: "0",
      },
      {
        title: "Join Date",
        dataIndex: "createdAt",
        key: "0",
        sorter: (a, b) => a.age - b.age,
        render: (text) => <span>{formatDate(text)}</span>,
      },
      {
        title: "Credit Value",
        dataIndex: "balanceCredits",
        key: "0",
        sorter: (a, b) => a.age - b.age,
        render: (balanceCredits) => <span>{balanceCredits || 0}</span>,
      },
      {
        title: "Status",
        dataIndex: ["data", "isPaused"],
        key: "0",
        render: (text, record, rowIndex) => (
          <Spin spinning={false}>
            <Space
              className="cursor-pointer"
              onClick={() => handleStatusToggle(record)}
            >
              {record?.isPaused === true ? (
                <Tooltip title="Active User">
                  <Space className="">{Svgs.pause}</Space>
                </Tooltip>
              ) : (
                <Tooltip title="Pause User">
                  <Space className="">{Svgs.resume}</Space>
                </Tooltip>
              )}
            </Space>
          </Spin>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={userDropdown(record)}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span >User Management</span>,
          },
          {
            title: <span >Vendor</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Vendor
          </Text>
        </Col>
        <Col>
          <AddVendor />
        </Col>
      </Row>
      {!!data?.length && (
        <div className="flex gap-3 mb-5">
          <Search
            allowClear
            value={searchTerm}
            className="search-input-custom w-full max-w-[400px]"
            placeholder="Search Service"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Space size="middle" className="w-full">
            <Dropdown
              overlay={filterDropdown}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="bg-[#D7E5FF] !h-[50px] flex justify-center items-center w-full px-5 text-base font-normal text-primary rounded-lg">
                {Svgs.filter} <span>Filter</span>
              </Space>
            </Dropdown>
          </Space>
        </div>
      )}
      <div>
        {filteredData.length > 0 ? (
          <Table
            className="service-table"
            columns={getColumns()}
            dataSource={filteredData}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: orginalData.length,
            }}
          />
        ) : (
          <VendorEmpty />
        )}
      </div>
    </Layout>
  );
}

export default VendorCategory;
