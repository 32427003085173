import axios from "../config/Axios";

export const fetchUserCredits = () => {
  return axios.get("/user-credits");
};

export const createUserCredits = (userCreditData) => {
  return axios.post("/user-credits", userCreditData);
};

export const updateUserCredits = (id, userCreditData) => {
  return axios.put(`/user-credits/${id}`, userCreditData);
};

export const deleteUserCredits = (id) => {
  return axios.delete(`/user-credits/${id}`);
};
