import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createCategory,
  deleteCategory,
  fetchCategories,
  searchActiveServices,
  searchCategories,
  updateCategory,
} from "../../service/catergoryService";

const initialState = {
  category: [],
};

export const getAllCategory = createAsyncThunk(
  "users/getAllCategory",
  async (pagination) => {
    try {
      const response = await fetchCategories(pagination);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const search_Categories = createAsyncThunk(
  "coupons/searchCategories",
  async ({ query, page, pageSize }) => {
    const response = await searchCategories({ query, page, pageSize });
    return response.data;
  }
);

export const getActiveServices = createAsyncThunk(
  "services/getActiveServices",
  async ({ query }) => {
    const response = await searchActiveServices({ query });
    return response?.data?.data;
  }
);

// /active-services
export const addCategory = createAsyncThunk(
  "users/addcategory",
  async (categoryData) => {
    try {
      const response = await createCategory(categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update_Category = createAsyncThunk(
  "users/updateCategory",
  async ({ id, ...categoryData }) => {
    const response = await updateCategory(id, categoryData);
    return response.data;
  }
);

export const delete_Category = createAsyncThunk(
  "users/deleteCategory",
  async (categoryId) => {
    await deleteCategory(categoryId);
    return categoryId;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search_Categories.fulfilled, (state, action) => {
        state.category = action.payload;
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.category = action.payload;
      })
      .addCase(getActiveServices.fulfilled, (state, action) => {
        state.activeService = action.payload;
      });

    // .addCase(addCategory.fulfilled, (state, action) => {
    //   state.category.push(action.payload);
    // })
    // .addCase(update_Category.fulfilled, (state, action) => {
    //   const updatedCategory = action.payload;
    //   const index = state.category.findIndex(
    //     (category) => category.id === updatedCategory.id
    //   );
    //   state.category[index] = updatedCategory;
    // });
    // .addCase(delete_Category.fulfilled, (state, action) => {
    //   const categoryId = action.payload;
    //   state.category = state.category.filter(
    //     (category) => category.id !== categoryId
    //   );
    // });
  },
});

export default categorySlice.reducer;
