import { useQuery } from "react-query";
import axios from "../config/Axios";

export const fetchClientRequests = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };

  const { data } = await axios.get("clientRequests/paged", {
    params: updatedParams,
  });
  return data;
};

export async function fetchServices() {
  try {
    const data = await axios.get("categories?activeOnly=true");
    return data?.data;
  } catch (error) {
    return null;
  }
}

export async function fetchPrices() {
  try {
    const data = await axios.get("prices");
    return data?.data;
  } catch (error) {
    return null;
  }
}

export const deleteClientRequest = async (id) => {
  const response = await axios.delete(`clientRequests/${id}`);
  return response.data;
};

export async function fetchClientRequestById(id) {
  const response = await axios.get(`clientRequests/${id}`);
  return response.data;
}

export async function fetchProposals({ queryKey }) {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };

  const response = await axios.get(`proposals`, { params: updatedParams });
  return response.data;
}

export async function fetchClientProposalsById(id) {
  const response = await axios.get(`proposals/${id}`);
  return response.data;
}

export const createProposal = async (createVendorProposalDto) => {
  const response = await axios.post("proposals", createVendorProposalDto);
  return response.data;
};

export const fetchAllCategories = async (activeOnly = false) => {
  const response = await axios.get(`/categories`, {
    params: { activeOnly },
  });
  return response.data;
};

export const fetchUserById = async (id) => {
  const response = await axios.get(`/users/${id}`);
  return response.data;
};

export const sendMessageRequest = async (notifications) => {
  const response = await axios.post("notifications", notifications);
  return response.data;
};

export const getMessages = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };

  const response = await axios.get(`notifications`, { params: updatedParams });
  return response.data;
};


const fetchServiceRequests = async ({ userId }) => {
  let url = "/clientRequests/request-stats-vendor";
  if (userId) {
    url = `/clientRequests/request-stats-vendor/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchServiceRequests = (params) => {
  return useQuery(
    ["fetchServiceRequests", params],
    () => fetchServiceRequests(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};
const fetchClientServiceRequests = async ({ userId }) => {
  let url = "/clientRequests/request-stats";
  if (userId) {
    url = `/clientRequests/request-stats/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchClientServiceRequests = (params) => {
  return useQuery(
    ["fetchServiceRequests", params],
    () => fetchClientServiceRequests(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};
