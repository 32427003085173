import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import _omit from "lodash.omit";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import PaymentEmpty from "../components/Payment/payment-empty";
import PaymentCategory from "../components/Payment/payment-category";
import { fetchFilteredTransactions } from "../service/transaction";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { fetchUsersByRole } from "../store/coupons/couponSlice";
import { VENDOR_ROLE_ID, getUserId, getUserRole } from "../constants";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function Payment() {
  const [pagination, setPagination] = useState({ page: 1, pageSize: 20 });
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);

  const vendors = useSelector((state) => state?.coupons?.vendors || []);
  const isAdmin = getUserRole(user, "admin");
  const params = {
    status: searchParams.get("status"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    amount: searchParams.get("amount"),
    userId: !isAdmin ? user?.id : null,
    query: searchParams.get("query"),
  };

  const filters = {
    ...pagination,
    ...params,
  };

  const { data, isFetching } = useQuery(
    ["filteredTransactions", filters],
    fetchFilteredTransactions
  );

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };

  useEffect(() => {
    dispatch(fetchUsersByRole(VENDOR_ROLE_ID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, VENDOR_ROLE_ID]);

  const transactions = data?.data || [];
  const isParamsCount = Object.values(_omit(params, "userId")).filter(
    Boolean
  ).length;
  const allVendors = vendors?.filter((item) => item.id !== getUserId());
  useSetTabDetails('Payments');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Spin className="w-full flex" spinning={isFetching}>
              {!isParamsCount && !transactions.length ? (
                <PaymentEmpty />
              ) : (
                <PaymentCategory
                  params={params}
                  isParamsCount={isParamsCount}
                  total={data?.total}
                  vendors={allVendors || []}
                  transactions={transactions}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleTableChange={handleTableChange}
                />
              )}
            </Spin>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
