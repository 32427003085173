import React from "react";
import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Select,
  Upload,
  Typography,
  Spin,
} from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import { getFormItemValidationProps } from "../../utils/form-util";
import { useMutation, useQuery } from "react-query";
import { fetchRoles } from "../../service/rollService";
import { ADMIN_TYPE, ADMIN_TYPE_VALUE } from "../../constants";
import { adminFormSchema } from "./validation";
import { createAdminUser, updateAdminUser } from "../../service/userservice";
import { toast } from "react-toastify";

const { Text } = Typography;

const AdminForm = (props) => {
  const { closePopup, refetch, user } = props;

  const { data, isLoading } = useQuery("roles", fetchRoles);
  const createAdmin = useMutation(createAdminUser);
  const updateAdmin = useMutation(updateAdminUser);

  const roles = data?.data;

  const handleSubmit = async (values) => {
    try {
      const mutation = user ? updateAdmin : createAdmin;
      const variables = {
        id: values.id,
        name: values.name,
        email: values.email,
        roleId: +values.roleId,
        phoneNumber: values.phoneNumber,
        profilePictureUrl: null,
      };

      await mutation.mutateAsync(variables);
      if (refetch) {
        await refetch();
      }
      if (!user) {
        toast.success("Admin Create Successfully!");
      } else {
        toast.success("Admin Updated Successfully!");
      }
      closePopup();
    } catch (error) {
      const response = error?.response?.data;
      const message = response?.message;
      toast.error(message || "Something went wrong");
    }
  };

  return (
    <Formik
      enableReinitialize={!!user}
      initialValues={{
        name: "",
        email: "",
        roleId: user?.role?.id || null,
        phoneNumber: "",
        ...user,
      }}
      validationSchema={adminFormSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <Spin spinning={createAdmin.isLoading}>
            <Upload className="mt-6">
              <div className="flex gap-2 items-center">
                <Avatar
                  className="w-16 h-16 rounded-xl"
                  src="/assets/images/profile-avatar.svg"
                />
                <div className="flex flex-col">
                  <Text className="tex-tbase font-normal text-primary underline mb-1">
                    Upload Profile Photo
                  </Text>
                  <Text className="text-xs text-grayLight font-normal">
                    File types supported: JPG, PNG Max size: 12 MB
                  </Text>
                </div>
              </div>
            </Upload>
            <Form
              name="login"
              size="large"
              layout="vertical"
              style={{
                width: "100%",
                maxWidth: "500px",
                margin: "15px auto 0 auto",
                fontWeight: "500",
                fontSize: "14px",
              }}
              className="flex flex-col gap-3"
            >
              <Field name="name">
                {({ field, form }) => (
                  <Form.Item
                    label="Name:"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      size="large"
                      placeholder="Enter your Full Name"
                      className="super__select text-sm font-normal text-dark"
                      prefix={<UserOutlined className="text-gray" />}
                    />
                  </Form.Item>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <Form.Item
                    label="Email:"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      size="large"
                      placeholder="Enter your Email Address"
                      className="super__select text-sm font-normal text-dark"
                      prefix={<MailOutlined className="text-gray" />}
                    />
                  </Form.Item>
                )}
              </Field>
              <Field name="roleId">
                {({ field, form }) => (
                  <Form.Item
                    className="super__select"
                    label="Admin Category"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Select
                      {...field}
                      loading={isLoading}
                      showSearch
                      size="large"
                      className="w-full super__select text-sm font-normal text-dark"
                      placeholder="Select Admin category:"
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      options={roles
                        ?.filter((role) => ADMIN_TYPE[role.name])
                        .map((role) => ({
                          value: role.id,
                          label: ADMIN_TYPE_VALUE[role.name],
                        }))}
                    />
                  </Form.Item>
                )}
              </Field>
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <Form.Item
                    label="Phone Number:"
                    required
                    {...getFormItemValidationProps(field, form)}
                  >
                    <Input
                      {...field}
                      size="large"
                      placeholder="Enter your Phone Number"
                      className="super__select text-sm font-normal text-dark"
                      prefix={<></>}
                    />
                  </Form.Item>
                )}
              </Field>

              <Form.Item>
                <Flex style={{ marginTop: 20 }}>
                  <Button
                    className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[320px] mx-auto"
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={!formikProps.dirty}
                    onClick={formikProps.handleSubmit}
                  >
                    {user ? "Update" : "Add"}
                  </Button>
                </Flex>
              </Form.Item>
            </Form>{" "}
          </Spin>
        );
      }}
    </Formik>
  );
};

export default AdminForm;
