import { useQuery } from "react-query";
import axios from "../config/Axios";

const fetchTotalCredits = async ({ startDate, endDate, userId }) => {
  let url = "/users/total-credits";
  if (userId) {
    url = `/users/total-user-credits/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchTotalCredits = (params) => {
  return useQuery(
    ["fetchTotalCredits", params],
    () => fetchTotalCredits(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};

const fetchTotalSubscriptions = async ({ userId }) => {
  let url = "/subscriptions/total-subscriptions";
  if (userId) {
    url = `/subscriptions/total-subscriptions/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchTotalSubscriptions = (params) => {
  return useQuery(
    ["fetchTotalSubscriptions", params],
    () => fetchTotalSubscriptions(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};

const fetchNewSubscriptions = async ({ userId }) => {
  let url = "/subscriptions/new-subscriptions";
  if (userId) {
    url = `/subscriptions/new-subscriptions/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchNewSubscriptions = (params) => {
  return useQuery(
    ["fetchNewSubscriptions", params],
    () => fetchNewSubscriptions(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};

const fetchDailyExpense = async ({ userId }) => {
  let url = "/subscriptions/total-subscribed-credits";
  if (userId) {
    url = `/subscriptions/total-subscribed-credits/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchDailyExpense = (params) => {
  return useQuery(
    ["fetchDailyExpense", params],
    () => fetchDailyExpense(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};

const fetchSubscribedCredits = async ({ userId }) => {
  let url = "/subscriptions/total-subscribed-credits";
  if (userId) {
    url = `/subscriptions/total-subscribed-credits/${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
};

export const useFetchTotalSubscribedCredits = (params) => {
  return useQuery(
    ["fetchSubscribedCredits", params],
    () => fetchSubscribedCredits(params),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};
