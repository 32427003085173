import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Spin,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  addCategory,
  getAllCategory,
} from "../../store/category/categorySlice";
import { Svgs } from "../Svgs/svg-icons";

const { Text } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

const AddService = ({ setPagination, pagination }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    iconURL: "",
    isPaused: false,
    createdBy: 1,
    updatedBy: 1,
  });
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!formValues.iconURL) {
        toast.error("Please upload an icon.");
        return;
      }

      await form.validateFields();
      const resultAction = await dispatch(addCategory(formValues));
      // console.log(resultAction);
      if (resultAction.payload) {
        await resultAction.meta.requestPayload;
        setPagination({ page: 1, pageSize: 10 });
        await dispatch(getAllCategory(pagination));
        setFormValues({
          name: "",
          description: "",
          iconURL: "",
          isPaused: false,
          createdBy: 1,
          updatedBy: 1,
        });
        form.resetFields();
        toast.success("Category Created Successfully!");
        setIsModalOpen(false);
      } else if (resultAction.error.message) {
        toast.error(resultAction.error.message || "something went wrong");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const isLt12MB = file.size / 1024 / 1024 < 12;

      if (!isLt12MB) {
        toast.error("File must be smaller than 12MB!");
        onError();
        return;
      }

      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "yasinCloud");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/hotelroombooking/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      setFormValues({
        ...formValues,
        iconURL: data.url,
      });
      onSuccess();
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        onClick={showModal}
        className="bg-primary flex justify-center items-center w-full max-w-[auto] py-5 text-base font-normal text-white rounded-lg"
        type=""
        icon={Svgs.addiconw}
      >
        Add New Category
      </Button>
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center">
            Add New Category
          </Text>
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-sm font-normal text-[#303030] text-center mb-2">
              Please add category icon along name and description.
            </Text>
          </div>
          <Upload
            className="mt-6"
            customRequest={customRequest}
            showUploadList={false}
            accept="image/*"
            beforeUpload={(file) => {
              const isLt12MB = file.size / 1024 / 1024 < 12;
              if (!isLt12MB) {
                toast.error("File must be smaller than 12MB!");
                return false;
              }

              const isImage = file.type.startsWith("image/");
              if (!isImage) {
                toast.error("You can only upload image files!");
                return false;
              }

              return true;
            }}
          >
            <div className="flex gap-3 items-center">
              <Spin spinning={loading}>
                <Avatar
                  required
                  className="w-16 h-16 rounded-md"
                  src={
                    formValues.iconURL || "/assets/images/profile-avatar.svg"
                  }
                />
              </Spin>
              <div className="flex flex-col gap-1">
                <Text className="text-base font-normal text-primary">
                  <span style={{ color: "red" }}>* </span>
                  Upload Icon
                </Text>
                <Text className="text-xs text-grayLight font-normal">
                  File types supported: JPG, PNG Max size: 12 MB, <br />
                  Recomended dimensions: 64x64
                </Text>
              </div>
            </div>
          </Upload>
          <Form
            name="login"
            size="large"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "15px auto 0 auto",
              fontWeight: "500",
              fontSize: "14px",
            }}
            className="flex flex-col gap-3"
          >
            <Form.Item
              label="Name:"
              name="categoryName"
              rules={[
                { required: true, message: '"Name" not allowed to be empty' },
              ]}
              hasFeedback
            >
              <Input
                autoComplete="on"
                size="large"
                disabled={loading}
                placeholder="Enter new category name"
                className="super__select text-sm font-normal text-dark h-14"
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
                label={'Service Value'}
                name="serviceValue"
                className="flex flex-col w-full"
                rules={[
                  {
                    required: true,
                    message: '"Service Value" not allowed to be empty',
                  },
                ]}
                hasFeedback
            >
              <InputNumber
                  size="large"
                  min={1}
                  defaultValue={1}
                  onChange={(value) =>
                      setFormValues({ ...formValues, serviceValue: value })
                  }
                  placeholder="Service Value"
                  className="super__select text-sm font-normal text-dark flex-1 w-full"
              />
            </Form.Item>
            <Form.Item
              label="Description:"
              name="categoryDescription"
              rules={[
                {
                  required: true,
                  message: '"Description" not allowed to be empty',
                },
              ]}
              hasFeedback
            >
              <TextArea
                className="font-normal text-sm"
                disabled={loading}
                autoComplete="on"
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="Write description here..."
                value={formValues.description}
                onChange={(e) =>
                  setFormValues({ ...formValues, description: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item>
              <Flex vertical style={{ marginTop: 20 }}>
                <Button
                  disabled={loading}
                  className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[280px] mx-auto"
                  type=""
                  htmlType="submit"
                >
                  Save
                </Button>
              </Flex>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </div>
  );
};

export default AddService;
