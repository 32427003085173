import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createClientRequest,
  deleteClientRequest,
  fetchClientRequest,
  updateClientRequest,
} from "../../service/clientRequestService";

const initialState = {
  clientRequest: [],
};

export const getAllClientRequest = createAsyncThunk(
  "clientRequest/getAllclientRequest",
  async () => {
    const response = await fetchClientRequest();
    return response.data;
  }
);

export const addClientRequest = createAsyncThunk(
  "clientRequest/addclientRequest",
  async (clientRequestData) => {
    const response = await createClientRequest(clientRequestData);
    return response.data;
  }
);

export const update_ClientRequest = createAsyncThunk(
  "clientRequest/updateClientRequest",
  async (clientRequestData) => {
    const response = await updateClientRequest(clientRequestData);
    return response.data;
  }
);

export const delete_ClientRequest = createAsyncThunk(
  "ClientRequest/deleteClientRequest",
  async (clientRequestId) => {
    await deleteClientRequest(clientRequestId);
    return clientRequestId;
  }
);

const clientRequestSlice = createSlice({
  name: "clientRequest",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllClientRequest.fulfilled, (state, action) => {
        state.clientRequest = action.payload;
      })
      .addCase(addClientRequest.fulfilled, (state, action) => {
        state.clientRequest.push(action.payload);
      })
      .addCase(update_ClientRequest.fulfilled, (state, action) => {
        const updatedclientRequest = action.payload;
        const index = state.clientRequest.findIndex(
          (clientRequest) => clientRequest.id === updatedclientRequest.id
        );
        state.clientRequest[index] = updatedclientRequest;
      })
      .addCase(delete_ClientRequest.fulfilled, (state, action) => {
        const clientRequestId = action.payload;
        state.clientRequest = state.clientRequest.filter(
          (clientRequest) => clientRequest.id !== clientRequestId
        );
      });
  },
});

export default clientRequestSlice.reducer;
