import React from "react";
import { Typography, Breadcrumb, Row, Col, Image } from "antd";
import { Content } from "antd/es/layout/layout";
import AddAdminProfile from "./create-admin";

const { Text } = Typography;

const AdminEmpty = ({ refetch }) => {
  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>User Management</span>,
          },
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            title: <a>Admins</a>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-8">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Admins
          </Text>
        </Col>
        <Col>
          <AddAdminProfile refetch={refetch} />
        </Col>
      </Row>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-admin-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mb-2">
          No Admin to display here
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-[440px]">
          You can add 2 admins here, let’s click on Add New Admin to add the
          admins
        </Text>
        <AddAdminProfile refetch={refetch} />
      </section>
    </Content>
  );
};

export default AdminEmpty;
