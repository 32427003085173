import React, { useState } from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProposalEmpty from "../components/Proposal/proposal-empty";
import ProposalCategory from "../components/Proposal/proposal-category";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { useQuery } from "react-query";
import { fetchProposals } from "../service/request";
import { getUserId } from "../constants";
import { useSearchParams } from "react-router-dom";
import Loader from "../components/loader/loader";
import { useSelector } from "react-redux";

export default function Proposal() {
  useSetTabDetails("Proposals");
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state?.user?.user);

  const paramsCount = Array.from(searchParams.entries()).length;
  const isAdmin = user?.role?.name === "ADMIN";
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const filters = {
    serviceId: searchParams.get("category"),
    search: searchParams.get("search"),
    userId: isAdmin ? null : getUserId(),
  };

  const { data, isLoading, isError, refetch } = useQuery(
    [
      "PROPOSALS_REQUEST_FETCH",
      { ...filters, page: pagination.page, pageSize: pagination.pageSize },
    ],
    fetchProposals,
    {
      keepPreviousData: true,
    }
  );

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            {(data && data?.length > 0) || paramsCount ? (
              <ProposalCategory
                refetch={refetch}
                total={data?.total}
                proposals={data || []}
                pagination={pagination}
                paramsCount={paramsCount}
                setPagination={setPagination}
                handleTableChange={handleTableChange}
              />
            ) : (
              <ProposalEmpty refetch={refetch} />
            )}
            {/* <RespondedVendor /> */}
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
