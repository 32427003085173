import { Avatar, Col, Dropdown, Menu, Space, Table, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import DeleteProposal from "../Proposal/delete-proposal";
import { Svgs } from "../Svgs/svg-icons";

const { Text } = Typography;

const userDropdown = (proposal, refetch) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item className="dropdown__item__header" key="0" icon={Svgs.view}>
      <Link to={`/proposal/${proposal.id}`}>View Details</Link>
    </Menu.Item>
    <Menu.Item key="1">
      {" "}
      <DeleteProposal
        refetch={refetch}
        proposalId={proposal?.id}
        proposal={proposal}
        isReload={false}
      />
    </Menu.Item>
  </Menu>
);

function VendorProposal({ proposals, refetch }) {
  const getColumns = () => {
    return [
      {
        title: "Client Name",
        dataIndex: "userName",
        key: "0",
        render: (_, proposal) => (
          <Space>
            <span className="text-primary underline">
              {proposal?.user?.name}
            </span>{" "}
          </Space>
        ),
      },
      {
        title: "Service Category",
        dataIndex: "categoryName",
        key: "0",
        render: (_, proposal) => (
          <Space className="flex gap-1 items-center">
            <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src={
                proposal?.request?.category?.iconURL ||
                "/assets/images/avatar.svg"
              }
            />
            <span>{proposal?.request?.category?.name}</span>{" "}
          </Space>
        ),
      },
      {
        title: "Area Name",
        dataIndex: "areaName",
        key: "0",
        render(_, proposal) {
          return (
            <span>
              {proposal?.serviceSubscription?.areas?.[0]?.name || "N/A"}
            </span>
          );
        },
      },
      {
        title: "Area Category",
        dataIndex: "categoryName",
        key: "0",
        render(_, proposal) {
          return (
            <span>
              {proposal?.serviceSubscription?.areas?.[0]?.areaCategory
                ?.areaCategoryName || "N/A"}
            </span>
          );
        },
      },
      {
        title: "Credit Value",
        dataIndex: "budget",
        key: "0",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (_, proposal) =>
          proposal?.status === "accepted" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                Accept
              </div>
            </Space>
          ) : proposal?.status === "pending" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#FFC107] py-[2px] px-2 rounded-2xl">
                Pending
              </div>
            </Space>
          ) : (
            proposal?.status ===
            "rejected"(
              <Space className="flex items-center gap-3">
                <div className="text-xs font-normal text-white bg-[#F00F00] py-[2px] px-2 rounded-2xl">
                  Rejected
                </div>
              </Space>
            )
          ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (_, proposal) => (
          <Space size="middle">
            <Dropdown
              overlay={userDropdown(proposal, refetch)}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Col span={24} className="bg-white box__shadow lg:p-5 p-3 rounded-xl">
      <Text className="md:text-xl text-lg font-medium text-gray">
        Proposals:
      </Text>
      <div className="mt-3">
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={proposals}
          pagination={false}
        />
      </div>
    </Col>
  );
}

export default VendorProposal;
