import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import CategoryAreaList from "../components/Area/area-category";
import AreaEmpty from "../components/Area/area-empty";
import { useDispatch, useSelector } from "react-redux";
import {
  filter_Areas,
  getAllArea,
  getAreaZipCodes,
} from "../store/area/areaSlice";
import { getAllAreaCategory } from "../store/areaCategory/areaCategorySlice";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import useSetTabDetails from "../hooks/use-set-tab-name";

export const formatAreas = (areas) => {
  return areas.map((area) => ({
    ...area,
    zipCodes: area.zipCodes.map((code) => code.zipCode),
  }));
};

export default function Area() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramsCount = Array.from(searchParams.entries()).length;
  const areaZipCodes = searchParams.get("areaZipCodeSearch");
  const areaZipCodeSearchArray = areaZipCodes ? areaZipCodes.split("+") : [];

  const areas = useSelector((state) => state?.area?.area?.data);
  const total = useSelector((state) => state?.area?.area?.total);
  const areaCategories = useSelector(
    (state) => state.areaCategory.areaCategory
  );
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  useEffect(() => {
    dispatch(getAllAreaCategory());
    if (paramsCount) {
      dispatch(
        filter_Areas({
          ...pagination,
          areaName: searchParams.get("search"),
          zipCodes: areaZipCodeSearchArray,
          areaCategoryId: searchParams.get("areaCatId"),
        })
      );
    } else {
      dispatch(getAllArea(pagination));
    }
    dispatch(getAreaZipCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsCount, dispatch, pagination]);

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };
  useSetTabDetails('Area');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Spin spinning={typeof areas === "undefined"}>
              {!areas?.length &&
              !paramsCount &&
              typeof areas !== "undefined" ? (
                <AreaEmpty />
              ) : (
                <CategoryAreaList
                  total={total}
                  areas={formatAreas(areas || [])}
                  areaCategories={areaCategories}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleTableChange={handleTableChange}
                />
              )}
            </Spin>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
