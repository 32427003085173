import React from "react";
import { Typography, Breadcrumb, Row, Col, Image, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import { Svgs } from "../Svgs/svg-icons";
import { Link, useNavigate } from "react-router-dom";

const { Text } = Typography;

const AreaEmpty = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/area">Area Management</Link>,
          },
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium">
            Area
          </Text>
        </Col>
        <Button
          className="bg-primary flex justify-center items-center w-full max-w-[160px] py-5 text-base font-normal text-white rounded-lg"
          type=""
          icon={Svgs.addiconw}
          onClick={() => navigate("/area/create")}
        >
          Add New Area
        </Button>
      </Row>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-area-img-new.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2">
          You don’t have any Data yet!
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7">
          Please click the button to add new data.
        </Text>
        <Button
          className="bg-primary flex justify-center items-center w-full max-w-[160px] py-5 text-base font-normal text-white rounded-lg"
          type=""
          icon={Svgs.addiconw}
          onClick={() => navigate("/area/create")}
        >
          Add New Area
        </Button>
      </section>
    </Content>
  );
};

export default AreaEmpty;
