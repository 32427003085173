import axios from "../config/Axios";

export const fetchClientRequest = () => {
  return axios.get("/client-requests");
};

export const createClientRequest = (clientRequestData) => {
  return axios.post("/client-requests", clientRequestData);
};

export const updateClientRequest = (id, clientRequestData) => {
  return axios.put(`/client-requests/${id}`, clientRequestData);
};

export const deleteClientRequest = (id) => {
  return axios.delete(`/client-requests/${id}`);
};
