import React, { useMemo, useState } from "react";
import {
  Typography,
  Form,
  Input,
  Flex,
  Button,
  Select,
  InputNumber,
  message,
} from "antd";
import countryList from "react-select-country-list";
import { Content } from "antd/es/layout/layout";
import { Svgs } from "../Svgs/svg-icons";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Field, Formik } from "formik";
import { getFormItemValidationProps } from "../../utils/form-util";
import { useNavigate } from "react-router-dom";
import { validationSchema } from "./validations";
import { useMutation } from "react-query";
import { applyCoupon } from "../../service/transaction";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const { Text } = Typography;

const PurchaseDetail = () => {
  const navigate = useNavigate();
  const applyDiscount = useMutation(applyCoupon);
  const paymentInfo = localStorage.getItem("paymentInfo");
  const user = useSelector((state) => state?.user?.user);
  const options = useMemo(() => countryList().getData(), []);
  const formInfo = JSON.parse(paymentInfo) || {};
  const [coupon, setCoupon] = useState(null);
  const [offPerItem, setOffPerItem] = useState(0);
  const [isCouponApplied, setCouponApplied] = useState(formInfo?.couponCode);

  const handleApplyCoupon = async (values, setFieldValue) => {
    try {
      const variables = {
        amount: values.originalAmount || values.amount,
        couponCode: values.couponCode,
      };
      const coupon = await applyDiscount.mutateAsync(variables);
      setCoupon(coupon);
      setOffPerItem(coupon?.data?.amountOff);
      setFieldValue("amount", coupon?.data?.discountedAmount);
      setFieldValue("originalAmount", coupon?.data?.amount);
      toast.success("Coupon Applied Successfully!");
      setCouponApplied(true);
    } catch (error) {
      const response = error?.response?.data;
      const message = response?.message;
      toast.error(message || "Something went wrong");
    }
  };

  const getDefaultFormValue = () => {
    const values = {};
    const billingInfo = formInfo?.billingInfo;
    if (billingInfo?.name) {
      const [fname, lname] = formInfo?.billingInfo?.name?.split(" ");
      values.fname = fname;
      values.lname = lname;
    }

    values.email = billingInfo?.email || user?.email;
    values.amount = formInfo?.amount;
    values.phone = billingInfo?.phone || user?.phoneNumber;
    values.couponCode = formInfo?.couponCode;
    values.country = billingInfo?.address?.country;
    values.postalCode = billingInfo?.address?.postalCode;
    return values;
  };

  return (
    <Content>
      <div className="flex gap-16 justify-center items-center mb-10">
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.details}
          </div>
          <Text className="text-sm text-primary font-semibold">Details</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.payment}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Payment</Text>
          <div className="flex gap-1 items-center absolute top-4 left-[46px]">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.review}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Review</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.thankyou}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Thank You</Text>
        </div>
      </div>
      <div className="bg-[#f5f5f5] rounded-lg lg:p-5 p-3">
        <div className="flex justify-between items-center">
          <Text className="md:text-2xl text-xl font-medium text-gray">
            Billing Details:
          </Text>
          <div className="py-2 px-4 w-full max-w-[190px] bg-[#9a9ea5] rounded-xl flex items-center justify-center text-white md:text-lg text-base font-medium whitespace-nowrap">
            1 USD = 100 Credits
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            fname: user?.name?.split(" ")?.[0] || "",
            lname: user?.name?.split(" ")?.[1] || "",
            email: user?.email || '',
            phone: user?.phoneNumber || '',
            country: null,
            postalCode: null,
            amount: null,
            couponCode: null,
            originalAmount: null,
            ...getDefaultFormValue(),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const paymentInfo = JSON.parse(localStorage.getItem("paymentInfo"));
            const newPaymentInfo = {
              ...paymentInfo,
              amount: values.amount,
              couponCode: coupon?.data?.couponCode,
              amountOff: coupon?.data?.amountOff,
              discountedAmount: coupon?.data?.discountedAmount,
              originalAmount: coupon?.data?.amount,
              billingInfo: {
                email: values.email,
                name: values.fname + " " + values.lname,
                phone: values.phone,
                address: {
                  postalCode: values.postalCode,
                  country: values.country,
                },
              },
            };

            localStorage.setItem("paymentInfo", JSON.stringify(newPaymentInfo));
            navigate("/purchase-credit?payment-details=1");
          }}
        >
          {(prop) => {
            return (
              <div>
                <div className="grid lg:grid-cols-2 gap-5 my-4">
                  <Form name="" size="large" layout="vertical">
                    <Field name="fname">
                      {({ field, form }) => (
                        <Form.Item
                          label="First Name:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Input
                            {...field}
                            size="large"
                            required
                            placeholder="Adam"
                            className="super__select text-sm font-normal text-dark"
                            prefix={<UserOutlined className="text-gray" />}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>

                  <Form name="" size="large" layout="vertical">
                    <Field name="lname">
                      {({ field, form }) => (
                        <Form.Item
                          label="Last Name:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Input
                            {...field}
                            size="large"
                            required
                            placeholder="Synder"
                            className="super__select text-sm font-normal text-dark"
                            prefix={<UserOutlined className="text-gray" />}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                  <Form size="large" layout="vertical">
                    <Field name="email">
                      {({ field, form }) => (
                        <Form.Item
                          label="Email:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Input
                            {...field}
                            size="large"
                            required
                            placeholder="JhonHenry123@gmail.com"
                            className="super__select text-sm font-normal text-dark"
                            prefix={<MailOutlined className="text-gray" />}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                  <Form name="" size="large" layout="vertical">
                    <Field name="phone">
                      {({ field, form }) => (
                        <Form.Item
                          label="Phone Number:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Input
                            {...field}
                            size="large"
                            required
                            placeholder="+21 90845672"
                            className="super__select text-sm font-normal text-dark"
                            prefix={
                              <PhoneOutlined className="text-gray rotate-90" />
                            }
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                  <Form name="" size="large" layout="vertical">
                    <Field name="country">
                      {({ field, form }) => (
                        <Form.Item
                          label="Country:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Select
                            {...field}
                            showSearch
                            placeholder="Country"
                            options={options}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.label
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onChange={(value) =>
                              form.setFieldValue(field.name, value)
                            }
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                  <Form name="" size="large" layout="vertical">
                    <Field name="postalCode">
                      {({ field, form }) => (
                        <Form.Item
                          label="Zip Code:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <Input
                            {...field}
                            size="large"
                            required
                            placeholder="Zip Code"
                            className="super__select text-sm font-normal text-dark"
                            prefix={<></>}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Form>
                </div>
                <div>
                  <Text className="md:text-2xl text-xl font-medium text-gray">
                    Credits
                  </Text>
                  <div className="grid lg:grid-cols-2 gap-5 mt-4">
                    <div className="relative" size="large" layout="vertical">
                      <Form size="large" layout="vertical">
                        <Field name="amount">
                          {({ field, form }) => (
                            <React.Fragment>
                              <Form.Item
                                label="Select Amount:"
                                required
                                {...getFormItemValidationProps(field, form)}
                              >
                                <InputNumber
                                  {...field}
                                  size="large"
                                  min={0}
                                  onClick={() => {
                                    if (isCouponApplied) {
                                      message.error({
                                        content:
                                          "Discount amount unchangeable. Please remove coupon and retry",
                                      });
                                    }
                                  }}
                                  value={
                                    prop?.values?.originalAmount ||
                                    formInfo?.originalAmount
                                  }
                                  readOnly={isCouponApplied}
                                  required
                                  onChange={(value) => {
                                    form.setFieldValue(
                                      field.name,
                                      value - offPerItem
                                    );
                                    form.setFieldValue("originalAmount", value);
                                  }}
                                  placeholder="$1.00"
                                  className="super__select text-sm font-normal text-dark w-full"
                                  prefix={<></>}
                                />
                              </Form.Item>
                              <div className="absolute top-[2px] left-[110px]">
                                {Svgs.exclamatory}
                              </div>
                            </React.Fragment>
                          )}
                        </Field>
                      </Form>
                    </div>
                    <div className="flex gap-2 items-end">
                      <Form
                        className="w-full relative"
                        onSubmit={(e) => e.preventDefault()}
                        size="large"
                        layout="vertical"
                      >
                        <Field name="couponCode">
                          {({ field }) => (
                            <React.Fragment>
                              <Form.Item label="Discount Code:">
                                <Input
                                  {...field}
                                  value={field.value}
                                  size="large"
                                  required
                                  disabled={!prop?.values?.amount}
                                  readOnly={isCouponApplied}
                                  placeholder="Promo Code"
                                  className="super__select text-sm font-normal text-dark w-full"
                                  prefix={<></>}
                                />
                              </Form.Item>
                              <div className="absolute top-[2px] left-[100px]">
                                {Svgs.exclamatory}
                              </div>
                            </React.Fragment>
                          )}
                        </Field>
                      </Form>
                      <Button
                        className="bg-primary flex justify-center items-center w-full max-w-[180px] py-6 text-lg font-normal text-white rounded-lg !h-14"
                        type="primary"
                        disabled={
                          !prop.values.couponCode || applyDiscount.isLoading
                        }
                        onClick={() => {
                          if (isCouponApplied) {
                            if (Object.entries(formInfo).length) {
                              formInfo.amount = formInfo?.originalAmount;
                              formInfo.discountedAmount = 0;
                              formInfo.couponCode = null;

                              localStorage.setItem(
                                "paymentInfo",
                                JSON.stringify(formInfo)
                              );
                            }
                            setOffPerItem(0);
                            prop.setFieldValue("couponCode", null);
                            prop.setFieldValue(
                              "amount",
                              prop.values.originalAmount ||
                                formInfo?.originalAmount
                            );
                            setCouponApplied(false);
                            return;
                          }
                          handleApplyCoupon(prop.values, prop.setFieldValue);
                        }}
                      >
                        {isCouponApplied ? "Remove" : "Apply"}
                      </Button>
                    </div>
                  </div>
                  {!!isCouponApplied && (
                    <Text className="text-sm font-medium text-gray">
                      You are going to pay $
                      {prop?.values?.amount || formInfo?.amount}, and you'll
                      receive a discount of $
                      {(
                        (prop?.values?.originalAmount ||
                          formInfo?.originalAmount) -
                        (prop?.values?.amount || formInfo?.amount)
                      )?.toFixed(2)}
                    </Text>
                  )}
                </div>
                <Flex className="justify-center my-5">
                  <Button
                    className="bg-primary flex justify-center items-center w-full max-w-[300px] py-6 text-base font-normal text-white rounded-lg"
                    type=""
                    onClick={prop.handleSubmit}
                  >
                    Continue
                  </Button>
                </Flex>
              </div>
            );
          }}
        </Formik>
      </div>
    </Content>
  );
};

export default PurchaseDetail;
