import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchAllVendors, fetchTopSerives, fetchTopSubscriptions, fetchTopSubscriptionsAdmin } from "../../service/vendorService";

const initialState = {
  vendors: [],
  topServices: [],
  topSubscriptions: [],
  topSubscriptionsAdmin: [],
};

export const getAllVendors = createAsyncThunk(
  "users/getAllVendors",
  async () => {
    const response = await fetchAllVendors();
    return response.data;
  }
);
export const getAllTopServices = createAsyncThunk(
  "clientRequests/top",
  async (params) => {
    const response = await fetchTopSerives(params);
    return response.data;
  }
);
export const getAllTopSubscriptions = createAsyncThunk(
  "clientRequests/top/subscriptions",
  async (params) => {
    const response = await fetchTopSubscriptions(params);
    return response.data;
  }
);
export const getAllTopSubscriptionsAdmin = createAsyncThunk(
  "clientRequests/top/subscriptions-admin",
  async (params) => {
    const response = await fetchTopSubscriptionsAdmin(params);
    return response.data;
  }
);


const vendorSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllVendors.fulfilled, (state, action) => {
      state.vendors = action.payload;
    });
    builder.addCase(getAllTopServices.fulfilled, (state, action) => {
      state.topServices = action.payload;
    });
    builder.addCase(getAllTopSubscriptions.fulfilled, (state, action) => {
      state.topSubscriptions = action.payload;
    });
    builder.addCase(getAllTopSubscriptionsAdmin.fulfilled, (state, action) => {
      state.topSubscriptionsAdmin = action.payload;
    });
  },
});

export default vendorSlice.reducer;
