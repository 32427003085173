import React, { useState } from "react";
import { Modal, Typography, Flex, Button, Image, Spin } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import axiosInstance from "../../config/Axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const { Text } = Typography;

const WithDraw = ({ proposal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mutation = useMutation(() => {
    return axiosInstance.patch(`/proposals/${proposal.id}/withdraw`);
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleWithdraw = async () => {
    try {
      await mutation.mutateAsync();
      handleCancel();
      toast.success("Proposal Withdrawn Successfully");
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <div onClick={showModal} className="flex gap-2">
        {Svgs.withdraw}
        Withdraw
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Spin spinning={mutation.isLoading}>
          <div className="flex flex-col">
            <Image
              preview={false}
              className="max-w-[140px] mx-auto"
              src="/assets/images/withdraw.svg"
            />
            <div className="flex flex-col justify-center items-center mt-5">
              <Text className="text-base font-normal text-[#2c2c2e] text-center max-w-[320px] !leading-tight">
                Are you sure you want to withdraw from this{" "}
                <span className="font-bold">
                  {proposal?.request?.category?.name}
                </span>{" "}
                Request.
              </Text>
            </div>
            <Flex gap={16} className="mt-6 justify-center">
              <Button
                className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
                type=""
                onClick={handleWithdraw}
              >
                Yes
              </Button>

              <Button
                className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
                type=""
              >
                No
              </Button>
            </Flex>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default WithDraw;
