export const validateCouponForm = (values) => {
  const errors = {};
  const isPercentage = values.couponType === "Percentage";
  if (!values.couponCode) {
    errors.couponCode = '"Coupon Id" is not allowed to be empty';
  } else if (!/^[A-Za-z0-9]+$/.test(values.couponCode)) {
    errors.couponCode = '"Coupon Id" must be alphanumeric';
  }
  if (values.isCouponAlreadyExist) {
    errors.couponCode = values.isCouponAlreadyExist;
  }

  if (!values.couponName) {
    errors.couponName = '"Coupon Name" is not allowed to be empty';
  }

  if (!values.couponType) {
    errors.couponType = '"Coupon Type" is not allowed to be empty';
  }

  if (isPercentage && !values.discountPercentage) {
    errors.discountPercentage = '"Percentage" is not allowed to be empty';
  }

  if (!isPercentage && !values.discountValue) {
    errors.discountValue = '"Amount" is not allowed to be empty';
  }

  if (values.isCouponHasDateLimit && (!values.startDate || !values.expDate)) {
    errors.couponUsageDate = '"Date" is not allowed to be empty';
  }

  if (values.isCouponHasLimit && !values.redemptionLimit) {
    errors.redemptionLimit = '"Redemption Limit" is not allowed to be empty';
  }

  return errors;
};

export const validationForShareCoupon = (values) => {
  const errors = {};
  if (!values?.emails?.length) {
    errors.users = '"Vendor" not allowed to be empty';
  }
  return errors;
};
