import axios from "../config/Axios";

export const fetchCategories = (pagination) => {
  return axios.get("/categories/getAllCategories", { params: pagination });
};

export const createCategory = (categoryData) => {
  return axios.post("/categories", categoryData);
};

export const updateCategory = (id, categoryData) => {
  return axios.put(`/categories/${id}`, categoryData);
};

export const deleteCategory = (id) => {
  return axios.delete(`/categories/${id}`);
};

export const searchCategories = (params) => {
  return axios.get(`/categories/search`, { params });
};


export const searchActiveServices = (params) => {
  return axios.get(`/categories/active-services`, { params });
};