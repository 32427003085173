import axios from "../config/Axios";

export const fetchUsers = () => {
  return axios.get("/users");
};

export const fetchUser = (id) => {
  return axios.get(`/users/${id}`);
};

export const createUser = (userData) => {
  return axios.post("/users", userData);
};

export const loginUser = ({ username, email, password }) => {
  return axios.post("/users/login", { username, email, password });
};

export const updateUser = ({id, userData}) => {
  return axios.put(`/users/${id}`, userData);
};

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`);
};

export const forgetPassword = (email) => {
  return axios.post("/users/forgot-password", { email });
};

export const resetPassword = (userData) => {
  return axios.post("/users/reset-password", userData);
};

export const createAdminUser = async (adminData) => {
  const { data } = await axios.post('/users/admin/create', adminData);
  return data?.data;
};

export const deleteAdminUser = async (userId) => {
  const { data } = await axios.delete(`users/admin/${userId}`);
  return data?.data;
};

export const getAdminUsers = async () => {
  const { data } = await axios.get('/users/admin/all');
  return data;
};

export const updateAdminUser = async ({ id, ...updateData }) => {
  const { data } = await axios.put(`/users/admin/${id}`, updateData);
  return data;
};

export const updatePermission = async ({ userId, permissions }) => {
  const { data } = await axios.patch(`/users/update-permission/${userId}`, permissions);
  return data;
};