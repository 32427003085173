import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createUserCredits,
  deleteUserCredits,
  fetchUserCredits,
  updateUserCredits,
} from "../../service/userCreditsService";

const initialState = {
  userCredits: [],
};

export const getAllUserCredits = createAsyncThunk(
  "userCredits/getAlluserCredits",
  async () => {
    const response = await fetchUserCredits();
    return response.data;
  }
);

export const addUserCredits = createAsyncThunk(
  "services/addservices",
  async (userCreditsData) => {
    const response = await createUserCredits(userCreditsData);
    return response.data;
  }
);

export const update_UserCredits = createAsyncThunk(
  "service/updateServices",
  async (userCreditsData) => {
    const response = await updateUserCredits(userCreditsData);
    return response.data;
  }
);

export const delete_UserCredits = createAsyncThunk(
  "ClientRequest/deleteClientRequest",
  async (userCreditId) => {
    await deleteUserCredits(userCreditId);
    return userCreditId;
  }
);

const userCreditsSlice = createSlice({
  name: "userCredits",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllUserCredits.fulfilled, (state, action) => {
        state.userCredits = action.payload;
      })
      .addCase(addUserCredits.fulfilled, (state, action) => {
        state.userCredits.push(action.payload);
      })
      .addCase(update_UserCredits.fulfilled, (state, action) => {
        const updatedUserCredits = action.payload;
        const index = state.userCredits.findIndex(
          (userCredits) => userCredits.id === updatedUserCredits.id
        );
        state.userCredits[index] = updatedUserCredits;
      })
      .addCase(delete_UserCredits.fulfilled, (state, action) => {
        const userCreditsId = action.payload;
        state.userCredits = state.userCredits.filter(
          (userCredits) => userCredits.id !== userCreditsId
        );
      });
  },
});

export default userCreditsSlice.reducer;
