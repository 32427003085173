import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  InputNumber,
  Flex,
  Typography,
  Layout,
} from "antd";

const { Text } = Typography;
const { Content } = Layout;

const ManualCredit = ({ setManualCredit, manualCredit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manualCreditInput, setManualCreditInput] = useState();
  const [message, setMessage] = useState();

  const showModal = () => {
    if (manualCredit) {
      setManualCredit(null);
      setManualCreditInput(null);
    } else if (manualCredit == null) {
      setIsModalOpen(true);
      setManualCreditInput(null);
    }
  };

  const handleCancel = () => {
    setMessage("");

    setIsModalOpen(false);
  };

  const handleManualCreditChange = () => {
    if (manualCreditInput) {
      setManualCredit(manualCreditInput);
      setMessage("");
      setIsModalOpen(false);
    } else {
      setMessage("please Enter Credit Value");
    }
  };
  return (
    <div className="lg:w-[20%]">
      <Button
        onClick={showModal}
        className="bg-[#50586C] lg:text-xl text-base font-normal text-white rounded-xl !h-14 w-full max-w-[240px]"
        type=""
      >
        {manualCredit ? " Revert to Auto Credits" : "Manual Credit"}
      </Button>
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center">
            Set Manual Credits
          </Text>
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-sm font-normal text-[#303030] text-center mb-2">
              You can set the auto generated Credit value according to your
              choice, you can revert the amount to auto generated value any
              time.
            </Text>
          </div>
          <Form
            name="login"
            size="large"
            layout="vertical"
            onFinish={handleManualCreditChange}
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "15px auto 0 auto",
              fontWeight: "500",
              fontSize: "14px",
            }}
            className="flex flex-col gap-3"
          >
            <Form.Item label="Set Manual Credits:">
              <InputNumber
                size="large"
                min={0}
                max={1000}
                placeholder="Manual Credit"
                className="super__select text-sm font-normal text-dark flex-1 w-full"
                value={manualCreditInput}
                onChange={(value) => setManualCreditInput(value)}
              />
              <span style={{ color: "red" }}>{message}</span>
            </Form.Item>
            <Form.Item>
              <Flex vertical style={{ marginTop: 20 }}>
                <Button
                  className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[320px] mx-auto"
                  type=""
                  htmlType="submit"
                  block
                  onClick={handleManualCreditChange}
                >
                  Save
                </Button>
              </Flex>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </div>
  );
};

export default ManualCredit;
