import axios from "../config/Axios";

export const fetchAllVendors = () => {
  return axios.get("/users/vendors");
};

export const fetchTopSerives = (params) => {
  return axios.get(`/proposals/top-categories/${params?.userId}`);
};
export const fetchTopSubscriptions = (params) => {
  let url = `/subscription/top-categories`;
  if (params?.userId) {
    url = `/subscriptions/top-categories/${params.userId}`;
  }
  return axios.get(url);
};
export const fetchTopSubscriptionsAdmin = () => {
  return axios.get(`/subscriptions/top-categories-admin`);
};