import { Image, Typography, } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import AddVendor from "./add-vendor";

const { Text } = Typography;

const VendorEmpty = () => {
  return (
    <Content>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-vendor-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2 mb-2">
          No vendor list to display
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-[360px]">
          Let's start adding/inviting New vendors by simply by clicking{" "}
          <span className="font-bold">Add New Vendor</span> button.
        </Text>
        <AddVendor />
      </section>
    </Content>
  );
};

export default VendorEmpty;
