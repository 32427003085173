import React, { useState } from "react";
import {
  Button,
  Modal,
  Typography,
  Layout,
  Flex,
  Switch,
  Avatar,
  Spin,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { updatePermission } from "../../service/userservice";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const { Text } = Typography;
const { Content } = Layout;

const LogisticRole = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const permission = useMutation(updatePermission);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdatePermission = async (variables) => {
    try {
      await permission.mutateAsync(variables);
      toast.success("Permissions Updated Successfully!");
    } catch (error) {
      const response = error?.response?.data;
      const message = response?.message;
      toast.error(error?.message || message || "Something went wrong");
    }
  };

  return (
    <React.Fragment>
      <div onClick={showModal} className="flex gap-2">
        {Svgs.role}
        Assign Role
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        loading={permission.isLoading}
      >
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center">
            {user?.role?.name === "SUB_ADMIN"
              ? "Sub Admin Role"
              : "Logistic Sub Admin Role"}
          </Text>
          <div className="flex flex-col justify-center items-center mt-4">
            <Text className="text-sm font-normal text-[#888888] text-center mb-2 w-full max-w-[340px]">
              You can give permissions accordingly to the created admin to get
              access.
            </Text>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <Avatar
              src="/assets/images/avatar-vendor.svg"
              className="w-16 h-16 rounded-full"
            />
            <div className="flex flex-col">
              <span className="md:text-2xl text-xl font-medium text-primary">
                {user?.name}
              </span>
              <span className="text-base text-[#303030] font-normal">
                {user?.email}
              </span>
            </div>
          </div>
          <Spin spinning={permission.isLoading}>
            <div className="flex flex-col gap-4 mt-10">
              <h3 className="md:text-2xl text-xl font-medium text-gray">
                Set Access Permissions:
              </h3>
              {user?.role?.permissions
                ?.filter((p) => p.name !== "Dashboard")
                .map((permission) => {
                  return (
                    <div
                      key={permission.id}
                      className="flex justify-between items-center bg-[#f6f6f6] rounded-4 py-2 pl-3 pr-8"
                    >
                      <label className="text-xl text-black font-normal">
                        {permission?.name}
                      </label>
                      <Switch
                        defaultChecked={user?.permissions?.some(
                          (p) => p.permissionKey === permission.permissionKey
                        )}
                        onChange={async (checked) => {
                          const data = {
                            userId: user?.id,
                            permissions: {
                              active: checked,
                              id: permission?.id,
                            },
                          };
                          await handleUpdatePermission(data);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </Spin>
          <Flex gap={16} className="mt-8 justify-center">
            <Button
              className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out w-full max-w-[320px] mx-auto"
              type=""
              htmlType="submit"
              block
              disabled={permission.isLoading}
              onClick={() => setIsModalOpen(false)}
            >
              Done
            </Button>
          </Flex>
        </Content>
      </Modal>
    </React.Fragment>
  );
};

export default LogisticRole;
