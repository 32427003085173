import axios from "../config/Axios";

// Create a new coupon
export const createCoupon = (couponData) => {
  return axios.post("/coupons", couponData);
};

// Update a coupon
export const updateCoupon = (couponCode, updateCouponData) => {
  return axios.put(`/coupons/${couponCode}`, updateCouponData);
};

// Activate/Deactivate a coupon
export const updateCouponIsActive = (couponCode, isActive, userId) => {
  return axios.patch(`/coupons/${couponCode}/activate`, { isActive, userId });
};

// Validate a coupon ID
export const validateCouponId = (couponCode) => {
  return axios.get("/coupons/validate-id", { params: { couponCode } });
};

// Search coupons
export const searchCoupons = (query, page, pageSize) => {
  return axios.get("/coupons/search", {
    params: {
      query,
      page,
      pageSize,
    },
  });
};

// Fetch a specific coupon
export const fetchCoupon = (couponCode, userId) => {
  return axios.get(`/coupons/${couponCode}`, { params: { userId } });
};

// Fetch all coupons
export const fetchCoupons = (userId, page, pageSize) => {
  return axios.get("/coupons", { params: { userId, page, pageSize } });
};

// Delete a coupon
export const deleteCoupon = (userId, couponCode) => {
  return axios.delete(`/coupons/${couponCode}`, { params: { userId } });
};
