import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRole } from "../../constants";
import { constructQueryString, exportToCSV } from "../../utils/common-util";
import { Svgs } from "../Svgs/svg-icons";
import PaymentFilterDropdown from "./payment-filter";

const { Search } = Input;
const { Text } = Typography;

const UserDropdown = ({ csvdata, headers, invoiceUrl }) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item key="0">
      <div
        onClick={() => {
          const data = [headers, ...csvdata];
          exportToCSV(data);
        }}
        className="flex gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={17}
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M14 12.5V10.5C14 10.3674 13.9473 10.2402 13.8536 10.1464C13.7598 10.0527 13.6326 10 13.5 10C13.3674 10 13.2402 10.0527 13.1464 10.1464C13.0527 10.2402 13 10.3674 13 10.5V12.5C13 12.6326 12.9473 12.7598 12.8536 12.8536C12.7598 12.9473 12.6326 13 12.5 13H3.5C3.36739 13 3.24021 12.9473 3.14645 12.8536C3.05268 12.7598 3 12.6326 3 12.5V10.5C3 10.3674 2.94732 10.2402 2.85355 10.1464C2.75979 10.0527 2.63261 10 2.5 10C2.36739 10 2.24021 10.0527 2.14645 10.1464C2.05268 10.2402 2 10.3674 2 10.5V12.5C2 12.8978 2.15804 13.2794 2.43934 13.5607C2.72064 13.842 3.10218 14 3.5 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5ZM10.81 9.89L8.31 11.89C8.22173 11.9598 8.11251 11.9977 8 11.9977C7.88749 11.9977 7.77827 11.9598 7.69 11.89L5.19 9.89C5.09891 9.80402 5.04341 9.68697 5.03448 9.56203C5.02556 9.43708 5.06386 9.31334 5.1418 9.21528C5.21975 9.11722 5.33166 9.05199 5.4554 9.03249C5.57913 9.013 5.70568 9.04066 5.81 9.11L7.5 10.46V3.5C7.5 3.36739 7.55268 3.24021 7.64645 3.14645C7.74021 3.05268 7.86739 3 8 3C8.13261 3 8.25979 3.05268 8.35355 3.14645C8.44732 3.24021 8.5 3.36739 8.5 3.5V10.46L10.19 9.11C10.2403 9.06247 10.3 9.02592 10.3652 9.00266C10.4304 8.9794 10.4998 8.96994 10.5688 8.97487C10.6379 8.9798 10.7052 8.99903 10.7664 9.03132C10.8277 9.06362 10.8815 9.10828 10.9246 9.16248C10.9677 9.21668 10.9991 9.27924 11.0167 9.34619C11.0343 9.41314 11.0379 9.48302 11.0271 9.55142C11.0163 9.61981 10.9915 9.68522 10.9541 9.7435C10.9167 9.80179 10.8677 9.85167 10.81 9.89Z"
            fill="#858FA2"
          />
        </svg>
        Export as CSV
      </div>
    </Menu.Item>
    <Menu.Item key="1">
      <div
        onClick={() => {
          window.open(invoiceUrl, "_blank");
        }}
        className="flex gap-2"
      >
        {Svgs.view}
        View Invoice
      </div>
    </Menu.Item>
  </Menu>
);

function PaymentCategory({
  transactions,
  total,
  pagination,
  handleTableChange,
  isParamsCount,
  vendors,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const user = useSelector((state) => state?.user?.user);

  const isAdmin = getUserRole(user, "admin");

  const dataSource = transactions.map((tran) => {
    return {
      ...tran,
      key: tran.id,
      transaction: `#${tran.id}`,
      client: tran.user,
      amount: "$" + (Number(tran.discountedAmount) || tran.amount),
      datetime: format(tran.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
      credits: tran.creditsPurchased,
      status: tran.status,
      coupon: tran.couponCode || "-",
    };
  });

  const headers = [
    "Transaction ID",
    "Client ID",
    "Amount",
    "Credits",
    "discountedAmount",
    "couponCode",
    "stripeTransactionId",
    "Date & Time",
  ];

  // eslint-disable-next-line no-sparse-arrays
  const parseData = (data) =>
    data.map((item) => [
      item.id,
      item.user.id,
      item.amount,
      item.creditsPurchased,
      item.couponCode,
      item.stripeTransactionId,
      format(item.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
    ]);

  const getColumns = () => {
    return [
      {
        title: "Transaction ID",
        dataIndex: "transaction",
        key: "transaction",
        render: (text) => (
          <Space className="flex gap-2 items-center">
            <span className="text-primary">{text}</span>{" "}
          </Space>
        ),
        sorter: (a, b) => a.key - b.key,
      },
      isAdmin
        ? {
            title: "Vendor",
            dataIndex: "client",
            key: "0",
            render: (user) => (
              <Space className="flex gap-2 items-center whitespace-nowrap">
                <Avatar
                  className="rounded-full w-9 h-9 flex justify-center items-center"
                  size="large"
                  icon={<UserOutlined />}
                />
                <span className="text-primary underline">{user?.name}</span>{" "}
              </Space>
            ),
          }
        : null,
      {
        title: "Amount",
        dataIndex: "amount",
        key: "0",
      },
      {
        title: "Coupon",
        dataIndex: "coupon",
        key: "coupon",
      },
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
        render: (creditsPurchased) => (
          <Space className="flex flex-wrap gap-2 items-center">
            <span>{creditsPurchased}</span>
          </Space>
        ),
      },
      {
        title: "Date & Time",
        dataIndex: "datetime",
        key: "0",
        render: (datetime) => (
          <span style={{ whiteSpace: "nowrap" }}>{datetime}</span>
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (status, record) =>
          status === "Paid" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                {status}
              </div>
            </Space>
          ) : (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#F00F00] py-[2px] px-2 rounded-2xl">
                {status}
              </div>
            </Space>
          ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={
                <UserDropdown
                  invoiceUrl={record.invoiceUrl}
                  csvdata={parseData([record])}
                  headers={headers}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ].filter(Boolean);
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Payment History</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Payment History
          </Text>
        </Col>
        <Col>
          <Button
            onClick={() => {
              const data = [headers, ...parseData(transactions)];
              exportToCSV(data);
            }}
            className="bg-primary flex justify-center items-center w-full max-w-[180px] py-5 text-base font-normal text-white rounded-lg"
            type="primary"
            disabled={!transactions.length}
            icon={Svgs.download}
          >
            Export as CSV
          </Button>
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          defaultValue={params.get("search")}
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search..."
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                query: value,
              },
              "query"
            );
            navigate(`/payment${queryString}`);
          }}
        />
        <div className="flex gap-2 items-center">
          <Space size="middle" className="w-full">
            <Dropdown
              overlay={<PaymentFilterDropdown vendors={vendors} />}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
                {Svgs.filter} <span>Filter</span>
              </Space>
            </Dropdown>
          </Space>
          {isParamsCount ? (
            <Button
              type=""
              onClick={() => navigate("/payment")}
              className={`bg-[#D7E5FF] flex gap-2 justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg`}
            >
              <span>Clear</span>
            </Button>
          ) : null}
        </div>
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default PaymentCategory;
