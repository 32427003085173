import * as Yup from "yup";

const zipCodeRegex =
  /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1})$/i;

const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters long")
    .max(50, "Name must not exceed 50 characters")
    .required('"Name" is not allowed to empty'),

  description: Yup.string()
    .min(5, "Description must be at least 5 characters long")
    .max(200, "Description must not exceed 200 characters")
    .required('"Description" is not allowed to empty'),
    zipCode: Yup.string().test(
        "conditional-required",
        'Enter a valid "Zip Code"',
        function (value) {
            const { zipCodesEntries } = this.parent;

            if (!zipCodesEntries || zipCodesEntries.length <= 0) {
                if (!value) {
                    return false;
                }

                const zipCodes = value
                    .split(",")
                    .map((zip) => zip.trim())
                    .filter((zip) => zipCodeRegex.test(zip));

                return zipCodes.length > 0;
            }

            if (value) {
                const zipCodes = value
                    .split(",")
                    .map((zip) => zip.trim())
                    .filter((zip) => zipCodeRegex.test(zip));

                return zipCodes.length > 0 || !value;
            }

            return true;
        }
    ),
  creditValue: Yup.number().required('"Credit" is not allowed to empty'),
  areaCategoryId: Yup.number().optional(),
});

export default formValidationSchema;
