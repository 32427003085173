import { Avatar, Col, Typography } from "antd";
import React from "react";

const { Text } = Typography;

// const UserDropdown = ({ data }) => (
//   <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
//     <Menu.Item key="0">
//       <PauseClient />
//     </Menu.Item>
//     <Menu.Item key="1">
//       <DeleteClient data={data} />
//     </Menu.Item>
//   </Menu>
// );

const ClientSubscription = ({ proposals }) => {
  if (!proposals?.length) return null;
  return (
    <Col span={24} className="bg-white card__shadow lg:p-5 p-3 rounded-lg">
      <Text className="md:text-xl text-lg font-medium text-gray mb-3">
        Availed Services:
      </Text>
      <div>
        <div className="grid xl:grid-cols-3 gap-4 mt-5">
          {proposals?.map((proposal) => {
            return (
              <div className="bg-[#fbfbfb] border border-[#DEDEDE] rounded pt-2 px-2 pb-10 relative">
                <div className="flex items-center gap-2">
                  <Avatar
                    className="w-9 h-9 rounded-full"
                    src={
                      proposal?.request?.category?.iconURL ||
                      "/assets/images/avatar.svg"
                    }
                  />
                  <Text className="text-base text-primary font-medium">
                    {proposal?.request?.category?.name}
                  </Text>
                </div>
                <div className="mt-2 flex flex-col gap-2">
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">Area Name: </span>
                    {proposal?.serviceSubscription?.areas?.map((area) => (
                      <span className="font-normal text-[#888888]">
                        {area?.name}
                      </span>
                    ))}
                  </div>
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">Zip code:</span>

                    {proposal?.serviceSubscription?.areas
                      ?.flatMap((area) => area.zipCodes)
                      .map(({ zipCode }) => (
                        <span className="font-normal text-[#888888]">
                          {zipCode},
                        </span>
                      ))}
                  </div>
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">
                      Area Category:
                    </span>
                    <span className="font-normal text-[#888888]">
                      {proposal?.serviceSubscription?.areas
                        ?.flatMap((area) => area?.areaCategory)
                        ?.map((cat) => cat?.name)?.length
                        ? proposal?.serviceSubscription?.areas
                            ?.flatMap((area) => area?.areaCategory)
                            ?.map((cat) => cat?.name)
                            ?.join(", ")
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="bg-[#DDDFE3] p-2 text-primary text-xs absolute bottom-0 w-full left-0 rounded-bl rounded-br">
                  <div className="flex items-center gap-1">
                    <span className="font-medium">Vendor Name:</span>
                    <span className="font-normal">{proposal?.user?.name}</span>
                  </div>
                </div>
                {/* <Space size="middle" className="absolute top-2 right-2">
                  <Dropdown
                    overlay={<UserDropdown proposal={proposal} />}
                    placement="bottomRight"
                    trigger={["click"]}
                    className="cursor-pointer"
                  >
                    <Space className="gap-3 items-center">{Svgs.dots}</Space>
                  </Dropdown>
                </Space> */}
              </div>
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default ClientSubscription;
