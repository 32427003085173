import React from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestDetail from "../components/Request/request-detail";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { useQuery } from "react-query";
import { fetchClientRequestById } from "../service/request";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/loader";

export default function ServiceRequestDetails() {
  useSetTabDetails("Service request");
  const params = useParams();

  const {
    data: clientRequest,
    isLoading,
    error,
    refetch,
  } = useQuery(["clientRequest", params.requestId], () =>
    fetchClientRequestById(params.requestId)
  );

  if (isLoading) return <Loader />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <PerfectScrollbar style={{ height: "100vh" }}>
      <div className="h-full min-h-screen grid grid-columns">
        <SideBar />
        <div className="relative flex flex-col">
          <Header />
          <Content className="px-4 pt-28 pb-6">
            <RequestDetail clientRequest={clientRequest} refetch={refetch} />
          </Content>
        </div>
      </div>
    </PerfectScrollbar>
  );
}
