import React from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import PurchaseCreditEmpty from "../components/PurchaseCredit/purshase-credit";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function Credit() {
  useSetTabDetails('Purchased Credits');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <PurchaseCreditEmpty />
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
