import React, { useState } from "react";
import { Button, Modal, Typography, Layout, Flex, Image, Spin } from "antd";
import { delay } from "../../utils/common-util";

const { Text } = Typography;
const { Content } = Layout;

const ThankYou = ({ handleSubmit, mutation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full max-w-[110px]">
      <Button
        onClick={async () => {
          await handleSubmit();
          await delay(500);
          showModal();
        }}
        className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
        type=""
      >
        Yes
      </Button>

      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Image
            preview={false}
            className="max-w-[70px] mx-auto"
            src="/assets/images/thankyou.png"
          />
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-base font-semibold text-[#0F172A] text-center mb-2">
              Thank You!
            </Text>
            <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
              You have accepted the proposal
            </Text>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Button
              className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
              type=""
              onClick={() => window.location.reload()}
            >
              Ok
            </Button>
          </Flex>
        </Content>
      </Modal>
    </div>
  );
};

export default ThankYou;
