import {
  Avatar,
  Breadcrumb,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import RequestChat from "../Request/request-chat";
import { Svgs } from "../Svgs/svg-icons";
import AcceptProposal from "./accept-proposal";
import DeleteProposal from "./delete-proposal";

const { Text } = Typography;

// client module use

const UserDropdown = ({ proposalId, proposal, refetch }) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    {/* <Menu.Item key="0">
      <WithDraw />
    </Menu.Item> */}
    <Menu.Item key="1">
      <DeleteProposal
        refetch={refetch}
        proposalId={proposalId}
        proposal={proposal}
      />
    </Menu.Item>
  </Menu>
);

function ProposalResponded({ proposal, refetch }) {
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();

  const isClient = user?.role?.name === "CLIENT";

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/proposal">Service Requests</Link>,
          },
          {
            title: <span>Proposal</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center gap-4 mb-3">
            <span onClick={() => navigate(-1)} className="cursor-pointer">
              {Svgs.arrowback}
            </span>
            {proposal?.user?.name} Proposal Details
          </Text>
          <Text className="text-sm font-normal text-[#888888]">
            Here you can see all the detail of this job, that how many vendors
            has responded and what is the status of this request.
          </Text>
        </Col>
        <Col className="flex items-center gap-2">
          {isClient ? <AcceptProposal proposal={proposal} /> : null}
          {!isClient && (
            <Dropdown
              overlay={
                <UserDropdown
                  refetch={refetch}
                  proposalId={proposal.id}
                  proposal={proposal}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-1 items-center text-xs font-medium text-primary py-3 px-3 bg-[#D7E5FF] rounded">
                {Svgs.action} <span>Action</span>{" "}
              </Space>
            </Dropdown>
          )}
        </Col>
      </Row>
      <div className="bg-[#f5f5f5] rounded-2xl lg:p-5 p-3">
        <div className="flex flex-col gap-5">
          <div className="bg-white rounded-md p-3">
            <div className="flex lg:flex-row flex-col justify-between items-start">
              <div>
                <h3 className="text-base text-gray font-medium">
                  Service Category:
                </h3>
                <div className="flex gap-2 items-center mt-2">
                  <Avatar
                    src="/assets/images/avatar.svg"
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="flex flex-col gap-1">
                    <span className="text-base text-primary font-medium">
                      {proposal?.request?.category?.name}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray font-normal">
                        Proposal Status:{" "}
                      </span>
                      <Tag className="text-xs font-normal capitalize py-[2px] px-2 rounded-2xl">
                        {proposal?.status}
                      </Tag>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <Text className="text-base font-normal text-gray">
                  Request Date:
                </Text>
                <div className="bg-white border border-[#DBDBDB] rounded-md py-3 px-4 text-sm font-normal text-gray">
                  {format(proposal.createdAt, "MM-dd-yyyy")}
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-5">
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Zip Code:</span>
                <span className="font-normal text-[#888888]">
                  {proposal?.request?.zipCode}
                </span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Amount:</span>
                <span className="font-normal text-[#888888]">
                  {`$${proposal?.budget}` || 'N/A'}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-md p-3">
            <div className="flex flex-col gap-1 text-base">
              <span className="font-medium text-gray">Message:</span>
              <span className="font-normal text-[#888888]">
                {proposal?.description}
              </span>
            </div>
          </div>
        </div>
        <Row className="mt-5">
          <Col span={24}>
            <div className="bg-white border border-[#E1E1E1] rounded-md pt-3 pb-6 px-3">
              <div className="flex lg:flex-row flex-col lg:gap-0 gap-3 justify-between items-center">
                <Text className="text-gray font-medium text-base">
                  Vendor's Name:
                </Text>
              </div>
              <div className="flex gap-5 items-center">
                <div className="flex items-center gap-3 mt-2">
                  <Avatar
                    className="w-12 h-12 rounded-full"
                    src="/assets/images/avatar-vendor.svg"
                  />
                  <div className="flex flex-col">
                    <Text className="text-base font-medium text-primary underline">
                      {proposal?.user?.name}
                    </Text>
                    <div className="flex items-center gap-3 mb-1">
                      <Text className="text-gray text-sm font-normal">
                        {proposal?.user?.email}
                      </Text>
                    </div>
                  </div>
                </div>
                <RequestChat proposals={[proposal]} request={proposal?.request} isFromClient={true} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ProposalResponded;
