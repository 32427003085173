import { format } from "date-fns";

const headers = [
  "Log Id",
  "User Id",
  "User name",
  "Type",
  "Status",
  "Activity",
  "Description",
  "Date & Time",
];

// eslint-disable-next-line no-sparse-arrays
const parseData = (data) =>
  data.map((item) => [
    item.id,
    item.user.id,
    item.user.name,
    item.user?.role?.name,
    item.status,
    item.activity,
    item.description,
    format(item.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
  ]);

export const generateLogsDataForCSV = (logs) => [headers, ...parseData(logs)];
