import { useQuery } from "react-query";
import axios from "../config/Axios";

const fetchUserAnalysis = async () => {
  const response = await axios.get("/users/users-analysis");
  return response.data;
};

export const useFetchUserAnalysis = () => {
  return useQuery(["fetchUserAnalysis"], () => fetchUserAnalysis(), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: "always",
    refetchOnMount: true,
    staleTime: 0,
  });
};
