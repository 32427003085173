import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
    fetchArea,
    createArea,
    updateArea,
    deleteArea,
    fetchAreaZipCodes,
    searchArea,
    filterArea,
    fetchAreaById,
    fetchActiveArea, calculateAreaValue,
} from "../../service/areaService";

const initialState = {
  area: [],
  zipCodes: [],
  areaById: null,
};

export const getAllArea = createAsyncThunk(
  "areas/getAllArea",
  async (pagination) => {
    const response = await fetchArea(pagination);
    return response.data;
  }
);

export const getActiveAreas = createAsyncThunk(
  "areas/getActiveAreas",
  async (pagination) => {
    const response = await fetchActiveArea(pagination);
    return response.data;
  }
);

export const getAreaById = createAsyncThunk("areas/getAreaById", async (id) => {
  const response = await fetchAreaById(id);
  return response.data;
});

export const getAreaZipCodes = createAsyncThunk(
  "areas/fetchAreaZipCodes",
  async (pagination) => {
    const response = await fetchAreaZipCodes(pagination);
    return response.data;
  }
);

export const search_Areas = createAsyncThunk(
  "areas/searchArea",
  async ({ query, page, pageSize }) => {
    const response = await searchArea({ query, page, pageSize });
    return response.data;
  }
);

export const filter_Areas = createAsyncThunk(
  "areas/filterArea",
  async ({ areaCategoryId, zipCodes, areaName, page, pageSize }) => {
    const response = await filterArea({
      areaCategoryId,
      zipCodes,
      areaName,
      page,
      pageSize,
    });
    return response.data;
  }
);
export const addArea = createAsyncThunk(
  "areas/addarea",
  async (areaData, { rejectWithValue }) => {
    try {
      const response = await createArea(areaData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const update_Area = createAsyncThunk(
  "area/updateArea",
  async ({ id, ...areaData }) => {
    const response = await updateArea(id, areaData);
    return response.data;
  }
);

export const delete_Area = createAsyncThunk(
  "area/deleteArea",
  async (areaId) => {
    await deleteArea(areaId);
    return areaId;
  }
);

export const calculate_Area_Value = createAsyncThunk(
    'area/calculateAreaValue',
    async (areaData, { rejectWithValue }) => {
        try {
            const response = await calculateAreaValue(areaData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllArea.fulfilled, (state, action) => {
      state.area = action.payload;
    });
    builder
      .addCase(filter_Areas.fulfilled, (state, action) => {
        state.area = action.payload;
      })
      .addCase(search_Areas.fulfilled, (state, action) => {
        state.area = action.payload;
      })
      .addCase(getAreaById.fulfilled, (state, action) => {
        state.areaById = action.payload;
      })
      .addCase(getActiveAreas.fulfilled, (state, action) => {
        state.activeAreas = action.payload || [];
      })
    .addCase(calculate_Area_Value.fulfilled, (state, action) => {
        state.calculatedValue = {
            calculatedValue: action.payload.value,
        };
    })
      .addCase(getAreaZipCodes.fulfilled, (state, action) => {
        state.zipCodes = action.payload || [];
      });

    // .addCase(update_Area.fulfilled, (state, action) => {
    //   const updatedArea = action.payload;
    //   const index = state.area.findIndex(
    //     (area) => area.id === updatedArea.id
    //   );
    //   state.area[index] = updatedArea;
    // })
    // .addCase(delete_Area.fulfilled, (state, action) => {
    //   const areaId = action.payload;
    //   state.area = state.area.filter((area) => area.id !== areaId);
    // });
  },
});

export default areaSlice.reducer;
