import React from "react";
import { Image, Typography, Row, Col, Breadcrumb } from "antd";
import AddService from "./add-service";
import { Link } from "react-router-dom";

const { Text } = Typography;

const ServiceEmpty = ({ pagination, setPagination }) => {
  return (
    <>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to="/service">Service Category</Link>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-8">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            Service Category
          </Text>
        </Col>
        <Col>
          <AddService pagination={pagination} setPagination={setPagination} />
        </Col>
      </Row>

      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <>
          <Image
            preview={false}
            className="lg:max-w-md mx-auto mb-3"
            src="/assets/images/no-area-img-new.svg"
          />
          <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2">
            You don’t have any Data yet!
          </Text>
          <Text className="text-[#868687] md:text-lg text-base font-normal mb-7">
            Please click the button to add new data.
          </Text>
          <AddService pagination={pagination} setPagination={setPagination} />
        </>
      </section>
    </>
  );
};

export default ServiceEmpty;
