export const getUserId = () => Number(localStorage.getItem("userId"));

export const ADMIN_TYPE = {
  LOGISTIC_SUB_ADMIN: "LOGISTIC_SUB_ADMIN",
  SUB_ADMIN: "SUB_ADMIN",
};

export const ADMIN_TYPE_VALUE = {
  LOGISTIC_SUB_ADMIN: "Logistic Sub Admin",
  SUB_ADMIN: "Sub Admin",
};

export const SUPER_ADMIN_TYPE = "ADMIN";
export const SUPER_ADMIN_ID = 1;
export const VENDOR_ROLE_ID = 3;

export const getUserRole = (user, role) => {
  if (role === "vendor") {
    return user?.role?.id === 3;
  }
  if (role === "admin") {
    return user?.role?.id === 1;
  }
  if (role === "client") {
    return user?.role?.id === 2;
  }
  if (role === "subAdmin") {
    return user?.role?.id === 4;
  }
  if (role === "logisticAdmin") {
    return user?.role?.id === 5;
  }
};

export const STATIC_GRAPH_DATA = [
  {
    bgColor: "#E3F5FF",
    graphColor: "#27AAE1",
    title: "Total Credits Left:",
    number: "0",
    analytics: "0%",
    data: [], // Example data
    categories: [], // Example categories
  },
  {
    bgColor: "#E5ECF6",
    graphColor: "#1EB564",
    title: "Subscribed Credits:",
    number: "0",
    analytics: "+0.00%",
    data: [20, 29, 37, 36, 44, 45, 50, 58, 63], // Example data
    categories: ["User1", "User2", "User3"], // Example categories
  },
  {
    bgColor: "#FBF0F0",
    title: "Total Subscriptions:",
    graphColor: "#D02626",
    number: "0",
    analytics: "+100.00%",
    data: [0, 4], // Example data
    categories: ["1", "2"], // Example categories
  },
  {
    bgColor: "#FAEFD9",
    title: "Daily Expense:",
    graphColor: "#7F3E3B",
    number: "0",
    analytics: "-0.00%",
    data: [15, 23, 20, 25, 19, 20, 23, 28, 27], // Example data
    categories: ["User1", "User2", "User3"], // Example categories
  },
  {
    bgColor: "#FEEBFF",
    title: "New Subscriptions:",
    graphColor: "#A01EB5",
    number: "0",
    analytics: "+100.00%",
    data: [0, 4], // Example data
    categories: ["User1", "User2"], // Example categories
  },
]
