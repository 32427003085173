import {
  Avatar,
  Breadcrumb,
  Col,
  Layout,
  Row,
  Space,
  Table,
  Typography
} from "antd";
import React, { useState } from "react";
import { Svgs } from "../Svgs/svg-icons";

const { Text } = Typography;

// client module use

// const userDropdown = (
//   <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
//     <Menu.Item key="0">
//       <WithDraw />
//     </Menu.Item>
//     <Menu.Item key="1">
//       <DeleteProposal />
//     </Menu.Item>
//   </Menu>
// );

// const userDropdownVendor = (
//   <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
//     <Menu.Item key="0" className="dropdown__item__header" icon={Svgs.view}>
//       View Details
//     </Menu.Item>
//   </Menu>
// );

function ProposalDetail() {
  const data = [
    {
      key: "0",
      vendors: "Plumber",
      email: "adam123@gmail.com",
      proposalamount: "$ 200.00",
      date: "21-08-2023",
    },
  ];

  const [dataSource] = useState(data);

  const getColumns = () => {
    return [
      {
        title: "Vendors",
        dataIndex: "vendors",
        key: "0",
        render: (text) => (
          <Space className="flex gap-2 items-center">
            <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src="/assets/images/avatar-vendor.svg"
            />
            <span className="text-primary underline">Adam Synder</span>{" "}
          </Space>
        ),
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "0",
      },
      {
        title: "Proposal Amount",
        dataIndex: "proposalamount",
        key: "0",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "0",
        sorter: (a, b) => a.age - b.age,
      },
    ];

    // client module use

    // return [
    //   {
    //     title: "Vendors",
    //     dataIndex: "vendors",
    //     key: "0",
    //     render: (text) => (
    //       <Space className="flex gap-2 items-center">
    //         <Avatar
    //           className="rounded-full w-9 h-9 flex justify-center items-center"
    //           size="large"
    //           src="/assets/images/avatar-vendor.svg"
    //         />
    //         <span className="text-primary underline">Adam Synder</span>{" "}
    //       </Space>
    //     ),
    //     sorter: (a, b) => a.age - b.age,
    //   },
    //   {
    //     title: "Email",
    //     dataIndex: "email",
    //     key: "0",
    //   },
    //   {
    //     title: "Proposal Amount",
    //     dataIndex: "proposalamount",
    //     key: "0",
    //   },
    //   {
    //     title: "Date",
    //     dataIndex: "date",
    //     key: "0",
    //     sorter: (a, b) => a.age - b.age,
    //   },
    //   {
    //     title: "",
    //     dataIndex: "actions",
    //     key: "actions",
    //     align: "right",
    //     render: (text, record) => (
    //       <Space size="middle">
    //         <Dropdown
    //           overlay={userDropdownVendor}
    //           placement="bottomRight"
    //           trigger={["click"]}
    //           className="cursor-pointer"
    //         >
    //           <Space className="gap-3 items-center">{Svgs.dots}</Space>
    //         </Dropdown>
    //       </Space>
    //     ),
    //   },
    // ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span >Proposals</span>,
          },
          {
            title: <span >Proposal Details</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center gap-4 mb-3">
            {Svgs.arrowback}
            Request Details
          </Text>
          <Text className="text-sm font-normal text-[#888888]">
            Here you can see all the detail of this job, that how many vendors
            has responded and what is the status of this request.
          </Text>
        </Col>
      </Row>
      <div className="bg-[#f5f5f5] rounded-2xl lg:p-5 p-3">
        <div className="flex flex-col gap-5">
          <div className="grid lg:grid-cols-2 gap-5">
            <div className="bg-white rounded-md p-3">
              <h3 className="text-base text-gray font-medium">
                Service Category:
              </h3>
              <div className="flex gap-2 items-center mt-2">
                <Avatar
                  src="/assets/images/avatar.svg"
                  className="w-16 h-16 rounded-full"
                />
                <div className="flex flex-col gap-1">
                  <span className="text-base text-primary font-medium">
                    Plumber
                  </span>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray font-normal">
                      Proposal Status:{" "}
                    </span>
                    <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                      Accept
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <h3 className="text-base text-gray font-medium">Client Name:</h3>
              <div className="flex gap-2 items-center mt-2">
                <Avatar
                  src="/assets/images/avatar-vendor.svg"
                  className="w-16 h-16 rounded-full"
                />
                <div className="flex flex-col gap-1">
                  <span className="md:text-2xl text-xl text-primary font-normal">
                    John Harry
                  </span>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-[#303030] font-normal">
                      User@mail.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Area Name:</span>
                <span className="font-normal text-[#888888]">San Diego</span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Area Category:</span>
                <span className="font-normal text-[#888888]">San Diego</span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Zip Code:</span>
                <span className="font-normal text-[#888888]">(A1A A1A)</span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Credit Value:</span>
                <span className="font-normal text-[#888888]">10</span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-md p-3">
            <div className="flex flex-col gap-1 text-base">
              <span className="font-medium text-gray">Description:</span>
              <span className="font-normal text-[#888888]">
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore
              </span>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl mt-6">
          <h2 className="text-xl font-medium text-gray px-4 py-5">
            Responded Vendors:
          </h2>
          <Table
            className="service-table proposal-table bg-white"
            columns={getColumns()}
            scroll={{ x: 400 }}
            dataSource={dataSource}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              total: 100,
            }}
          />
        </div>
      </div>
    </Layout>

    // client module proposal

    // <Layout>
    //   <Breadcrumb
    //     className="mb-4"
    //     items={[
    //       {
    //         title: <a href="">Proposal Management</a>,
    //       },
    //       {
    //         title: <a href="">Sturgis’s Proposal Details</a>,
    //       },
    //     ]}
    //   />
    //   <Row className="flex justify-between items-center mb-4">
    //     <Col>
    //       <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center gap-4 mb-3">
    //         {Svgs.arrowback}
    //         Request Details
    //       </Text>
    //       <Text className="text-sm font-normal text-[#888888]">
    //         You can see all details about the Proposal here.
    //       </Text>
    //     </Col>
    //     <Col className="flex items-center gap-2">
    //       <Dropdown
    //         overlay={userDropdown}
    //         placement="bottomRight"
    //         trigger={["click"]}
    //         className="cursor-pointer"
    //       >
    //         <Space className="gap-1 items-center text-xs font-medium text-primary py-3 px-3 bg-[#D7E5FF] rounded">
    //           {Svgs.action} <span>Action</span>{" "}
    //         </Space>
    //       </Dropdown>
    //     </Col>
    //   </Row>
    //   <div className="bg-[#f5f5f5] rounded-2xl lg:p-5 p-3">
    //     <div className="flex flex-col gap-5">
    //       <div className="bg-white rounded-md p-3">
    //         <div className="flex lg:flex-row flex-col justify-between items-start">
    //           <div>
    //             <h3 className="text-base text-gray font-medium">
    //               Service Category:
    //             </h3>
    //             <div className="flex gap-2 items-center mt-2">
    //               <Avatar
    //                 src="/assets/images/avatar.svg"
    //                 className="w-16 h-16 rounded-full"
    //               />
    //               <div className="flex flex-col gap-1">
    //                 <span className="text-base text-primary font-medium">
    //                   Plumber
    //                 </span>
    //                 <div className="flex items-center gap-2">
    //                   <span className="text-sm text-gray font-normal">
    //                     Proposal Status:{" "}
    //                   </span>
    //                   <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
    //                     Accept
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="flex items-center gap-2">
    //             <Text className="text-base font-normal text-gray">
    //               Request Link:
    //             </Text>
    //             <Link className="text-primary hover:text-primary font-normal underline hover:underline">
    //               https://www.w3.org/Provider/Style/dummy.html
    //             </Link>
    //             <div className="cursor-pointer">{Svgs.linkcopy}</div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="grid lg:grid-cols-2 gap-5">
    //         <div className="bg-white rounded-md p-3">
    //           <div className="flex gap-1 items-center text-base">
    //             <span className="font-medium text-gray">Area Name:</span>
    //             <span className="font-normal text-[#888888]">San Diego</span>
    //           </div>
    //         </div>
    //         <div className="bg-white rounded-md p-3">
    //           <div className="flex gap-1 items-center text-base">
    //             <span className="font-medium text-gray">Area Category:</span>
    //             <span className="font-normal text-[#888888]">San Diego</span>
    //           </div>
    //         </div>
    //         <div className="bg-white rounded-md p-3">
    //           <div className="flex gap-1 items-center text-base">
    //             <span className="font-medium text-gray">Zip Code:</span>
    //             <span className="font-normal text-[#888888]">(A1A A1A)</span>
    //           </div>
    //         </div>
    //         <div className="bg-white rounded-md p-3">
    //           <div className="flex gap-1 items-center text-base">
    //             <span className="font-medium text-gray">Credit Value:</span>
    //             <span className="font-normal text-[#888888]">10</span>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="bg-white rounded-md p-3">
    //         <div className="flex flex-col gap-1 text-base">
    //           <span className="font-medium text-gray">Description:</span>
    //           <span className="font-normal text-[#888888]">
    //             lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore lorem ipsum dolor sit amet,
    //             consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    //             labore
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="bg-white rounded-xl mt-6">
    //       <h2 className="text-xl font-medium text-gray px-4 py-5">
    //         Responded Vendors:
    //       </h2>
    //       <Table
    //         className="service-table proposal-table bg-white"
    //         columns={getColumns()}
    //         scroll={{ x: 400 }}
    //         dataSource={dataSource}
    //         pagination={{
    //           defaultCurrent: 1,
    //           pageSize: 10,
    //           total: 100,
    //         }}
    //       />
    //     </div>
    //   </div>
    // </Layout>
  );
}

export default ProposalDetail;
