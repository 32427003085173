import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserRole, STATIC_GRAPH_DATA } from "../../../constants";
import {
  useFetchDailyExpense,
  useFetchNewSubscriptions,
  useFetchTotalCredits,
  useFetchTotalSubscribedCredits,
  useFetchTotalSubscriptions,
} from "../../../service/totalCredits";
import { Svgs } from "../../Svgs/svg-icons";

const Card = ({ card }) => {
  // const chartOptions = {
  //   options: {
  //     chart: {
  //       id: "basic-bar",
  //       sparkline: {
  //         enabled: true,
  //       },
  //     },
  //     stroke: {
  //       curve: "smooth",
  //     },
  //     fill: {
  //       opacity: 2.2,
  //       type: "gradient",
  //     },
  //     colors: [card.graphColor],
  //     yaxis: {
  //       min: 0,
  //       labels: {
  //         show: false,
  //       },
  //     },
  //     xaxis: {
  //       type: "category",
  //       categories: card.categories,
  //     },
  //   },
  //   series: [
  //     {
  //       name: card.title,
  //       data: card.data,
  //     },
  //   ],
  // };

  return (
    <div
      className={`flex items-center flex-col px-3 pb-2 pt-4 rounded-md bg-[${card.bgColor}]`}
      style={{ backgroundColor: card.bgColor }}
    >
      <h4 className="lg:text-sm max-lg:text-xs font-medium text-[#50586C]">
        {card.title}
      </h4>
      <h3 className="lg:text-xl max-lg:text-lg font-semibold text-[#303030] my-1">
        {card.number}
      </h3>
      {/* <p
        className={`text-xs ${
          card.analytics.startsWith("-") ? "text-[#EE2E6B]" : "text-[#01C0AA]"
        } flex items-center gap-6px`}
      >
        <span>{card.analytics}</span>
        <span className={card.analytics.startsWith("-") ? "" : "-rotate-180"}>
          {Svgs.arrow}
        </span>
      </p> */}
      {/* <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="area"
        height="100"
      /> */}
    </div>
  );
};

const Statistics = () => {
  const user = useSelector((state) => state?.user?.user);
  const isAdmin = getUserRole(user, "admin");

  const { data: totalCredits } = useFetchTotalCredits({
    userId: !isAdmin ? user?.id : undefined,
  });
  const { data: totalSubscriptions } = useFetchTotalSubscriptions({
    userId: !isAdmin ? user?.id : undefined,
  });
  const { data: newSubscriptions } = useFetchNewSubscriptions({
    userId: !isAdmin ? user?.id : undefined,
  });
  const { data: dailyExpense } = useFetchDailyExpense({
    userId: !isAdmin ? user?.id : undefined,
  });
  const { data: subscribedCredits } = useFetchTotalSubscribedCredits({
    userId: !isAdmin ? user?.id : undefined,
  });
  const [cards, setCards] = useState(STATIC_GRAPH_DATA);

  useEffect(() => {
    if (totalCredits) {
      setCards((prevCards) => {
        const updatedCards = [...prevCards];
        // const newData = totalCredits.allUsersCredits.map(
        //   (user) => user.totalCredits
        // );
        // const newCategories = totalCredits.allUsersCredits.map(
        //   (user) => user.userId
        // );
        // const lastValue = newData[newData.length - 1];
        // const secondLastValue =
        //   newData.length > 1 ? newData[newData.length - 2] : 0;
        // const analytics =
        //   secondLastValue === 0
        //     ? 100
        //     : ((lastValue - secondLastValue) / secondLastValue) * 100;
        // const analyticsFormatted =
        //   totalCredits.totalCredits === 0
        //     ? "+0.00%"
        //     : `${analytics >= 0 ? "+" : ""}${analytics.toFixed(2)}%`;

        updatedCards[0] = {
          ...updatedCards[0],
          number: totalCredits?.totalCredits?.toString() || "0",
        };
        return updatedCards;
      });
    }
  }, [totalCredits]);
  useEffect(() => {
    if (totalSubscriptions) {
      setCards((prevCards) => {
        const updatedCards = [...prevCards];
        updatedCards[2] = {
          ...updatedCards[2],
          number: totalSubscriptions?.totalSubscriptions?.toString() || "0",
        };
        return updatedCards;
      });
    }
  }, [totalSubscriptions]);
  useEffect(() => {
    if (newSubscriptions) {
      setCards((prevCards) => {
        const updatedCards = [...prevCards];
        updatedCards[4] = {
          ...updatedCards[4],
          number: newSubscriptions?.totalNewSubscriptions?.toString() || "0",
        };
        return updatedCards;
      });
    }
  }, [newSubscriptions]);
  // useEffect(() => {
  //   if (dailyExpense) {
  //     setCards((prevCards) => {
  //       const updatedCards = [...prevCards];
  //       updatedCards[1] = {
  //         ...updatedCards[1],
  //         number: dailyExpense?.totalCurrentMonth?.toString() || "0",
  //       };
  //       return updatedCards;
  //     });
  //   }
  // }, [dailyExpense]);
  useEffect(() => {
    if (subscribedCredits) {
      setCards((prevCards) => {
        const updatedCards = [...prevCards];
        updatedCards[1] = {
          ...updatedCards[1],
          number: subscribedCredits?.totalSubscribedCredits?.toString() || "0",
        };
        updatedCards[3] = {
          ...updatedCards[3],
          number: subscribedCredits?.totalSubscribedCredits?.toString() || "0",
        };
        return updatedCards;
      });
    }
  }, [subscribedCredits]);

  return (
    <div className="rounded-2xl bg-white p-4 pb-3 shadow-md w-full border border-[#F8F9FA] space-y-5">
      <div className="flex items-center justify-between gap-3">
        <h4 className="lg:text-20px max-lg:text-17px text-primary flex items-center gap-5 font-semibold">
          <span className="leading-none">Total Credits Stats</span>
          <Tooltip placement="top" title="Total Credits Stats">
            <span>{Svgs.tooltip}</span>
          </Tooltip>
        </h4>
        {/* <div className="flex items-center justify-end gap-3">
          <DatePicker picker="month" onChange={handleDateChange} />
        </div> */}
      </div>
      <div className="mt-6 grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {cards.map((card, index) => (
          <Card key={index} card={card} />
        ))}
      </div>
    </div>
  );
};

export default Statistics;
