import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchClientProposalById } from "../../service/clientProposal";

const initialState = {
  clientProposal: [],
};

export const getClientProposalById = createAsyncThunk(
  "clientProposal/getClientProposalById",
  async (id) => {
    const response = await fetchClientProposalById(id);
    return response.data;
  }
);

const clientProposalSlice = createSlice({
  name: "clientProposal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getClientProposalById.fulfilled, (state, action) => {
      state.clientProposal = action.payload;
    });
  },
});

export default clientProposalSlice.reducer;
