import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Spin,
} from "antd";
import { phone } from "phone";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Svgs } from "../components/Svgs/svg-icons";
import { ADMIN_TYPE, SUPER_ADMIN_TYPE } from "../constants";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { getAllRoles } from "../store/roles/roleSlice";
import { addUser } from "../store/user/userSlice";
const { Content } = Layout;

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roles = useSelector((state) => state.role.roles);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState("");
  const [countryCode, setCountryCode] = useState("us");
  const [formValues, setFormValues] = useState({
    roleId: null,
    name: `${firstName} ${lastName}`,
    email: "",
    phoneNumber: "",
    password: "",
    createdBy: 1,
    updatedBy: 1,
  });

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const onFinish = async () => {
    try {
      setLoading(true);
      // Extract and clean the phone number
      const cleanedPhoneNumber = formValues.phoneNumber.replace(/[\s-]/g, "");

      // Update formValues with the cleaned phone number
      const updatedFormValues = {
        ...formValues,
        phoneNumber: cleanedPhoneNumber,
        name: `${firstName} ${lastName}`,
      };
      const resultAction = await dispatch(addUser(updatedFormValues));
      const response = resultAction.payload;
      if (resultAction.error) {
        toast.error(resultAction.error.message);
      } else if (resultAction) {
        if (response) {
          toast.success("Please Check Your Email and Confirm it");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response?.message || "Something went wrong");
        }
      }
    } catch (error) {
      console.error("Form submission error:", error.message);
      toast.error(error.message);
      navigate("/signup");
    } finally {
      setLoading(false);
    }
  };
  useSetTabDetails("Sign-up");
  const validatePhoneNumber = (_, value) => {
    const temp = phone(formValues.phoneNumber, {
      country: countryCode.toUpperCase(),
    });
    if (temp.isValid) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid phone number");
  };
  return (
    <>
      <Content>
        <Row className="w-full flex lg:flex-row lg:items-stretch flex-col lg:gap-0 gap-5 lg:min-h-screen lg:max-h-screen overflow-hidden">
          <Col className="lg:w-[41.8%] relative flex flex-col justify-between">
            <PerfectScrollbar className="lg:max-h-screen lg:px-9 px-5 py-5">
              <div className="flex items-center justify-center lg:justify-start max-w-[200px] mx-auto lg:mx-0 lg:px-0 lg:mb-9 max-lg:mb-4">
                {Svgs.loginlogo}
              </div>
              <div className="flex flex-col justify-center items-center">
                <h1 className="md:text-[26px] text-xl font-normal leading-normal text-dark text-center mt-3 mb-1">
                  Sign Up Your Account
                </h1>
                <p className="text-sm text-primary font-normal leading-normal text-center">
                  Set up your profile to get started the account
                </p>
                <Form
                  name="signup"
                  size="large"
                  layout="vertical"
                  onFinish={onFinish}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    margin: "30px auto 0 auto",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="flex flex-col gap-3"
                  validateMessages={{
                    required: "Field is required",
                    types: {
                      email: "Not a valid email",
                    },
                  }}
                  initialValues={{
                    userRole: formValues.roleId,
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: formValues.email,
                    phoneNumber: formValues.phoneNumber,
                    password: formValues.password,
                    acceptTerms: acceptTerms,
                  }}
                >
                  <Form.Item
                    className="super__select"
                    name="userRole"
                    label="Select User"
                    rules={[
                      { required: true, message: "User role is required" },
                    ]}
                    hasFeedback
                  >
                    <Select
                      showSearch
                      required
                      size="large"
                      className="w-full super__select text-sm font-normal text-dark"
                      placeholder="Vendor"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={roles
                        .filter(
                          (role) =>
                            role.name !== ADMIN_TYPE.SUB_ADMIN &&
                            role.name !== ADMIN_TYPE.LOGISTIC_SUB_ADMIN &&
                            role.name !== SUPER_ADMIN_TYPE
                        )
                        .map((role) => ({
                          value: role.id.toString(),
                          label: role.name,
                        }))}
                      onChange={(roleId) =>
                        setFormValues((prevFormValues) => ({
                          ...prevFormValues,
                          roleId: roleId ? parseInt(roleId, 10) : null,
                        }))
                      }
                    />
                  </Form.Item>
                  <Flex gap="middle">
                    <Form.Item
                      label="First Name"
                      name="First Name"
                      rules={[
                        { required: true, message: "First name is required" },
                        { min: 3 },
                      ]}
                      hasFeedback
                    >
                      <Input
                        size="large"
                        required
                        placeholder="Enter First Name"
                        className="super__select text-sm font-normal text-dark"
                        prefix={<></>}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="last name"
                      rules={[
                        { required: true, message: "Last name is required" },
                        { min: 3 },
                      ]}
                      hasFeedback
                    >
                      <Input
                        size="large"
                        required
                        placeholder="Enter Last Name"
                        className="super__select text-sm font-normal text-dark"
                        prefix={<></>}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Item>
                  </Flex>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      { required: true, message: "Email is required" },
                      {
                        type: "email",
                        message: "Email is not a valid email",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      required
                      placeholder="User@mail.com"
                      className="super__select text-sm font-normal text-dark"
                      prefix={<></>}
                      value={formValues.email}
                      onChange={(e) =>
                        setFormValues({ ...formValues, email: e.target.value })
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Enter Phone Number"
                    name="phone number"
                    rules={[
                      { required: true, message: "Phone number is required" },
                      { validator: validatePhoneNumber },
                    ]}
                    hasFeedback
                  >
                    <PhoneInput
                      country={countryCode}
                      value={formValues.phoneNumber}
                      onChange={(phone, country, e, formattedValue) => {
                        setFormValues({
                          ...formValues,
                          phoneNumber: formattedValue,
                        });
                        setCountryCode(country.countryCode);
                      }}
                      inputClass="super__select text-sm font-normal text-dark"
                      inputStyle={{ width: "100%", height: "56px" }}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    label="Enter Phone Number"
                    name="phone number"
                    rules={[
                      { required: true, message: "Phone number is required" },
                      { min: 11 },
                      { max: 11 },
                    ]}
                    hasFeedback
                  >
                    <Input
                      className="super__select text-sm font-normal text-dark"
                      size="large"
                      placeholder="Enter your phone number"
                      prefix={<></>}
                      value={formValues.phoneNumber}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Enter Password"
                    name="password"
                    rules={[
                      { required: true, message: "Password is required" },
                      { min: 6 },
                      { max: 25 },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      className="super__select text-sm font-normal text-dark"
                      size="large"
                      required
                      placeholder="*******"
                      prefix={<></>}
                      value={formValues.password}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          password: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm password"
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match"
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      className="super__select text-sm font-normal text-dark"
                      size="large"
                      required
                      placeholder="*******"
                      prefix={<></>}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassowrd(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    className="super__select"
                    style={{ fontWeight: "400" }}
                  >
                    <Form.Item
                      name="acceptTerms"
                      valuePropName="checked"
                      noStyle
                      rules={[
                        { required: true, message: "Accept terms is required" },
                      ]}
                    >
                      <Checkbox
                        className="text-dark super__select text-sm mr-2"
                        onChange={(e) => setAcceptTerms(true)}
                      ></Checkbox>
                      I accept
                      <Link
                        to="/"
                        className="text-primary underline mx-[3px] font-medium"
                      >
                        Terms of Use
                      </Link>
                      ,
                      <Link
                        to="/"
                        className="text-primary underline mx-[3px] font-medium"
                      >
                        Privacy Policy
                      </Link>
                      and
                      <Link
                        to="/"
                        className="text-primary underline mx-[3px] font-medium"
                      >
                        Cookie Policy
                      </Link>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Flex vertical style={{ width: "100%", marginTop: 20 }}>
                      <Spin spinning={loading}>
                        <Button
                          className="bg-primary border border-primary text-white hover:bg-white hover:text-primary rounded-xl !h-14 transition-all ease-out"
                          type="submit"
                          htmlType="submit"
                          block
                        >
                          Create Account
                        </Button>
                      </Spin>
                    </Flex>
                  </Form.Item>
                  <p className="lg:mt-1 text-sm text-primary font-normal leading-normal text-center">
                    Already have an account?
                    <Link
                      className="text-primary ml-1 font-medium underline"
                      to="/login"
                    >
                      Log in
                    </Link>
                  </p>
                </Form>
                <ul className="flex items-center justify-center lg:mt-8 max-lg:mt-5 space-x-9">
                  <li>
                    <Link className="text-sm text-dark font-normal leading-normal">
                      Terms and conditions
                    </Link>
                  </li>
                  <li className="list-disc">
                    <Link className="text-sm text-dark font-normal leading-normal">
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </div>
            </PerfectScrollbar>
          </Col>

          <Col className="bg-login-gradient lg:flex-1 lg:px-9 px-5 py-10 hidden lg:block">
            <div className="flex items-center justify-center w-full h-full">
              <Image
                preview={false}
                className="lg:max-w-sm"
                src="/assets/images/signup-page-pic.svg"
              />
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default SignUp;
