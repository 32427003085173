import React, { useState } from "react";
import {
  Layout,
  Space,
  Table,
  Dropdown,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Form,
  Avatar,
  Select,
  Menu,
  DatePicker,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import DeleteRequest from "./delete-request";
import ClientRequest from "./client-request";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { constructQueryString } from "../../utils/common-util";
import { useSelector } from "react-redux";

const { Search } = Input;
const { Text } = Typography;

const { RangePicker } = DatePicker;

const UserDropdown = ({ request, refetch }) => {
  const user = useSelector((state) => state?.user?.user);
  const isVendor = user?.role?.name === "VENDOR";

  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item key="0" className="dropdown__item__header" icon={Svgs.view}>
        <Link to={`/request/${request.id}`}>View Details</Link>
      </Menu.Item>
      {!isVendor ? (
        <Menu.Item key="1">
          <DeleteRequest request={request} refetch={refetch} />
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

const FilterDropdown = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});

  return (
    <Form
      name="login"
      size="large"
      layout="vertical"
      className="flex flex-col gap-3 bg-white rounded-lg border border-[#DBDBDB] p-3 w-full"
    >
      <Form.Item
        className="super__select filter-dropdown w-full"
        label="Status:"
      >
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Filter by Status"
          onChange={(value) => {
            setFilter({ ...filter, status: value });
          }}
          options={[
            {
              value: "pending",
              label: "Pending",
            },
            {
              value: "rejected",
              label: "Rejected",
            },
            {
              value: "hold",
              label: "Hold",
            },
            {
              value: "accepted",
              label: "Accepted",
            },
          ]}
        />
      </Form.Item>

      <Form.Item
        className="super__select filter-dropdown w-full request__range"
        label="Date:"
      >
        <RangePicker
          format={["YYYY-MM-DD", "YYYY-MM-DD"]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              format(dates[1], "MM-dd-yyyy, hh:mm:ss a");
              setFilter({
                ...filter,
                startDate: dates[0].toISOString(),
                endDate: dates[1].toISOString(),
              });
            }
          }}
        />
      </Form.Item>
      <Button
        className="bg-primary flex justify-center items-center w-full max-w-[100px] mx-auto py-5 text-base font-normal text-white rounded-lg mt-2"
        type=""
        onClick={() => {
          let queryParams = "";
          if (filter.startDate) {
            queryParams += `startDate=${filter.startDate}&endDate=${filter.endDate}`;
          }
          if (filter.status) {
            if (queryParams) queryParams += "&";
            queryParams += `status=${filter.status}`;
          }
          navigate(`/request?${queryParams}`);
        }}
      >
        Apply Filter
      </Button>
    </Form>
  );
};

function RequestCategory({
  requests,
  pagination,
  total,
  handleTableChange,
  refetch,
  paramsCount,
}) {
  const user = useSelector((state) => state?.user?.user);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isVendor = user?.role?.name === "VENDOR";
  const isClient = user?.role?.name === "CLIENT";
  const isAdmin = !isVendor && !isClient;
  const dataSource = requests.map((request) => {
    let status = request?.status;
    if (isVendor) {
      const isVendorSendProposal = request?.proposals?.find(
        (proposal) => proposal?.userId === user?.id
      );
      status = isVendorSendProposal ? "Proposal Submitted" : "Pending";
    }

    return {
      key: request.id,
      servicecategory: request?.id,
      serviceCategoryImage: request?.category?.iconURL,
      zipCode: request?.zipCode || "N/A",
      client: request?.user?.name || "Guest",
      description: request?.description || "N/A",
      datetime: format(request.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
      status: status,
      credit: request?.budget || "N/A",
      request,
      response: request?.proposals || [],
    };
  });

  const getColumns = () => {
    return [
      {
        title: "Request ID",
        dataIndex: "servicecategory",
        key: "0",
        render: (text, record) => (
          <Space className="flex gap-2 items-center">
            {/* <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src={record?.serviceCategoryImage}
            /> */}
            <span>{text}</span>{" "}
          </Space>
        ),
      },
      {
        title: "ZipCode",
        dataIndex: "zipCode",
        key: "0",
      },
      isAdmin
        ? {
            title: "Client",
            dataIndex: "client",
            key: "0",
            render: (text) => (
              <Space className="flex gap-2 items-center">
                <Avatar
                  className="rounded-full w-9 h-9 flex justify-center items-center"
                  size="large"
                  src="/assets/images/avatar-vendor.svg"
                />
                <span className="text-primary underline">{text}</span>{" "}
              </Space>
            ),
          }
        : null,
      {
        title: "Amount",
        dataIndex: "credit",
        key: "0",
      },
      {
        title: "Date & Time",
        dataIndex: "datetime",
        key: "0",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "0",
        render: (text, record) =>
          text === "accepted" || text === "Proposal Submitted" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                {text}
              </div>
            </Space>
          ) : (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#FFC107] py-[2px] px-2 rounded-2xl">
                {text}
              </div>
            </Space>
          ),
      },
      isClient
        ? {
            title: "Response By",
            dataIndex: "response",
            key: "0",
            render: (response) =>
              response?.length ? (
                <Space className="flex flex-wrap gap-2 items-center">
                  <span>{response[0]?.user?.name}</span>
                  {response?.length > 1 ? (
                    <div className="bg-primary py-[2px] px-2 text-white text-center text-xs font-normal rounded-2xl">
                      {response?.length - 1} More
                    </div>
                  ) : null}
                </Space>
              ) : (
                "N/A"
              ),
          }
        : null,
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={
                <UserDropdown request={record.request} refetch={refetch} />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ].filter(Boolean);
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Service Requests</span>,
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium ">
            All Requests
          </Text>
        </Col>
        <Col>
          <ClientRequest refetch={refetch} />
        </Col>
      </Row>
      <div className="flex gap-3 mb-5">
        <Search
          allowClear
          className="search-input-custom w-full max-w-[400px]"
          placeholder="Search Request"
          defaultValue={searchParams.get("search")}
          onSearch={(value) => {
            const queryString = constructQueryString(
              {
                search: value,
              },
              "search"
            );
            navigate(`/request${queryString}`);
          }}
        />
        <div className="flex gap-2">
          <Space size="middle" className="w-full">
            <Dropdown
              overlay={<FilterDropdown />}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg">
                {Svgs.filter} <span>Filter</span>
              </Space>
            </Dropdown>
            {paramsCount ? (
              <Space
                onClick={() => navigate(`/request`)}
                className="cursor-pointer bg-[#D7E5FF] flex justify-center items-center w-full !h-[50px] px-5 text-base font-normal text-primary rounded-lg"
              >
                <span>Clear</span>
              </Space>
            ) : null}
          </Space>
        </div>
      </div>
      <div>
        <Table
          className="service-table"
          columns={getColumns()}
          scroll={{ x: 400 }}
          dataSource={dataSource}
          pagination={{
            defaultCurrent: pagination.page,
            pageSize: pagination.pageSize,
            total: total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
}

export default RequestCategory;
