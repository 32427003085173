import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axios from "../config/Axios";

export const fetchFilteredTransactions = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };

  const response = await axios.get(`/payments`, { params: updatedParams });

  return response.data;
};

export const createCharge = async (adminData) => {
  const { data } = await axios.post("/payments", adminData);
  return data;
};

export const applyCoupon = async (params) => {
  const { data } = await axios.post("/coupons/apply-coupon", params);
  return data;
};

export const getAllVendorSubscriptions = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };
  const { data } = await axios.get("/subscriptions", { params: updatedParams });
  return data;
};

export const getAllVendorPurchases = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;
  const updatedParams = {
    ...params,
  };
  if (updatedParams.startDate) {
    const { startDate } = updatedParams;
    updatedParams.startDate = new Date(startDate).toISOString();
  }
  if (updatedParams.endDate) {
    const { endDate } = updatedParams;
    updatedParams.endDate = new Date(endDate).toISOString();
  }
  const { data } = await axios.get("/purchases", { params: updatedParams });
  return data;
};

export const subscribeService = async (params) => {
  const { data } = await axios.post("/subscriptions/subscribe", params);
  return data;
};

export const toggleSubscriptionActiveStatus = async (id) => {
  const response = await axios.patch(`/subscriptions/${id}/toggle-active`);
  return response.data;
};

export const deleteSubscription = async (id) => {
  await axios.delete(`/subscriptions/${id}`);
};

export const useToggleSubscriptionActiveStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(toggleSubscriptionActiveStatus, {
    onSuccess: (data, variables) => {
      message.destroy({ key: "updateStatus" });
      queryClient.invalidateQueries("GET_ALL_VENDOR_SUBSCRIPTION");
    },
    onError: (error) => {
      message.destroy({ key: "updateStatus" });
      toast.error(error?.response?.data?.message || "Failed to update status");
    },
  });
};

export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_ALL_VENDOR_SUBSCRIPTION");
    },
    onError: (error) => {
      toast.error(
        error?.response?.data?.message || "Failed to delete subscription"
      );
    },
  });
};
