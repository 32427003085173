import React, { useState } from "react";
import { useQuery } from "react-query";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestEmpty from "../components/Request/request-empty";
import RequestCategory from "../components/Request/request-category";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { fetchClientRequests } from "../service/request";
import { getUserId } from "../constants";
import Loader from "../components/loader/loader";
import { useSearchParams } from "react-router-dom";

export default function ServiceRequest() {
  useSetTabDetails("Service request");
  const [searchParams] = useSearchParams();
  const paramsCount = Array.from(searchParams.entries()).length;

  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const filters = {
    status: searchParams.get("status"),
    search: searchParams.get("search"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    userId: getUserId(),
  };

  const { data, isLoading, isError, refetch } = useQuery(
    [
      "CLIENT_REQUEST_FETCH",
      { ...filters, page: pagination.page, pageSize: pagination.pageSize },
    ],
    fetchClientRequests,
    {
      keepPreviousData: false,
      cacheTime: 0,
    }
  );

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <PerfectScrollbar style={{ height: "100vh" }}>
      <div className="h-full min-h-screen grid grid-columns">
        <SideBar />
        <div className="relative flex flex-col">
          <Header />
          <Content className="px-4 pt-28 pb-6">
            {(data && data.data.length > 0) || paramsCount ? (
              <RequestCategory
                total={data?.total}
                requests={data?.data || []}
                pagination={pagination}
                refetch={refetch}
                paramsCount={paramsCount}
                setPagination={setPagination}
                handleTableChange={handleTableChange}
              />
            ) : (
              <RequestEmpty refetch={refetch} />
            )}
            {/* <RespondedVendor /> */}
          </Content>
        </div>
      </div>
    </PerfectScrollbar>
  );
}
