import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import CategoryList from "../components/Service/service-category";
import ServiceEmpty from "../components/Service/service-empty";
import { SideBar } from "../components/SideBar";
import useSetTabDetails from "../hooks/use-set-tab-name";
import {
  getAllCategory,
  search_Categories,
} from "../store/category/categorySlice";

export default function Service() {
  const categories = useSelector((state) => state?.category?.category?.data);
  const total = useSelector((state) => state?.category?.category.total);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllCategory({ ...pagination }));
    dispatch(
      search_Categories({
        query: searchParams.get("search"),
        page: 1,
        pageSize: 10,
      })
    );
    // eslint-disable-next-line
  }, [dispatch, pagination]);

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
    });
  };
  useSetTabDetails('Services');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-32 pb-6">
            <Spin spinning={typeof categories === "undefined"}>
              {!categories?.length && !searchParams.get("search") ? (
                <ServiceEmpty
                  pagination={pagination}
                  setPagination={setPagination}
                />
              ) : (
                <CategoryList
                  total={total}
                  categories={categories || []}
                  pagination={pagination}
                  setPagination={setPagination}
                  handleTableChange={handleTableChange}
                />
              )}
            </Spin>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
