import { Breadcrumb, Button, Col, Image, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Svgs } from "../Svgs/svg-icons";

const { Text } = Typography;

const VendorLogEmpty = () => {
  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span >Logs</span>,
          },
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium">
            Logs
          </Text>
        </Col>
        <Col>
          <Button
            className="bg-[#9A9EA5] flex justify-center items-center w-full max-w-[180px] py-5 text-base font-normal text-white rounded-lg"
            type=""
            icon={Svgs.download}
          >
            Export as CSV
          </Button>
        </Col>
      </Row>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-logs-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mt-2 mb-2">
          No activity has been performed yet
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-full">
          This page will list all the activities performed on the system
        </Text>
      </section>
    </Content>
  );
};

export default VendorLogEmpty;
