import { Svgs } from "../Svgs/svg-icons";

export const menuItems = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: Svgs.dashboard,
    link: "/",
    className: "item__hover sideBarLinks",
    // requiredPermission: "manage_dashboard",
  },
  {
    label: "Management",
    key: "management",
    icon: Svgs.servicecategory,
    className: "item__hover sideBarLinks",
    requiredPermission: ["manage_services", "manage_areas"],
    children: [
      {
        label: "Service Category",
        key: "servicecategory",
        link: "/service",
        className: "item__hover sideBarLinks",
        icon: Svgs.ellipse,
        requiredPermission: "manage_services",
      },
      {
        label: "Area",
        key: "area",
        link: "/area",
        className: "item__hover sideBarLinks",
        icon: Svgs.ellipse,
        requiredPermission: "manage_areas",
      },
    ],
  },
  {
    label: "Discount Code",
    key: "discountcode",
    icon: Svgs.discountcode,
    link: "/coupons",
    className: "item__hover sideBarLinks",
    requiredPermission: "manage_discounts",
  },
  {
    label: "Users",
    key: "usermanagement",
    icon: Svgs.vendormanagement,
    className: "item__hover sideBarLinks",
    requiredPermission: "manage_users",
    children: [
      {
        label: "Vendors",
        key: "vendormanagement",
        link: "/vendor",
        className: "item__hover sideBarLinks",
        icon: Svgs.ellipse,
      },
      {
        label: "Clients",
        key: "clientmanagement",
        link: "/client",
        className: "item__hover sideBarLinks",
        icon: Svgs.ellipse,
      },
      {
        label: "Admins",
        key: "admins",
        link: "/admin",
        className: "item__hover sideBarLinks",
        icon: Svgs.ellipse,
        isSuperAdminOnly: true,
      },
    ],
  },
  // {
  //   label: "Credits",
  //   key: "credits",
  //   icon: Svgs.creditsdiscount,
  //   link: "/credit",
  //   className: "item__hover sideBarLinks",
  //   requiredPermission: "manage_credits",
  // },
  {
    label: "Logs",
    key: "logs",
    icon: Svgs.logs,
    link: "/log",
    className: "item__hover sideBarLinks",
    requiredPermission: "manage_logs",
  },
  {
    label: "Proposals",
    key: "proposals",
    icon: Svgs.proposals,
    requiredPermission: "manage_proposals",
    link: "/proposal",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Payment History",
    key: "paymenthistory",
    icon: Svgs.areamanagement,
    link: "/payment",
    requiredPermission: "manage_payments",
    className: "item__hover sideBarLinks",
  },
];

export const vendorMenuItems = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: Svgs.dashboard,
    link: "/",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Service Subscription",
    key: "servicesubscription",
    icon: Svgs.servicecategory,
    link: "/service-subscription",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Requests",
    key: "request",
    icon: Svgs.proposals,
    link: "/request",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Credits",
    key: "purchasecredits",
    icon: Svgs.creditsdiscount,
    link: "/credit",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Logs",
    key: "logs",
    icon: Svgs.logs,
    link: "/log",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Payment History",
    key: "paymenthistory",
    icon: Svgs.areamanagement,
    link: "/payment",
    className: "item__hover sideBarLinks",
  },
];

export const clientMenuItems = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: Svgs.dashboard,
    link: "/",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Requests",
    key: "request",
    icon: Svgs.proposals,
    link: "/request",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Logs",
    key: "logs",
    icon: Svgs.logs,
    link: "/log",
    className: "item__hover sideBarLinks",
  },
  {
    label: "Proposals",
    key: "proposals",
    icon: Svgs.proposals,
    requiredPermission: "manage_proposals",
    link: "/proposal",
    className: "item__hover sideBarLinks",
  },
];
