import React from "react";
import Chart from "react-apexcharts";

const Requests = ({ proposalStatsData }) => {
  const proposalStats = proposalStatsData?.proposalStats || [];
  const respondedProposalStats = proposalStatsData?.respondedProposalStats || [];

  const months = proposalStats.length ? proposalStats.map((item) => item.month) : [];
  const proposalCounts = proposalStats.length ? proposalStats.map((item) => item.count) : [];
  const respondedProposalCounts = respondedProposalStats.length ? respondedProposalStats.map((item) => item.count) : [];

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: months,
      labels: {
        style: {
          colors: "#A2A3A5",
          fontSize: "14px",
        },
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#A2A3A5",
          fontSize: "14px",
        },
      },
    },
    colors: ["#0B3B95", "#D8E6FF"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    tooltip: {
      x: {
        format: "MMM",
      },
    },
    legend: {
     fontSize: "14px",
     fontWeight: 400,
    },
  };

  const seriesData = [
    {
      name: "Proposals",
      data: proposalCounts,
    },
    {
      name: "Responded Proposals",
      data: respondedProposalCounts,
    },
  ];

  return (
    <div id="chart">
      <Chart
        options={chartOptions}
        series={seriesData}
        type="area"
        height={350}
      />
    </div>
  );
};

export default Requests;