import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Flex,
  Typography,
  Layout,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  addareaCategory,
  getAllAreaCategory,
} from "../../store/areaCategory/areaCategorySlice";

const { Text } = Typography;
const { Content } = Layout;

const AddArea = ({ setFieldValue = () => {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const createdByID = parseInt(localStorage.getItem("userId"), 10);

  const [formValues, setFormValues] = useState({
    areaCategoryName: "",
    createdBy: Number(createdByID),
    updatedBy: Number(createdByID),
  });
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(addareaCategory(formValues));
      if (resultAction.payload) {
        await dispatch(getAllAreaCategory());
        await resultAction.meta.requestPayload;
        setFieldValue("areaCategoryId", resultAction?.payload?.id);
        toast.success("Category Created Successfully!");
        setIsModalOpen(false);
      } else if (resultAction.error.message) {
        toast.error(resultAction.error.message || "something went wrong");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Link
        onClick={showModal}
        className="text-primary hover:text-primary !underline text-base flex justify-end !py-0"
        type=""
      >
        + Add new Category
      </Link>
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center">
            Add New Area Category
          </Text>
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-sm font-normal text-[#303030] text-center mb-2">
              Please add new area category, so that in future it would be easy
              to categorise many areas in which you want to provide services.
            </Text>
          </div>
          <Form
            name="login"
            size="large"
            layout="vertical"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "15px auto 0 auto",
              fontWeight: "500",
              fontSize: "14px",
            }}
            className="flex flex-col gap-3"
          >
            <Form.Item
              label="Name:"
              hasFeedback
              rules={[
                { required: true, message: '"Name" should not be empty' },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter area Name"
                className="super__select text-sm font-normal text-dark h-14"
                value={formValues.areaCategoryName}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    areaCategoryName: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item>
              <Flex
                className="w-full flex justify-center items-center area-btn"
                vertical
                style={{ marginTop: 20 }}
              >
                <Spin className="w-full flex" spinning={loading}>
                  <Button
                    className="bg-primary border border-primary text-white rounded-xl !h-14 transition-all ease-out w-full max-w-[320px] mx-auto"
                    htmlType="submit"
                    block
                    disabled={!formValues.areaCategoryName}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Spin>
              </Flex>
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </div>
  );
};

export default AddArea;
