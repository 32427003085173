import { Button, Flex, Image, Layout, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { delete_Area, getAllArea } from "../../store/area/areaSlice";
import { Svgs } from "../Svgs/svg-icons";
const { Text } = Typography;
const { Content } = Layout;

const DeleteArea = (data) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      let id = data.data.id;
      const resultAction = await dispatch(delete_Area(id));
      if (resultAction && resultAction.payload) {
        toast.success("Area deleted successfully");
        await dispatch(getAllArea());
        setIsModalOpen(false);
      } else if (resultAction.error) {
        toast.error(resultAction.error.message || "something went wrong");
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <div>
      <div onClick={showModal} className="flex gap-2 text-[#D83A52]">
        {Svgs.delete}
        Delete
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Image
            preview={false}
            className="max-w-[140px] mx-auto"
            src="/assets/images/trash.svg"
          />
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
              Are you sure you want to delete{" "}
              <span className="font-bold">{data.data.name}</span>?
            </Text>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Button
              className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
              type="submit"
              onClick={handleSubmit}
            >
              Delete
            </Button>
            <Button
              className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
              type="submit"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Flex>
        </Content>
      </Modal>
    </div>
  );
};

export default DeleteArea;
