import { createSlice, createAction } from "@reduxjs/toolkit";


const initialState = {
  tabDetails: {},
};

export const addTabDetails = createAction("tabDetails/addTabDetails");

const tabDetailsSlice = createSlice({
    name: "tabDetails",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder.addCase(addTabDetails, (state, action) => {
        state.tabDetails = action.payload;
      });
    },
  });

export default tabDetailsSlice.reducer;
