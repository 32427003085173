import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import VendorCategory from "../components/Vendor/vendor-category";
import VendorDetail from "../components/Vendor/vendor-detail";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { fetchUserById } from "../service/request";
import { getAllArea } from "../store/area/areaSlice";
import { getAllCategory } from "../store/category/categorySlice";
import { getAllVendors } from "../store/vendors/vendorsSlice";

export default function Vendor() {
  const data = useSelector((state) => state.vendor.vendors);
  const categories = useSelector((state) => state?.category?.category?.data);
  const allArea = useSelector((state) => state.area.area);

  const params = useParams();

  const detailRecord = params.vendorId;

  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery(
    ["categories", { activeOnly: true }],
    () => fetchUserById(detailRecord),
    {
      enabled: !!detailRecord,
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVendors());
    dispatch(getAllCategory());
    dispatch(getAllArea());
    // eslint-disable-next-line
  }, []);
  useSetTabDetails("Vendors");

  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            {!detailRecord ? (
              <VendorCategory
                data={data}
                categories={categories || []}
                allArea={allArea}
              />
            ) : (
              <Spin spinning={isLoading}>
                <VendorDetail
                  data={user || {}}
                  categories={categories}
                  allArea={allArea}
                  refetch={refetch}
                />
              </Spin>
            )}
            {/* <ProposalDetail /> */}
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
