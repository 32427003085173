import React, { useState } from "react";
import { Button, Modal, Typography, Layout, Flex, Image } from "antd";
import { Svgs } from "../Svgs/svg-icons";
import { useDeleteSubscription } from "../../service/transaction";
import { toast } from "react-toastify";

const { Text } = Typography;
const { Content } = Layout;

const DeleteSusbcription = ({ subscription }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: deleteSub, isLoading } = useDeleteSubscription();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div onClick={showModal} className="flex gap-2 text-[#D83A52]">
        {Svgs.delete}
        Delete
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Content className="flex flex-col">
          <Image
            preview={false}
            className="max-w-[140px] mx-auto"
            src="/assets/images/trash.svg"
          />
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-base font-normal text-[#2c2c2e] text-center mb-2 max-w-[320px]">
              Are you sure you want to delete{" "}
              <span className="font-bold">{subscription?.serviceId?.name}</span>
              ?
            </Text>
          </div>
          <Flex gap={16} className="mt-6 justify-center">
            <Button
              className="bg-primary border border-primary flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-white rounded-md"
              type="primary"
              disabled={isLoading}
              onClick={() => {
                deleteSub(subscription.id);
                toast.success("Subscription status updated successfully");
                setIsModalOpen(false);
              }}
            >
              Delete
            </Button>
            <Button
              className="bg-[#D9D9D9] border border-[#D9D9D9] flex justify-center items-center w-full max-w-[110px] py-5 text-sm font-normal text-[#2c2c2e] rounded-md"
              type="primary"
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Flex>
        </Content>
      </Modal>
    </div>
  );
};

export default DeleteSusbcription;
