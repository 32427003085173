import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import { SideBar } from "../components/SideBar";
import VendorLogCategory from "../components/VendorLog/vendor-log-category";
import VendorLogEmpty from "../components/VendorLog/vendor-log-empty";
import { getUserRole } from "../constants";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { useFetchLogs } from "../service/logs";
import { createFilterQueryString } from "../utils/common-util";

export default function VendorLog() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state?.user?.user);
  const [appliedFilter, setSearchFilters] = useState({});
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const isAdmin = getUserRole(user, "admin");

  const { data, isLoading } = useFetchLogs({
    page: pagination.page,
    limit: pagination.limit,
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    userId: !isAdmin ? user?.id : null,
    query: searchParams.get("query"),
  });

  const activityLogs = data?.data;
  const total = data?.total;
  const queryParams =
    searchParams.get("startDate") || searchParams.get("query");

  useEffect(() => {
    const createdSearch = createFilterQueryString(appliedFilter);
    if (
      window.location.search !== `?${createdSearch}` &&
      Object.keys(appliedFilter).length > 0
    ) {
      navigate(`/log?${createdSearch}`);
    }
  }, [appliedFilter, navigate]);

  const handleTableChange = (paginationInfo) => {
    setPagination({
      page: paginationInfo.current,
      limit: paginationInfo.pageSize,
    });
  };
  useSetTabDetails('Vendor Logs');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Spin spinning={isLoading}>
            <Content className="px-4 pt-28 pb-6">
              {!activityLogs?.length && !queryParams ? (
                <VendorLogEmpty />
              ) : (
                <VendorLogCategory
                  total={total}
                  pagination={pagination}
                  queryParams={queryParams}
                  setSearchFilters={setSearchFilters}
                  activityLogs={activityLogs || []}
                  handleTableChange={handleTableChange}
                />
              )}
            </Content>
          </Spin>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
