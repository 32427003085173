import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createRole,
  deleteRole,
  fetchRoles,
  updateRole,
} from "../../service/rollService";

const initialState = {
  roles: [],
};

export const getAllRoles = createAsyncThunk("roles/getAllRoles", async () => {
  const response = await fetchRoles();
  return response.data;
});

export const addRole = createAsyncThunk("roles/addrole", async (roleData) => {
  const response = await createRole(roleData);
  return response.data;
});

export const update_Role = createAsyncThunk(
  "roles/updateRole",
  async (roleData) => {
    const response = await updateRole(roleData);
    return response.data;
  }
);

export const delete_Role = createAsyncThunk(
  "role/deleteRole",
  async (roleId) => {
    await deleteRole(roleId);
    return roleId;
  }
);

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        state.roles.push(action.payload);
      })
      .addCase(update_Role.fulfilled, (state, action) => {
        const updatedRole = action.payload;
        const index = state.roles.findIndex(
          (role) => role.id === updatedRole.id
        );
        state.roles[index] = updatedRole;
      })
      .addCase(delete_Role.fulfilled, (state, action) => {
        const roleId = action.payload;
        state.roles = state.roles.filter((role) => role.id !== roleId);
      });
  },
});

export default roleSlice.reducer;
