import React from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import ProposalResponded from "../components/Proposal/proposal-responded";
import useSetTabDetails from "../hooks/use-set-tab-name";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchClientProposalsById } from "../service/request";
import Loader from "../components/loader/loader";

export default function ProposalDetailPage() {
  useSetTabDetails("Proposal management");
  const params = useParams();

  const {
    data: proposal,
    isLoading,
    error,
    refetch,
  } = useQuery(["PROPOSAL_DETAILS", params.proposalId], () =>
    fetchClientProposalsById(params.proposalId)
  );

  if (isLoading) return <Loader />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <ProposalResponded proposal={proposal} refetch={refetch} />
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
