import {
  Avatar,
  Breadcrumb,
  Col,
  Dropdown,
  Menu,
  Pagination,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";
import { Svgs } from "../Svgs/svg-icons";
import ServiceSubscriptionAdd from "./add-subscription";
import DeleteSusbcription from "./delete-subscription";
import PauesSubscription from "./pause-subscription";

const { Text } = Typography;

const UserDropdown = ({ subscription }) => {
  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item key="0">
        <PauesSubscription subscription={subscription} />
      </Menu.Item>
      <Menu.Item key="1">
        <DeleteSusbcription subscription={subscription} />
      </Menu.Item>
    </Menu>
  );
};

const AllSubscription = ({
  subscriptions,
  pagination,
  handleTableChange,
  total,
  refetch,
}) => {
  return (
    <>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Service Subscription</span>,
          }
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center gap-3">
            Service Subscription
          </Text>
        </Col>
        <Col>
          <ServiceSubscriptionAdd refetch={refetch} />
        </Col>
      </Row>
      <Col span={24} className="bg-white card__shadow lg:p-5 p-3 rounded-lg">
        <Text className="md:text-xl text-lg font-medium text-gray mb-3">
          Subscriptions:
        </Text>
        <div>
          <div className="grid xl:grid-cols-3 gap-4 mt-5">
            {subscriptions.map((subscription) => {
              return (
                <div className="bg-[#fbfbfb] border border-[#DEDEDE] rounded pt-2 px-2 pb-10 relative">
                  <div className="flex items-center gap-2">
                    <Avatar
                      className="w-9 h-9 rounded-full"
                      src={subscription?.serviceId?.iconURL || "/assets/images/avatar.svg"}
                    />
                    <Text className="text-base text-primary font-medium">
                      {subscription?.serviceId?.name}
                    </Text>
                  </div>
                    <div className="mt-2 flex flex-col gap-2">
                        <div className="flex items-center gap-1 text-xs">
                      <span className="font-medium text-gray">
                        Area Category:
                      </span>
                            <span className="font-normal text-[#888888]">
                        {subscription?.areas
                            ?.map((area) => area?.areaCategory?.areaCategoryName)
                            .join(", ")}
                      </span>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                            <span className="font-medium text-gray">Area Name:</span>
                            <span className="font-normal text-[#888888]">
                        {subscription?.areas
                            ?.map((item) => item.name)
                            .join(", ")}
                      </span>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                            <span className="font-medium text-gray">Zip code:</span>
                            <span className="font-normal text-[#888888]">
                        {subscription?.areas
                            ?.flatMap((area) =>
                                area.zipCodes.map((zip) => zip.zipCode)
                            )
                            .join(", ")}
                      </span>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                            <span className="font-medium text-gray">Status:</span>
                            <span className="font-normal text-[#888888]">
                        {subscription?.isActive ? 'Active' : 'Pause'}
                      </span>
                        </div>
                        <div className="flex gap-1 text-xs">
                            <span className="font-medium text-gray">Description:</span>
                            <span className="font-normal text-[#888888]">
                                {subscription?.serviceId?.description}
                            </span>
                        </div>
                    </div>
                    <div
                        className="bg-[#DDDFE3] p-2 text-primary text-xs absolute bottom-0 w-full left-0 rounded-bl rounded-br">
                        <div className="flex items-center gap-1">
                            <span className="font-medium">Daily Credit:</span>
                            <span className="font-normal" style={{display:'flex', justifyContent:'space-between', width: '80%'}}>
                          <span>
                                {Number(subscription?.dailyCredit || 0)?.toFixed(0)}
                          </span>
                          <span>
                          {subscription?.dailyCredit / 100} USD
                          </span>
                      </span>
                    </div>
                  </div>
                  <Space size="middle" className="absolute top-2 right-2">
                    <Dropdown
                      overlay={<UserDropdown subscription={subscription} />}
                      placement="bottomRight"
                      trigger={["click"]}
                      className="cursor-pointer"
                    >
                      <Space className="gap-3 items-center">{Svgs.dots}</Space>
                    </Dropdown>
                  </Space>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-10">
            <Pagination
              defaultCurrent={pagination.page}
              pageSize={pagination.pageSize}
              total={total}
              onChange={(e) =>
                handleTableChange({
                  page: e,
                  pageSize: pagination.pageSize,
                })
              }
            />
          </div>
        </div>
      </Col>
    </>
  );
};

export default AllSubscription;
