import React, { useEffect } from "react";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../../../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import AreaForm from "../../../components/Area/area-form";
import formValidationSchema from "../../../components/Area/validation";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAllArea,
  getAreaById,
  update_Area,
} from "../../../store/area/areaSlice";
import { getUserId } from "../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import useSetTabDetails from "../../../hooks/use-set-tab-name";

export const formatAreaById = (area) => {
  return {
    ...area,
    zipCodes: area?.zipCodes?.map(code => code.zipCode)
  }
}

export default function CreateArea() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const areaById = useSelector((state) => state?.area?.areaById);

  useEffect(() => {
    dispatch(getAreaById(params.areaId));
  }, [dispatch, params]);

  const handleSubmit = async (values) => {
    try {
      message.loading({ content: "Area Updating..." });

      const dto = {
        ...values,
        zipCodes: values.zipCodesEntries,
        createdBy: getUserId(),
        updatedBy: getUserId(),
      };
      await dispatch(update_Area(dto));
      toast.success("Area Updated Successfully!");
      await dispatch(getAllArea());
      navigate("/area");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      message.destroy();
    }
  };
  useSetTabDetails('Details Area');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <Formik
              enableReinitialize={true}
              initialValues={{
                FORM_MODE: "UPDATE",
                manualCredit: areaById?.isManualCredit ? areaById?.creditValue : null,
                name: "",
                description: "",
                zipCodesEntries: formatAreaById(areaById || []).zipCodes,
                zipCode: "",
                creditValue: 0,
                areaCategoryId: null,
                ...formatAreaById(areaById),
              }}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}
            >
              {(formikProps) => {
                return <AreaForm {...formikProps} />;
              }}
            </Formik>
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
