import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../config/Axios";

import {
  fetchCoupons,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  searchCoupons,
} from "../../service/couponService";

const initialState = {
  vendors: [],
  total: 0,
  couponById: null,
  coupons: []
};

export const getAllCoupons = createAsyncThunk(
  "coupons/getAllCoupons",
  async ({ userId, page, pageSize }) => {
    const response = await fetchCoupons(userId, page, pageSize);
    return response.data;
  }
);

export const addCoupon = createAsyncThunk(
  "coupons/addCoupon",
  async (couponData, { rejectWithValue }) => {
    try {
      const response = await createCoupon(couponData);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateCouponAction = createAsyncThunk(
  "coupons/updateCoupon",
  async ({ couponCode, updateData }, { rejectWithValue }) => {
    try {
      const response = await updateCoupon(couponCode, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCouponAction = createAsyncThunk(
  "coupons/deleteCoupon",
  async ({ userId, couponCode }, { rejectWithValue }) => {
    try {
      const response = await deleteCoupon(userId, couponCode);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const search_Coupons = createAsyncThunk(
  "coupons/searchCoupons",
  async ({ query, page, pageSize }) => {
    const response = await searchCoupons(query, page, pageSize);
    return response.data;
  }
);

export const updateCouponStatus = createAsyncThunk(
  "coupons/updateStatus",
  async ({ couponCode, isActive, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/coupons/${couponCode}/activate`, {
        isActive,
        userId,
      });
      return { ...response.data, couponCode, isActive };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const duplicateCoupon = createAsyncThunk(
  "coupons/duplicateCoupon",
  async ({ couponCode, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/coupons/${couponCode}/duplicate`, {
        userId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateCouponCode = createAsyncThunk(
  "coupons/validateCouponCode",
  async (couponCode, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/coupons/validate-id/${couponCode}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendCouponCodeToVendors = createAsyncThunk(
  "coupons/sendCouponCodeToVendors",
  async (
    { emails, couponCode, userId, customMessage },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/coupons/send-coupon-code", {
        emails,
        couponCode,
        userId,
        customMessage,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findCouponById = createAsyncThunk(
  "coupons/findCouponById",
  async ({ couponCode, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/coupons/${couponCode}`, {
        params: { userId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsersByRole = createAsyncThunk(
  "users/fetchByRole",
  async (roleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/users/roles/${roleId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(search_Coupons.fulfilled, (state, action) => {
        state.coupons = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(addCoupon.fulfilled, (state, action) => {
        state.coupons.push(action.payload);
      })
      .addCase(updateCouponAction.fulfilled, (state, action) => {
        const updatedCoupon = action.payload;
        const index = state.coupons.findIndex(
          (coupon) => coupon.id === updatedCoupon.id
        );
        if (index !== -1) {
          state.coupons[index] = updatedCoupon;
        }
      })
      .addCase(deleteCouponAction.fulfilled, (state, action) => {
        const couponCode = action.payload;
        state.coupons = state.coupons.filter(
          (coupon) => coupon.couponCode !== couponCode
        );
      })
      .addCase(updateCouponStatus.fulfilled, (state, action) => {
        const { couponCode, isActive } = action.payload;
        const index = state.coupons.findIndex(
          (coupon) => coupon.couponCode === couponCode
        );
        if (index !== -1) {
          state.coupons[index].isActive = isActive;
        }
      })
      .addCase(duplicateCoupon.fulfilled, (state, action) => {
        state.coupons.push(action.payload);
      })
      .addCase(fetchUsersByRole.fulfilled, (state, action) => {
        state.vendors = action.payload;
      })
      .addCase(findCouponById.fulfilled, (state, action) => {
        state.couponById = action.payload;
      });
  },
});

export default couponSlice.reducer;
