import React from "react";
import { Col, Avatar, Typography } from "antd";

const { Text } = Typography;

const VendorSubscription = ({ subscriptions }) => {
  if (!subscriptions?.length) return null;
  return (
    <Col span={24} className="bg-white card__shadow lg:p-5 p-3 rounded-lg">
      <Text className="md:text-xl text-lg font-medium text-gray mb-3">
        Availed Services:
      </Text>
      <div>
        <div className="grid xl:grid-cols-3 gap-4 mt-5">
          {subscriptions?.map((subscription) => {
            return (
              <div className="bg-[#fbfbfb] border border-[#DEDEDE] rounded pt-2 px-2 pb-10 relative">
                <div className="flex items-center gap-2">
                  <Avatar
                    className="w-9 h-9 rounded-full"
                    src={
                      subscription?.serviceId?.iconURL ||
                      "/assets/images/avatar.svg"
                    }
                  />
                  <Text className="text-base text-primary font-medium">
                    {subscription?.serviceId?.name}
                  </Text>
                </div>
                <div className="mt-2 flex flex-col gap-2">
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">Area Name: </span>
                    {subscription?.areas?.map((area) => (
                      <span className="font-normal text-[#888888]">
                        {area?.name}
                      </span>
                    ))}
                  </div>
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">Zip code:</span>

                    {subscription?.areas
                      ?.flatMap((area) => area.zipCodes)
                      .map(({ zipCode }) => (
                        <span className="font-normal text-[#888888]">
                          {zipCode},
                        </span>
                      ))}
                  </div>
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">
                      Area Category:
                    </span>
                    <span className="font-normal text-[#888888]">
                      {subscription?.areas
                        ?.flatMap((area) => area?.areaCategory)
                        ?.map((cat) => cat?.name)?.length
                        ? subscription?.areas
                            ?.flatMap((area) => area?.areaCategory)
                            ?.map((cat) => cat?.areaCategoryName)
                            ?.join(", ")
                        : "N/A"}
                    </span>
                  </div>
                  <div className="flex items-center gap-1 text-xs">
                    <span className="font-medium text-gray">Status: </span>
                    <span className="font-normal text-[#888888]">
                      {subscription?.isActive ? "Active" : "Pause"}
                    </span>
                  </div>
                </div>
                <div className="bg-[#DDDFE3] p-2 text-primary text-xs absolute bottom-0 w-full left-0 rounded-bl rounded-br">
                  <div className="flex items-center gap-1">
                    <span className="font-medium">Vendor Name:</span>
                    <span className="font-normal">
                      {subscription?.user?.name}
                    </span>
                  </div>
                </div>
                {/* <Space size="middle" className="absolute top-2 right-2">
                  <Dropdown
                    overlay={<UserDropdown proposal={proposal} />}
                    placement="bottomRight"
                    trigger={["click"]}
                    className="cursor-pointer"
                  >
                    <Space className="gap-3 items-center">{Svgs.dots}</Space>
                  </Dropdown>
                </Space> */}
              </div>
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default VendorSubscription;
