import React from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import PurchaseCredits from "../components/PurchaseCredit/purchase-credit";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function PurchaseCredit() {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const hasSearchParams = () => {
    return Array.from(search.keys()).length > 0;
  };
  if (!hasSearchParams()) {
    navigate("/purchase-credit?billing-details=1");
  }
  useSetTabDetails('Purchase credit');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <PurchaseCredits />
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
