import React, { useState } from "react";
import Header from "../components/Header";
import { Content } from "antd/es/layout/layout";
import { SideBar } from "../components/SideBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import ServiceSubscriptionEmpty from "../components/ServiceSubscription/service-subscription-empty";
import AllSubscription from "../components/ServiceSubscription/all-subscription";
import { getAllVendorSubscriptions } from "../service/transaction";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function ServiceSubscription() {
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 12 });

  const filters = {
    query: searchParams.get("query"),
    amount: searchParams.get("amount"),
    credits: searchParams.get("credits"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
  };

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      "GET_ALL_VENDOR_SUBSCRIPTION",
      { ...filters, page: pagination.page, pageSize: pagination.pageSize },
    ],
    getAllVendorSubscriptions
  );

  const subscriptions = data?.data;
  const total = data?.total;
  useSetTabDetails('Service subscriptions');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Spin spinning={isLoading || isFetching}>
            <Content className="px-4 pt-28 pb-6">
              {!subscriptions?.length ? (
                <ServiceSubscriptionEmpty refetch={refetch} />
              ) : null}
              {subscriptions?.length ? (
                <AllSubscription
                  total={total}
                  refetch={refetch}
                  pagination={pagination}
                  setPagination={setPagination}
                  subscriptions={subscriptions || []}
                  handleTableChange={setPagination}
                />
              ) : null}
            </Content>
          </Spin>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
