import React from "react";
import { Typography, Form, Input, Flex, Button, Checkbox } from "antd";
import { Content } from "antd/es/layout/layout";
import { Svgs } from "../Svgs/svg-icons";
import { UserOutlined } from "@ant-design/icons";
import { getFormItemValidationProps } from "../../utils/form-util";
import { Link, useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { paymentValidationSchema } from "./validations";
import { toast } from "react-toastify";
const { Text } = Typography;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PurchasePayment = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const paymentInfo = localStorage.getItem("paymentInfo");
  const formInfo = JSON.parse(paymentInfo);

  const user = useSelector((state) => state?.user?.user);

  const getDefaultFormValue = () => {
    const values = {};
    const billingInfo = formInfo?.billingInfo;
    if (billingInfo?.name) {
      const [fname, lname] = formInfo?.billingInfo?.name?.split(" ");
      values.fname = fname;
      values.lname = lname;
    }

    values.email = billingInfo?.email;
    values.amount = formInfo?.amount;
    values.couponCode = formInfo?.couponCode;
    values.phone = billingInfo?.phone;
    values.country = billingInfo?.address?.country;
    values.postalCode = billingInfo?.address?.postalCode;
    values.term = formInfo.term;
    return values;
  };
  return (
    <Content>
      <div className="flex gap-16 justify-center items-center mb-10">
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.details}
          </div>
          <Text className="text-sm text-primary font-semibold">Details</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative z-10">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.payment}
          </div>
          <Text className="text-sm text-primary font-semibold">Payment</Text>
          <div className="flex gap-1 items-center absolute top-4 left-[46px]">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative z-10">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.review}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Review</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.thankyou}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Thank You</Text>
        </div>
      </div>
      <div className="bg-[#f5f5f5] rounded-lg lg:p-5 p-3">
        <div className="flex justify-between items-center">
          <Text className="md:text-2xl text-xl font-medium text-gray">
            Add Payment Details:
          </Text>
          {/* <div className="flex flex-wrap gap-3 items-center">
            {Svgs.card}
            {Svgs.amazon}
            {Svgs.paypal}
            {Svgs.visa}
          </div> */}
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            stripeToken: formInfo?.stripeToken,
            cardNumber: "",
            fname: "",
            lname: "",
            term: null,
            ...getDefaultFormValue(),
          }}
          validate={paymentValidationSchema}
          onSubmit={async (values) => {
            const updatePaymentInfo = (token, paymentInfo) => {
              return {
                ...paymentInfo,
                stripeToken: token?.id,
                useExistingPaymentMethod: values.useExistingPaymentMethod,
                savePaymentMethod: values.savePaymentMethod,
                exp_month: token?.card?.exp_month || paymentInfo?.exp_month,
                exp_year: token?.card?.exp_year || paymentInfo?.exp_year,
                last4: token?.card?.last4 || paymentInfo?.last4,
                billingInfo: {
                  ...paymentInfo.billingInfo,
                  name: `${values.fname} ${values.lname}`,
                },
              };
            };

            const proceedToNextPage = () => {
              navigate("/purchase-credit?review-details=1");
            };

            let paymentInfo =
              JSON.parse(localStorage.getItem("paymentInfo")) || {};

            if (!values.useExistingPaymentMethod) {
              if (!stripe || !elements) {
                toast.error("Stripe has not initialized.");
                return;
              }
              const cardElement = elements.getElement(CardElement);
              const { error, token } = await stripe.createToken(cardElement);
              if (error) {
                toast.error(error.message);
                return;
              }
              paymentInfo = updatePaymentInfo(token, paymentInfo);
            } else {
              paymentInfo = updatePaymentInfo(null, paymentInfo);
            }
            localStorage.setItem("paymentInfo", JSON.stringify(paymentInfo));
            proceedToNextPage();
          }}
        >
          {(prop) => {
            return (
              <div className="mt-4">
                {!prop.values.useExistingPaymentMethod && (
                  <React.Fragment>
                    <Form name="" size="large" layout="vertical">
                      <Field name="cardNumber">
                        {({ field, form }) => (
                          <Form.Item
                            label="Card Number:"
                            required
                            {...getFormItemValidationProps(field, form)}
                          >
                            <CardElement
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={(e) =>
                                form.setFieldValue(field.name, !e.empty)
                              }
                              className="stripe-card-element"
                            />
                          </Form.Item>
                        )}
                      </Field>
                    </Form>
                    <div className="grid lg:grid-cols-2 gap-5 mt-5 mb-4">
                      <Form name="" size="large" layout="vertical">
                        <Field name="fname">
                          {({ field, form }) => (
                            <Form.Item
                              label="First Name:"
                              required
                              {...getFormItemValidationProps(field, form)}
                            >
                              <Input
                                {...field}
                                size="large"
                                required
                                placeholder="Adam"
                                className="super__select text-sm font-normal text-dark"
                                prefix={<UserOutlined className="text-gray" />}
                              />
                            </Form.Item>
                          )}
                        </Field>
                      </Form>
                      <Form name="" size="large" layout="vertical">
                        <Field name="lname">
                          {({ field, form }) => (
                            <Form.Item
                              label="Last Name"
                              required
                              {...getFormItemValidationProps(field, form)}
                            >
                              <Input
                                {...field}
                                size="large"
                                required
                                placeholder="Synder"
                                className="super__select text-sm font-normal text-dark"
                                prefix={<UserOutlined className="text-gray" />}
                              />
                            </Form.Item>
                          )}
                        </Field>
                      </Form>
                    </div>
                  </React.Fragment>
                )}
                <Form.Item
                  className="super__select font-medium"
                  style={{ marginTop: 5 }}
                >
                  {user.stripeCustomerId && (
                    <Field name="useExistingPaymentMethod">
                      {({ field }) => (
                        <Form.Item>
                          <Checkbox {...field} noStyle>
                            Pay with existing card
                          </Checkbox>
                        </Form.Item>
                      )}
                    </Field>
                  )}
                </Form.Item>
                <Form.Item
                  className="super__select font-normal"
                  style={{ marginTop: 5 }}
                >
                  <Field name="term">
                    {({ field, form }) => (
                      <Form.Item
                        required
                        {...getFormItemValidationProps(field, form)}
                      >
                        <Checkbox
                          {...field}
                          defaultChecked={field.value}
                          className="text-[#303030] super__select text-sm mr-2"
                        >
                          I agree to Seek My Service's{" "}
                          <Link className="underline !font-medium">
                            Terms of use
                          </Link>{" "}
                          and{" "}
                          <Link className="underline !font-medium">
                            Privacy policy
                          </Link>{" "}
                          .{" "}
                        </Checkbox>
                      </Form.Item>
                    )}
                  </Field>
                </Form.Item>
                <Flex className="justify-center gap-5 my-5">
                  <Button
                    className="bg-[#9A9EA5] flex justify-center items-center w-full max-w-[300px] py-6 text-base font-normal text-white rounded-lg"
                    type=""
                    onClick={() =>
                      navigate("/purchase-credit?billing-details=1")
                    }
                  >
                    Back
                  </Button>
                  <Button
                    className="bg-primary flex justify-center items-center w-full max-w-[300px] py-6 text-base font-normal text-white rounded-lg"
                    type=""
                    onClick={prop.handleSubmit}
                    disabled={prop.isSubmitting}
                  >
                    Purchase
                  </Button>
                </Flex>
              </div>
            );
          }}
        </Formik>
      </div>
    </Content>
  );
};

export default PurchasePayment;
