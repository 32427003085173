import { Content } from "antd/es/layout/layout";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Header from "../components/Header";
import ProposalDetail from "../components/Proposal/proposal-detail";
import ProposalEmpty from "../components/Proposal/proposal-empty";
import ProposalResponded from "../components/Proposal/proposal-responded";
import { SideBar } from "../components/SideBar";
import useSetTabDetails from "../hooks/use-set-tab-name";

export default function ProposalManagement() {
  useSetTabDetails('Proposal management');
  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <ProposalEmpty />
            {/* <ProposalCategory /> */}
            <ProposalDetail />
            <ProposalResponded />
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
