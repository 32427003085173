import React, { useEffect } from "react";
import _pick from "lodash.pick";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SideBar } from "../../../components/SideBar";
import { Content } from "antd/es/layout/layout";
import Header from "../../../components/Header";
import DiscountForm from "../../../components/Discount/discount-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  findCouponById,
  updateCouponAction,
} from "../../../store/coupons/couponSlice";
import { Empty, message } from "antd";
import { toast } from "react-toastify";
import { getUserId } from "../../../constants";
import useSetTabDetails from "../../../hooks/use-set-tab-name";

const CouponUpdate = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const couponCode = params.couponCode;

  const coupon = useSelector((state) => state.coupons?.couponById);

  const handleSubmit = async (values) => {
    message.loading({ content: "Updating coupon..." });
    try {
      const updateData = { ...values };

      if (updateData.couponType === "Fixed") {
        updateData.discountValue = Number(updateData.discountValue);
        updateData.discountPercentage = null;
      } else {
        updateData.discountPercentage = Number(updateData.discountPercentage);
        updateData.discountValue = null;
      }
      updateData.userId = getUserId();

      if (!updateData.isCouponHasLimit) {
        updateData.redemptionLimit = null;
      }

      if (!updateData.isCouponHasDateLimit) {
        updateData.startDate = null;
        updateData.expDate = null;
      }

      const couponData = _pick(updateData, [
        "couponName",
        "couponType",
        "userId",
        "isActive",
        "discountPercentage",
        "discountValue",
        "isCouponIdAutoGenerated",
        "discountPercentage",
        "startDate",
        "expDate",
        "redemptionLimit",
        "couponCode",
      ]);

      const result = await dispatch(
        updateCouponAction({ couponCode, updateData: couponData })
      ).unwrap();
      message.destroy();
      toast.success("Coupon updated successfully!");
      return result;
    } catch (error) {
      message.destroy();
      const errorMessage =
        error.message?.message || "Failed to update the coupon.";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    dispatch(
      findCouponById({ userId: getUserId(), couponCode: parseInt(couponCode) })
    );
  }, [dispatch, couponCode]);
  useSetTabDetails('Coupon Details');
  if (!coupon) {
    return (
      <div className="h-full min-h-screen grid grid-columns">
        <SideBar />
        <div className="relative flex flex-col">
          <Header />
          <Content className="px-4 pt-28 pb-6">
            <Empty
              description="Coupon Data Not Found"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                flexDirection: "column",
              }}
            />
          </Content>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full min-h-screen grid grid-columns">
      <SideBar />
      <div className="relative flex flex-col">
        <Header />
        <PerfectScrollbar style={{ height: "100vh" }}>
          <Content className="px-4 pt-28 pb-6">
            <DiscountForm
              handleSubmit={handleSubmit}
              fixedValue={{
                FORM_MODE: "update",
                ...coupon,
              }}
              fixedCoupon={coupon}
              title="Update Discount Code"
              subTitle="Update the details of your discount/coupon including its name, type, amount, and duration."
            />
          </Content>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default CouponUpdate;
