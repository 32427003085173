import React, { useState, useRef, useEffect } from "react";
import { Modal, Typography, Avatar, Button, Input, message, Spin } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Svgs } from "../Svgs/svg-icons";
import { getMessages, sendMessageRequest } from "../../service/request";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axiosInstance from "../../config/Axios";
import { getUserId } from "../../constants";
import { getUser } from "../../store/user/userSlice";

const { Text } = Typography;
const { TextArea } = Input;

const RequestChat = ({ proposals, clientRequest, isFromClient }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollbarRef = useRef(null);
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state?.user?.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userMessage, setMessage] = useState("");

  const sendMessage = useMutation(sendMessageRequest);
  const markAsRead = useMutation((userId) => {
    return axiosInstance.patch(`/notifications/mark-as-read`, userId);
  });

  const showModal = async () => {
    setIsModalOpen(true);
    const p = proposals?.[0];
    if (isFromClient) {
      await markAsRead.mutateAsync({ userId: p?.user?.id });
      scrollToBottom();
    } else {
      await markAsRead.mutateAsync({ userId: p?.request?.userId });
      scrollToBottom();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(window.location.pathname);
  };

  const proposal = proposals?.find(
    (proposal) => proposal?.user?.email === user?.email
  );
  const getProposalAndRequestId = () => {
    if (clientRequest) {
      const requestId = clientRequest?.id;
      const proposal = clientRequest?.proposals?.find(
        (prop) => prop?.user?.email === user?.email
      );

      return {
        requestId,
        proposalId: proposal?.id,
      };
    }
    if (isFromClient) {
      const p = proposals?.[0];
      return {
        requestId: p?.requestId,
        proposalId: p?.id,
      };
    }
  };

  const getRequestInfo = getProposalAndRequestId();
  const messages = useQuery(
    [
      "GET_USER_MESSAGES",
      {
        proposalId: getRequestInfo.proposalId,
        requestId: getRequestInfo.requestId,
      },
    ],
    getMessages,
    {
      cacheTime: 0,
      refetchInterval: 3500,
      async onSuccess() {
        const userId = getUserId();
        dispatch(getUser(userId));

        if (searchParams.get("message")) {
          const p = proposals?.[0];
          if (isFromClient) {
            await markAsRead.mutateAsync({ userId: p?.user?.id });
            scrollToBottom();
          } else {
            await markAsRead.mutateAsync({ userId: p?.request?.userId });
            scrollToBottom();
          }
        }
      },
    }
  );

  const scrollToBottom = () => {
    const scrollElement = scrollbarRef.current;
    if (scrollElement) {
      const scrollableElement = scrollElement._container;

      if (scrollableElement) {
        scrollableElement.scrollTop = scrollableElement.scrollHeight;
      }
    }
  };

  const handleSendMessage = async () => {
    try {
      message.loading({ content: "Sending message..." });
      const p = proposals?.[0];
      const variables = {
        proposalId: proposal?.id,
        requestId: proposal?.id,
        message: userMessage,
        userId: user?.id,
        senderId: user?.id,
        receiverId: isFromClient ? p?.user?.id : p?.request?.userId,
        ...getProposalAndRequestId(),
      };
      message.destroy();
      await sendMessage.mutateAsync(variables);
      setMessage("");
      await messages.refetch();
      message.success({ content: "Send Successfully" });
    } catch (e) {
      toast.error("Something went wrong.");
      message.destroy();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages?.data]);

  const messagesForMap = messages?.data || [];
  return (
    <div className="mt-5">
      <Button
        onClick={showModal}
        disabled={proposal?.status === "rejected"}
        className="flex gap-3 items-center bg-primary py-5 px-3 rounded cursor-pointer w-full max-w-[260px]"
      >
        <div className="flex items-center gap-2">
          {Svgs.chat}
          <Text className="md:text-xl text-lg text-white font-normal">
            Chat
          </Text>
        </div>
        <div className="-rotate-90">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M7.03125 9.9375L12.9681 16L19.0306 10.0632"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </Button>
      <Modal
        className="!w-full !max-w-[630px]"
        open={isModalOpen || searchParams.get("message")}
        onCancel={handleCancel}
      >
        <div className="relative">
          <Text className="md:text-[32px] text-2xl font-medium text-primary">
            Chat History
          </Text>
          <PerfectScrollbar
            className="!mt-10"
            style={{ maxHeight: 400 }}
            ref={scrollbarRef}
          >
            <Spin spinning={messages.isLoading}>
              {!messagesForMap?.length ? (
                <Text>Not Chat History Found</Text>
              ) : null}
              {messagesForMap?.map((message) => {
                return (
                  <React.Fragment>
                    {message?.user?.id !== user?.id ? (
                      <div className="flex items-start gap-3">
                        <Avatar
                          className="w-8 h-8 rounded-full"
                          src="/assets/images/avatar-vendor.svg"
                        />
                        <div className="flex flex-col gap-2">
                          <Text className="border border-[#9A9EA5] bg-white rounded-xl p-2 w-full max-w-[400px] text-primary text-sm">
                            {message?.message}
                          </Text>
                          <Text className="text-xs text-[#333]">
                            {format(message?.createdAt, "h:mm a")}
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-end items-start gap-3">
                        <div className="flex flex-col gap-2">
                          <Text className="border border-primary bg-primary rounded-xl p-2 w-full max-w-[400px] text-white text-sm">
                            {message?.message}
                          </Text>
                          <Text className="text-xs text-[#333] flex justify-end">
                            {format(message?.createdAt, "h:mm a")}
                          </Text>
                        </div>
                        <Avatar
                          className="w-8 h-8 rounded-full"
                          src="/assets/images/avatar-vendor.svg"
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </Spin>
          </PerfectScrollbar>
          {proposal?.status === "withdraw" ||
          (isFromClient && proposals?.[0]?.status === "withdraw") ? (
            <Text
              style={{
                color: "#878787",
                textAlign: "center",
                padding: 10,
                display: "block",
                border: "1px solid #eee",
                margin: 10,
              }}
            >
              Proposal Withdrawn: You can no longer send messages.
            </Text>
          ) : (
            <div className="flex gap-5 items-center bg-white sticky bottom-0 left-0 w-full max-w-[630px] pt-5">
              <TextArea
                rows={1}
                value={userMessage}
                disabled={sendMessage?.isLoading}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type something here..."
                className="!bg-[#F6F8FA] !border-[#9A9EA5] !rounded-md !min-h-[52px] placeholder:!text-[#50586C] !text-lg !font-normal !text-black"
              ></TextArea>
              <Button
                type="primary"
                disabled={!userMessage || sendMessage?.isLoading}
                icon={Svgs.send}
                onClick={handleSendMessage}
                className="!bg-primary !text-white !flex !items-center !justify-center !w-[52px] !h-[52px] !rounded-md shrink-0"
              ></Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RequestChat;
