import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  Layout,
  Space,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Avatar,
  Menu,
  Dropdown,
  Table,
  Tag,
} from "antd";
import { Svgs } from "../Svgs/svg-icons";
import RequestChat from "./request-chat";
import SubmitProposal from "./submit-proposal";
import ViewProposal from "./view-proposal";
import WithDraw from "./widthdraw";
import ClientRequest from "./client-request";
import DeleteRequest from "./delete-request";

const { Text } = Typography;

const UserDropdown = ({
  isAlreadyProposalSubmitted,
  request,
  refetch,
  proposal,
}) => {
  const user = useSelector((state) => state?.user?.user);
  const isVendor = user?.role?.name === "VENDOR";

  const shouldRenderSubmitProposal = () =>
    isAlreadyProposalSubmitted && proposal?.status !== "withdraw";

  const shouldRenderWithDraw = () =>
    isAlreadyProposalSubmitted && proposal?.status !== "withdraw";

  const shouldRenderViewProposal = () => proposal;

  const shouldRenderDeleteRequest = () => !isVendor;

  if (
    !shouldRenderViewProposal() &&
    !shouldRenderSubmitProposal() &&
    !shouldRenderWithDraw() &&
    !shouldRenderDeleteRequest()
  ) {
    return null;
  }

  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      {shouldRenderViewProposal() && (
        <Menu.Item key="0">
          <ViewProposal proposal={proposal} />
        </Menu.Item>
      )}
      {shouldRenderSubmitProposal() && (
        <Menu.Item key="1">
          <SubmitProposal
            isDropdown={true}
            request={request}
            refetch={refetch}
            proposal={proposal}
          />
        </Menu.Item>
      )}
      {shouldRenderWithDraw() && (
        <Menu.Item key="2">
          <WithDraw proposal={proposal} />
        </Menu.Item>
      )}
      {shouldRenderDeleteRequest() && (
        <Menu.Item key="5">
          <DeleteRequest request={request} />
        </Menu.Item>
      )}
    </Menu>
  );
};

// client module use

const ClientDropdown = ({ request, refetch }) => (
  <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
    <Menu.Item key="0">
      <ClientRequest request={request} refetch={refetch} />
    </Menu.Item>
  </Menu>
);

const UserDropdownVendor = ({ proposalId }) => {
  const navigate = useNavigate();

  return (
    <Menu className="!py-3 border border-[#DBDBDB] rounded-[5px] !shadow-none">
      <Menu.Item
        key="0"
        className="dropdown__item__header"
        icon={Svgs.view}
        onClick={() => navigate(`/proposal/${proposalId}`)}
      >
        View Details
      </Menu.Item>
    </Menu>
  );
};

function RequestDetail({ clientRequest, refetch }) {
  const params = useParams();
  const user = useSelector((state) => state?.user?.user);
  const proposals = clientRequest?.proposals;
  const userEmail = user?.email;
  const isAlreadyProposalSubmitted = proposals.some(
    (prop) => prop?.user?.email === userEmail
  );

  const proposal = proposals.find((prop) => prop?.user?.email === userEmail);
  const dataSource = proposals?.map((proposal) => ({
    key: proposal.id,
    vendors: proposal?.user?.name || "Guest",
    email: proposal?.user?.email,
    status: proposal?.status,
    proposalamount: proposal?.budget,
    proposal,
    date: format(proposal.createdAt, "MM-dd-yyyy, hh:mm:ss a"),
  }));

  const isClient = user?.role?.name === "CLIENT";
  const isVendor = user?.role?.name === "VENDOR";

  const getColumns = () => {
    return [
      {
        title: "Vendors",
        dataIndex: "vendors",
        key: "0",
        render: (text) => (
          <Space className="flex gap-2 items-center">
            <Avatar
              className="rounded-full w-9 h-9 flex justify-center items-center"
              size="large"
              src="/assets/images/avatar-vendor.svg"
            />
            <span className="text-primary underline">{text}</span>{" "}
          </Space>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Proposal Amount",
        dataIndex: "proposalamount",
        key: "proposalamount",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) =>
          text === "accepted" ? (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-success py-[2px] px-2 rounded-2xl">
                {text}
              </div>
            </Space>
          ) : (
            <Space className="flex items-center gap-3">
              <div className="text-xs font-normal text-white bg-[#FFC107] py-[2px] px-2 rounded-2xl">
                {text}
              </div>
            </Space>
          ),
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={<UserDropdownVendor proposalId={record?.proposal?.id} />}
              placement="bottomRight"
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Space className="gap-3 items-center">{Svgs.dots}</Space>
            </Dropdown>
          </Space>
        ),
      },
    ];
  };

  return (
    <Layout>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <Link to={`/service-request`}>Request</Link>,
          },
          {
            title: (
              <Link to={`/service-request/${params.requestId}`}>
                Request Details
              </Link>
            ),
          },
        ]}
      />
      <Row className="flex justify-between items-center mb-4">
        <Col>
          <Text className="text-primary md:text-[32px] text-xl font-medium flex items-center gap-4 mb-3">
            <Link to={`/service-request`}>{Svgs.arrowback}</Link>
            Request Details
          </Text>
          <Text className="text-sm font-normal text-[#888888]">
            Here you can see all the detail of this job and the client response.
          </Text>
        </Col>
        <Col className="flex items-center gap-2">
          {user?.role?.name === "VENDOR" ? (
            <SubmitProposal
              request={clientRequest}
              refetch={refetch}
              proposal={proposal}
              isAlreadyProposalSubmitted={isAlreadyProposalSubmitted}
            />
          ) : null}
          <Dropdown
            overlay={
              user?.role?.name === "CLIENT" ? (
                <ClientDropdown request={clientRequest} refetch={refetch} />
              ) : (
                <UserDropdown
                  request={clientRequest}
                  refetch={refetch}
                  proposal={proposal}
                  proposals={clientRequest?.proposals || []}
                  isAlreadyProposalSubmitted={isAlreadyProposalSubmitted}
                />
              )
            }
            placement="bottomRight"
            trigger={["click"]}
            className="cursor-pointer"
          >
            <Space className="gap-1 items-center text-xs font-medium text-primary py-3 px-3 bg-[#D7E5FF] rounded">
              {Svgs.action} <span>Action</span>{" "}
            </Space>
          </Dropdown>
        </Col>
      </Row>
      <div className="bg-[#f5f5f5] rounded-2xl lg:p-5 p-3">
        <div className="flex flex-col gap-5">
          <div className={`grid lg:grid-cols-${!isClient ? "2" : ""} gap-5`}>
            <div className="bg-white rounded-md p-3">
              <h3 className="text-base text-gray font-medium">
                Service Category:
              </h3>
              <div className="flex gap-2 items-center mt-2">
                <Avatar
                  src={
                    clientRequest?.category?.iconURL ||
                    "/assets/images/avatar.svg"
                  }
                  className="w-16 h-16 rounded-full"
                />
                <div className="flex flex-col gap-1">
                  <span className="text-base text-primary font-medium">
                    {clientRequest?.category?.name}
                  </span>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray font-normal">
                      Request Status:
                    </span>
                    <Tag className="text-xs font-normal capitalize py-[2px] px-2 rounded-2xl">
                      {clientRequest?.status}
                    </Tag>
                  </div>
                </div>
              </div>
            </div>
            {!isClient ? (
              <div className="bg-white rounded-md p-3">
                <h3 className="text-base text-gray font-medium">
                  Client Name:
                </h3>
                <div className="flex gap-2 items-center mt-2">
                  <Avatar
                    src="/assets/images/avatar-vendor.svg"
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="flex flex-col gap-1">
                    <span className="md:text-2xl text-xl text-primary font-normal">
                      {clientRequest?.user?.name || "Guest"}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-[#303030] font-normal">
                        {clientRequest?.user?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid lg:grid-cols-2 gap-5">
            {/*<div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Area Name:</span>
                <span className="font-normal text-[#888888]">
                  {" "}
                  {clientRequest?.status}
                </span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Area Category:</span>
                <span className="font-normal text-[#888888]">
                  {clientRequest?.status}
                </span>
              </div>
            </div>*/}
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Zip Code:</span>
                <span className="font-normal text-[#888888]">
                  {" "}
                  {clientRequest?.zipCode}
                </span>
              </div>
            </div>
            <div className="bg-white rounded-md p-3">
              <div className="flex gap-1 items-center text-base">
                <span className="font-medium text-gray">Amount:</span>
                <span className="font-normal text-[#888888]">
                  {" "}
                  {clientRequest?.budget || "N/A"}
                </span>
              </div>
            </div>
          </div>
          {clientRequest?.startDate ? (
            <div className="grid lg:grid-cols-2 gap-5">
              <div className="bg-white rounded-md p-3">
                <div className="flex gap-1 items-center text-base">
                  <span className="font-medium text-gray">Request Date:</span>
                  <span className="font-normal text-[#888888]">
                    {" "}
                    {clientRequest?.startDate} - {clientRequest?.endDate}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="bg-white rounded-md p-3">
            <div className="flex flex-col gap-1 text-base">
              <span className="font-medium text-gray">Description:</span>
              <span className="font-normal text-[#888888]">
                {clientRequest?.description}
              </span>
            </div>
          </div>
        </div>
        {!isClient ? (
          <Row className="mt-5">
            <Col span={24}>
              <div className="bg-white border border-[#E1E1E1] rounded-md pt-3 pb-6 px-3">
                <div className="flex lg:flex-row flex-col lg:gap-0 gap-3 justify-between items-center">
                  <Text className="text-gray font-medium text-base">
                    Client Name:
                  </Text>
                  <div className="flex items-center gap-3 w-full lg:max-w-[340px]">
                    <Text className="text-gray text-base font-normal whitespace-nowrap">
                      Request Date:
                    </Text>
                    <div className="border border-[#F5F5F5] rounded p-2 w-full max-w-[220px]">
                      <Text className="text-sm font-normal text-gray">
                        {format(clientRequest.createdAt, "MM-dd-yyyy")}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 items-center">
                  <div className="flex items-center gap-3 mt-2">
                    <Avatar
                      className="w-12 h-12 rounded-full"
                      src="/assets/images/avatar-vendor.svg"
                    />
                    <div className="flex flex-col">
                      <Text className="text-base font-medium text-primary underline">
                        {clientRequest?.user?.name || "Guest"}
                      </Text>
                      <div className="flex items-center gap-3 mb-1">
                        <Text className="text-gray text-sm font-normal">
                          {clientRequest?.user?.email}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <RequestChat proposals={proposals} clientRequest={clientRequest} />
                </div>
              </div>
            </Col>
          </Row>
        ) : null}
        {!isVendor && (
          <Row className="mt-5">
            <div className="w-full bg-white pt-5 rounded-md">
              <Text className="md:text-xl text-lg font-medium text-gray !px-4">
                Responded Vendors:
              </Text>
              <Table
                className="responded-table mt-5"
                columns={getColumns()}
                scroll={{ x: 400 }}
                dataSource={dataSource}
                pagination={false}
              />
            </div>
          </Row>
        )}
      </div>
    </Layout>
  );
}

export default RequestDetail;
