import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { Field, Formik } from "formik";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../config/Axios";
import { getUserId } from "../../constants";
import { fetchPrices, fetchServices } from "../../service/request";
import { getFormItemValidationProps } from "../../utils/form-util";
import { Svgs } from "../Svgs/svg-icons";
import { validateRequestForm } from "./validation";

const { Text } = Typography;
const { Content } = Layout;

const ClientRequest = ({ refetch, request }) => {
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState([]);
  const [vendors, setVendors] = useState(null);

  const user = useSelector((state) => state?.user?.user);
  const isVendor = user?.role?.name === "VENDOR";

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleGetCategoriesInfo = async () => {
    const categories = await fetchServices();
    const prices = await fetchPrices();
    setPrices(prices || []);
    const categoriesR = categories
      ?.filter((c) => !c.isPaused)
      ?.map((cat) => ({
        label: cat?.data?.name,
        value: cat.id,
      }));
    setCategories(categoriesR || []);
  };

  const debouncedSearch = debounce(async (zipcode, serviceId) => {
    try {
      if (!zipcode || !serviceId) return null;
      const response = await axiosInstance.get(
        "areas/get-vendor-clientRequest-info",
        { params: { zipcode, serviceId } }
      );

      setVendors(response.data);
    } catch (error) {
      setVendors([]);
    }
  }, 500);

  useEffect(() => {
    handleGetCategoriesInfo();
    if (request) {
      debouncedSearch(request?.zipCode, request?.category?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isVendor) return null;

  return (
    <div>
      {!params?.requestId ? (
        <Button
          onClick={showModal}
          className="bg-primary flex justify-center items-center w-full max-w-[180px] py-5 text-base font-normal text-white rounded-lg"
          type=""
          icon={Svgs.addiconw}
        >
          Make a Request
        </Button>
      ) : (
        <div onClick={showModal} className="flex gap-2">
          {Svgs.editg}
          Edit
        </div>
      )}
      <Modal open={isModalOpen} onCancel={handleCancel} destroyOnClose>
        <Content className="flex flex-col">
          <Text className="text-primary md:text-[32px] text-xl font-medium text-center">
            Make a Request
          </Text>
          <div className="flex flex-col justify-center items-center mt-5">
            <Text className="text-sm font-normal text-[#303030] text-center mb-2">
              You can make the request as per your requirement.
            </Text>
          </div>
          <Formik
            enableReinitialize
            validate={validateRequestForm}
            initialValues={{
              zipCode: request?.zipCode || null,
              serviceId: request?.category?.id,
              description: request?.description,
              price: request?.budget,
              startDate: request?.startDate || null,
              endDate: request?.endDate || null,
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const createClientRequestDto = {
                  zipCode: values.zipCode,
                  categoryId: values.serviceId,
                  description: values.description,
                  budget: values.price,
                  email: values.email,
                  startDate: values.startDate,
                  endDate: values.endDate,
                };

                createClientRequestDto.userId = getUserId();
                if (params.requestId) {
                  await axiosInstance.put(
                    `clientRequests/${params.requestId}`,
                    createClientRequestDto
                  );
                } else {
                  await axiosInstance.post(
                    "clientRequests",
                    createClientRequestDto
                  );
                }
                if (refetch) {
                  await refetch();
                }
                handleCancel();
                if (params.requestId) {
                  toast.success("Request Successfully Updated!");
                } else {
                  toast.success("Request Successfully Submitted!");
                }
              } catch (error) {
                toast.error("There was an error submitting the form:", error);
              }

              setSubmitting(false);
            }}
          >
            {({ handleSubmit, isSubmitting, values }) => {
              return (
                <Spin spinning={isSubmitting}>
                  <Form
                    name="login"
                    size="large"
                    layout="vertical"
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      margin: "15px auto 0 auto",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    className="flex flex-col gap-3"
                  >
                    <div className="grid lg:grid-cols-2 gap-3">
                      <Field name="serviceId">
                        {({ field, form }) => (
                          <Form.Item
                            className="super__select w-full"
                            label="Service Category:"
                            required
                            {...getFormItemValidationProps(field, form)}
                          >
                            <Select
                              {...field}
                              showSearch
                              className="w-full super__select text-sm font-normal text-dark"
                              placeholder="Service Category:"
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                debouncedSearch(values.zipCode, value);
                              }}
                              value={field.value}
                              onBlur={field.onBlur}
                              options={categories}
                            />
                          </Form.Item>
                        )}
                      </Field>
                      <Field name="zipCode">
                        {({ field, form }) => (
                          <Form.Item
                            label="Zip Code:"
                            required
                            {...getFormItemValidationProps(field, form)}
                          >
                            <Input
                              {...field}
                              size="large"
                              placeholder="A1A1A1"
                              className="super__select text-sm font-normal text-dark h-14"
                              onChange={(e) => {
                                field.onChange(e);
                                debouncedSearch(
                                  e.target.value,
                                  values.serviceId
                                );
                              }}
                              prefix={<></>}
                            />
                          </Form.Item>
                        )}
                      </Field>
                    </div>
                    <div className="grid lg:grid-cols gap-3">
                      <Field name="dateRange">
                        {({ field, form }) => (
                          <Form.Item
                            label="Date:"
                            {...getFormItemValidationProps(field, form)}
                          >
                            <DatePicker.RangePicker
                              value={
                                values.startDate
                                  ? [
                                      dayjs(values.startDate, "YYYY-MM-DD"),
                                      dayjs(values.endDate, "YYYY-MM-DD"),
                                    ]
                                  : null
                              }
                              className="!mb-2"
                              format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                              onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                  const startDate =
                                    dates[0].format("YYYY-MM-DD");
                                  const expDate = dates[1].format("YYYY-MM-DD");
                                  form.setFieldValue("startDate", startDate);
                                  form.setFieldValue("endDate", expDate);
                                } else {
                                  form.setFieldValue("startDate", null);
                                  form.setFieldValue("endDate", null);
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                      </Field>
                      <Field name="price">
                        {({ field, form }) => (
                          <Form.Item
                            label="Price Range:"
                            {...getFormItemValidationProps(field, form)}
                          >
                            <Select
                              {...field}
                              style={{ width: "100%" }}
                              size="large"
                              placeholder="200 - 100"
                              className="super__select text-sm font-normal text-dark h-14"
                              prefix={<></>}
                              onChange={(value) =>
                                form.setFieldValue(field.name, value)
                              }
                            >
                              <Select.Option value={null}>None</Select.Option>
                              {prices?.map((price) => (
                                <Select.Option value={price?.description}>
                                  {price?.description}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Field>
                    </div>
                    <Field name="description">
                      {({ field, form }) => (
                        <Form.Item
                          label="Description:"
                          required
                          {...getFormItemValidationProps(field, form)}
                        >
                          <TextArea
                            {...field}
                            className="font-normal text-sm"
                            autoComplete="on"
                            autoSize={{ minRows: 4, maxRows: 5 }}
                            placeholder="Write description here..."
                          />
                        </Form.Item>
                      )}
                    </Field>
                    {vendors === null ? (
                      <div />
                    ) : (
                      <div>
                        <label className="text-base font-medium text-primary">
                          Results:
                        </label>
                        {vendors.length ? (
                          vendors?.map((v) => {
                            return (
                              <div className="flex flex-col">
                                <Text className="text-base text-[#303030] font-normal">
                                  {v?.vendorInfoMessage}
                                </Text>
                                <Text className="text-base text-[#303030] font-normal">
                                  {v?.cleintRequestInfoMessage}
                                </Text>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-base font-normal text-dark mt-1">
                            No vendor Found in your area.
                          </p>
                        )}
                      </div>
                    )}
                    <Form.Item>
                      <Flex vertical style={{ marginTop: 20 }}>
                        <div className="flex justify-center">
                          <Button
                            onClick={handleSubmit}
                            className="bg-white text-primary rounded-xl lg:text-xl text-lg w-full max-w-[320px] mx-auto"
                            type=""
                            htmlType="submit"
                            block
                            disabled={isSubmitting || !vendors?.length}
                          >
                            <span className="underline">
                              Submit Your Request
                            </span>
                          </Button>
                        </div>
                      </Flex>
                    </Form.Item>
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        </Content>
      </Modal>
    </div>
  );
};

export default ClientRequest;
