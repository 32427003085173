import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, message, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createCharge } from "../../service/transaction";
import { Svgs } from "../Svgs/svg-icons";
const { Text } = Typography;

const PurchaseReview = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);

  const paymentInfo = localStorage.getItem("paymentInfo");
  const formInfo = JSON.parse(paymentInfo);

  const createPayment = useMutation(createCharge);

  const handleSubmit = async () => {
    try {
      message.loading({ content: "Processing..." });
      const paymentData = {
        ...formInfo,
        amount: formInfo.originalAmount || formInfo.amount,
        discountedAmount: formInfo.amount,
        useExistingPaymentMethod: formInfo.useExistingPaymentMethod,
        billingInfo: formInfo.billingInfo,
        createdBy: user?.id,
        updatedBy: user?.id,
      };

      if (!formInfo.useExistingPaymentMethod) {
        paymentData.last4 = formInfo.last4;
        paymentData.expYear = formInfo.exp_year;
        paymentData.expMonth = formInfo.exp_month;
        paymentData.stripeToken = formInfo.stripeToken;
      }

      const charge = await createPayment.mutateAsync(paymentData);
      message.destroy();
      localStorage.removeItem("paymentInfo");
      toast.success("Credits Purchased successfully!");
      navigate(`/purchase-credit?invoice=${charge?.invoiceUrl}`);
    } catch (error) {
      message.destroy();
      const response = error?.response?.data;
      const errMessage = response?.message;
      formInfo.stripeToken = null;
      const newPaymentValues = {
        ...structuredClone(paymentInfo),
        ...formInfo,
      };
      localStorage.setItem("paymentInfo", JSON.stringify(newPaymentValues));
      toast.error(
        errMessage || "Something went wrong while creating the charge"
      );
    }
  };

  return (
    <Content>
      <div className="flex gap-16 justify-center items-center mb-10">
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.details}
          </div>
          <Text className="text-sm text-primary font-semibold">Details</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.payment}
          </div>
          <Text className="text-sm text-primary font-semibold">Payment</Text>
          <div className="flex gap-1 items-center absolute top-4 left-[46px]">
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
            <div className="w-1 h-1 bg-primary rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 relative">
          <div className="bg-white border-[3px] border-primbg-primary text-primary w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.review}
          </div>
          <Text className="text-sm text-primary font-semibold">Review</Text>
          <div className="flex gap-1 items-center absolute top-4 left-10">
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
            <div className="w-1 h-1 bg-[#A1AEBE] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="bg-white border-[3px] border-[#A1AEBE] text-[#242E39] w-8 h-8 rounded-full flex justify-center items-center">
            {Svgs.thankyou}
          </div>
          <Text className="text-sm text-[#465668] font-normal">Thank You</Text>
        </div>
      </div>
      <div className="bg-[#f5f5f5] rounded-lg lg:p-5 p-3">
        <div className="flex justify-between items-center">
          <Text className="md:text-2xl text-xl font-medium text-gray">
            Review Details:
          </Text>
        </div>
        <div className="mt-4">
          <div className="grid lg:grid-cols-2 gap-3">
            <div className="bg-white rounded-xl card__shadow py-3 px-4">
              <div className="flex gap-2 items-center mb-3">
                <UserOutlined className="text-gray" />
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Name:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.name}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-3">
                <MailOutlined className="text-gray" />
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Email:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.email}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <PhoneOutlined className="text-gray rotate-90" />
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Phone Number:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.phone}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white rounded-xl card__shadow py-3 px-4 relative h-[120px]"
              style={formInfo?.amountOff ? { height: "195px" } : {}}
            >
              <div className="flex gap-2 items-center mb-3">
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Country:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.address?.country}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-3">
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Zip code:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.address?.postalCode}
                  </div>
                </div>
              </div>
              {formInfo?.amountOff ? (
                <>
                  <div className="flex gap-2 items-center mb-3">
                    <div className="flex items-center gap-2">
                      <div className="text-base text-[#0f172a] font-normal">
                        Amount:
                      </div>
                      <div className="text-sm text-[#303030] font-normal">
                        ${formInfo?.originalAmount}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center mb-3">
                    <div className="flex items-center gap-2">
                      <div className="text-base text-[#0f172a] font-normal">
                        Discount:
                      </div>
                      <div className="text-sm text-[#303030] font-normal">
                        ${formInfo?.amountOff?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#ededed] py-2 px-4 w-full absolute left-0 bottom-0 rounded-bl-xl rounded-br-xl">
                    <div className="flex items-center gap-2">
                      <div className="text-base text-[#0f172a] font-medium">
                        Payable Amount:
                      </div>
                      <div className="text-sm text-[#303030] font-normal">
                        ${formInfo?.amount}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="bg-[#ededed] py-2 px-4 w-full absolute left-0 bottom-0 rounded-bl-xl rounded-br-xl">
                  <div className="flex items-center gap-2">
                    <div className="text-base text-[#0f172a] font-medium">
                      Amount:
                    </div>
                    <div className="text-sm text-[#303030] font-normal">
                      ${formInfo?.amount}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-white rounded-xl card__shadow py-3 px-4">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Card Number:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    **** **** **** {formInfo?.last4 || user?.last4}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    CVC:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">***</div>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-3">
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Card Name:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.billingInfo?.name}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="flex items-center gap-2">
                  <div className="text-base text-[#0f172a] font-normal">
                    Expiry:
                  </div>
                  <div className="text-sm text-[#303030] font-normal">
                    {formInfo?.exp_month || user?.expMonth}/
                    {formInfo?.exp_year || user?.expYear}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Flex className="justify-center gap-5 mt-14 mb-10">
            <Button
              className="bg-[#9A9EA5] flex justify-center items-center w-full max-w-[300px] py-6 text-base font-normal text-white rounded-lg"
              type="primary"
              disabled={createPayment.isLoading}
              onClick={() => navigate("/purchase-credit?payment-details=1")}
            >
              Back
            </Button>
            <Button
              className="bg-primary flex justify-center items-center w-full max-w-[300px] py-6 text-base font-normal text-white rounded-lg"
              type="primary"
              disabled={createPayment.isLoading}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Flex>
        </div>
      </div>
    </Content>
  );
};

export default PurchaseReview;
