import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createAreaCategory,
  deleteAreaCategory,
  fetchAreaCategory,
  updateAreaCategory,
} from "../../service/areaCategory";

const initialState = {
  areaCategory: [],
};

export const getAllAreaCategory = createAsyncThunk(
  "AreaCategory/getAllAreaCategory",
  async () => {
    const response = await fetchAreaCategory();
    return response.data;
  }
);

export const addareaCategory = createAsyncThunk(
  "AreaCategory/addareaCategory",
  async (areaCategoryData) => {
    const response = await createAreaCategory(areaCategoryData);
    return response.data;
  }
);

export const update_Area = createAsyncThunk(
  "areaCategory/updateAreaCategory",
  async ({ id, ...areaCategoryData }) => {
    const response = await updateAreaCategory(id, areaCategoryData);
    return response.data;
  }
);

export const delete_Area = createAsyncThunk(
  "areaCategory/deleteAreaCategory",
  async (areaCategoryId) => {
    await deleteAreaCategory(areaCategoryId);
    return areaCategoryId;
  }
);

const areaCategorySlice = createSlice({
  name: "areaCategory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllAreaCategory.fulfilled, (state, action) => {
      state.areaCategory = action.payload;
    });
    // .addCase(addArea.fulfilled, (state, action) => {
    //   state.area.push(action.payload);
    // })
    // .addCase(update_Area.fulfilled, (state, action) => {
    //   const updatedArea = action.payload;
    //   const index = state.area.findIndex(
    //     (area) => area.id === updatedArea.id
    //   );
    //   state.area[index] = updatedArea;
    // })
    // .addCase(delete_Area.fulfilled, (state, action) => {
    //   const areaId = action.payload;
    //   state.area = state.area.filter((area) => area.id !== areaId);
    // });
  },
});

export default areaCategorySlice.reducer;
