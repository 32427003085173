import { Breadcrumb, Col, Image, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

const { Text } = Typography;

const PaymentEmpty = () => {
  return (
    <Content>
      <Breadcrumb
        className="mb-4"
        items={[
          {
            title: <span>Payment History</span>,
          },
        ]}
      />
      <Row className="flex flex-wrap justify-between lg:items-center items-start mb-8">
        <Col className="mb-2">
          <Text className="text-primary md:text-[32px] text-xl font-medium">
            Payment History{" "}
          </Text>
        </Col>
      </Row>
      <section className="flex flex-col justify-center items-center mt-6 bg-[#F5F5F5] p-8 rounded-xl">
        <Image
          preview={false}
          className="lg:max-w-md mx-auto mb-3"
          src="/assets/images/no-payment-img.svg"
        />
        <Text className="text-[#2C2C2E] md:text-2xl text-xl font-normal mb-2">
          No payment has been done yet
        </Text>
        <Text className="text-[#868687] md:text-lg text-base font-normal mb-7 text-center !leading-tight max-w-[440px]">
          This page will list complete payment history
        </Text>
      </section>
    </Content>
  );
};

export default PaymentEmpty;
