export const validateRequestForm = (values) => {
  const errors = {};
  const userId = localStorage.getItem("token");

  if (!values.serviceId) {
    errors.serviceId = "Service type is required";
  }

  if (!values.zipCode) {
    errors.zipCode = "Zip code is required";
  }

  if (!values.description) {
    errors.description = "Description is required";
  } else if (values.description.length < 10) {
    errors.description = "Description must be at least 10 characters long";
  }

  if (!userId && !values.email) {
    errors.email = "Email is required";
  } else if (
    !userId &&
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  return errors;
};

export function validateProposalForm(values) {
  let errors = {};

  if (!values.budget) {
    errors.budget = "Price is required";
  } else if (isNaN(values.budget)) {
    errors.budget = "Price must be a number";
  } else if (values.budget <= 0) {
    errors.budget = "Price must be greater than zero";
  }

  if (!values.description) {
    errors.description = "Description is required";
  } else if (values.description.length < 10) {
    errors.description = "Description must be at least 10 characters";
  }

  return errors;
}
